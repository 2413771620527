@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
.hljs {
  background: #f0f0f0;
  padding: .5em;
  display: block;
  overflow-x: auto;
}

.hljs * {
  font-family: monospace !important;
}

.hljs.cURL * {
  color: inherit;
}

.hljs, .hljs-subst {
  color: #444;
}

.hljs-comment {
  color: #888;
}

.hljs-keyword, .hljs-attribute, .hljs-selector-tag, .hljs-meta-keyword, .hljs-doctag, .hljs-name {
  color: #c7433c;
}

.hljs-type, .hljs-string, .hljs-number, .hljs-selector-id, .hljs-selector-class, .hljs-quote, .hljs-template-tag, .hljs-deletion, .hljs-title, .hljs-section {
  color: #293e57;
}

.hljs-regexp, .hljs-symbol, .hljs-variable, .hljs-template-variable, .hljs-link, .hljs-selector-attr, .hljs-selector-pseudo {
  color: #bc6060;
}

.hljs-literal, .hljs-built_in, .hljs-bullet, .hljs-code, .hljs-addition {
  color: #c7433c;
}

.hljs-meta {
  color: #1f7199;
}

.hljs-meta-string {
  color: #4d99bf;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.bbep6q_buttonSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bbep6q_buttonText {
  font-weight: bold;
}

.bbep6q_buttonLoading {
  position: relative;
}

.bbep6q_buttonLoading .bbep6q_buttonText {
  opacity: 0;
}

.bbep6q_same-icon-color-action-button .ms-Icon, .bbep6q_same-icon-color-action-button:enabled .ms-Icon {
  color: inherit;
}

@keyframes _1wq0lW_ms-motion-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes _1wq0lW_ms-motion-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes _1wq0lW_ms-motion-scaleDownIn {
  from {
    transform: scale3d(1.15, 1.15, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes _1wq0lW_ms-motion-scaleDownOut {
  from {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(.9, .9, 1);
  }
}

@keyframes _1wq0lW_ms-motion-slideLeftOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-48px, 0, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideRightOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(48px, 0, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideLeftIn {
  from {
    transform: translate3d(48px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideRightIn {
  from {
    transform: translate3d(-48px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideUpOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -48px, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideDownOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 48px, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideUpIn {
  from {
    transform: translate3d(0, 48px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _1wq0lW_ms-motion-slideDownIn {
  from {
    transform: translate3d(0, -48px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

._1wq0lW_toast {
  width: 480px;
  padding: 4px 0;
}

@media only screen and (max-width: 480px) {
  ._1wq0lW_toast {
    width: calc(100vw - 40px);
  }
}

._1wq0lW_toast ._1wq0lW_action-content {
  width: 404px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

._1wq0lW_toast ._1wq0lW_action-content > * {
  align-self: center;
}

@media only screen and (max-width: 480px) {
  ._1wq0lW_toast ._1wq0lW_action-content {
    width: calc(100vw - 116px);
  }
}

._1wq0lW_toast ._1wq0lW_action-content button {
  height: 24px;
  font-size: 14px;
}

._1wq0lW_fade-in {
  animation: .3s cubic-bezier(.1, .9, .2, 1) both _1wq0lW_ms-motion-scaleDownIn, .1s cubic-bezier(0, 0, 1, 1) both _1wq0lW_ms-motion-fadeIn;
}

._1wq0lW_fade-out {
  animation: .3s cubic-bezier(.1, .9, .2, 1) both _1wq0lW_ms-motion-scaleDownOut, .1s cubic-bezier(0, 0, 1, 1) both _1wq0lW_ms-motion-fadeOut;
}

._1wq0lW_login-toast-container .react-toast-notifications__container {
  margin-top: calc(50vh + 100px);
}

._1wq0lW_signup-toast-container .react-toast-notifications__container {
  margin-top: calc(50vh + 220px);
}

._1wq0lW_toast-container .react-toast-notifications__container {
  margin-top: 48px;
}

.c4w4-G_spinner {
  width: 64px;
  height: 64px;
  display: inline-block;
  position: relative;
}

.c4w4-G_spinner div {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation: 1.2s linear infinite c4w4-G_spinner;
  position: absolute;
}

.c4w4-G_spinner div:nth-child(1) {
  animation-delay: 0s;
  top: 6px;
  left: 6px;
}

.c4w4-G_spinner div:nth-child(2) {
  animation-delay: -.4s;
  top: 6px;
  left: 26px;
}

.c4w4-G_spinner div:nth-child(3) {
  animation-delay: -.8s;
  top: 6px;
  left: 45px;
}

.c4w4-G_spinner div:nth-child(4) {
  animation-delay: -.4s;
  top: 26px;
  left: 6px;
}

.c4w4-G_spinner div:nth-child(5) {
  animation-delay: -.8s;
  top: 26px;
  left: 26px;
}

.c4w4-G_spinner div:nth-child(6) {
  animation-delay: -1.2s;
  top: 26px;
  left: 45px;
}

.c4w4-G_spinner div:nth-child(7) {
  animation-delay: -.8s;
  top: 45px;
  left: 6px;
}

.c4w4-G_spinner div:nth-child(8) {
  animation-delay: -1.2s;
  top: 45px;
  left: 26px;
}

.c4w4-G_spinner div:nth-child(9) {
  animation-delay: -1.6s;
  top: 45px;
  left: 45px;
}

@keyframes c4w4-G_spinner {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.slZ2nq_splash-screen {
  width: 177px;
  text-align: center;
  margin-top: -64px;
  margin-left: -88px;
  position: fixed;
  top: 50vh;
  left: 50vw;
}

.slZ2nq_logo {
  margin-top: 16px;
  display: inline-block;
}

.Gn1Yqa_button-actions {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.A6IecW_message-bar {
  max-width: 250px;
  min-width: 100px;
  z-index: 100;
  position: absolute;
  bottom: 0;
  left: 0;
}

.A6IecW_message-bar .A6IecW_upgrade-button {
  margin-top: 8px;
}

.-S8MhG_beta-tag {
  color: #000;
  padding: 3px 5px;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  display: inline-block;
}

.-UuuPq_container {
  width: 260px;
  background-color: #fff;
  flex-direction: row;
  flex-shrink: 0;
  margin-left: 0;
  font-size: 10pt;
  display: flex;
}

.-UuuPq_container .-UuuPq_collapse-button-bar {
  height: inherit;
  width: 10px;
  cursor: pointer;
  background-color: #f6f6f6;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}

.-UuuPq_container .-UuuPq_collapse-button-bar:hover {
  background-color: #ebebeb;
}

.-UuuPq_container .-UuuPq_button-collapsed {
  width: 16px;
  background-image: url("icon-expand-right.924cf938.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
}

.-UuuPq_container-collapsed {
  margin-left: -244px;
}

@media screen and (max-width: 848px) {
  .-UuuPq_container {
    width: 350px;
  }

  .-UuuPq_container .-UuuPq_collapse-button-bar {
    display: none;
  }

  .-UuuPq_container-collapsed {
    margin-left: -334px;
  }
}

.-UuuPq_content {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
  overflow-y: auto;
}

.-UuuPq_content .-UuuPq_list {
  flex-direction: column;
  display: flex;
}

.-UuuPq_content .-UuuPq_list .-UuuPq_items .-UuuPq_section-header {
  height: 44px;
  align-items: center;
  margin-top: 0;
  padding: 12px 10px;
  display: flex;
}

.-UuuPq_content .-UuuPq_list .-UuuPq_items .-UuuPq_section-header > p {
  vertical-align: middle;
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
}

.-UuuPq_content .-UuuPq_list .-UuuPq_items .-UuuPq_section-header:not(:first-child) {
  margin-top: 10px;
}

.-UuuPq_content .-UuuPq_list .-UuuPq_items .-UuuPq_tawk-padding {
  height: 100px;
}

.-UuuPq_content .-UuuPq_list .-UuuPq_items a:hover {
  text-decoration: none;
}

.-UuuPq_powered-by-formx {
  color: #605e5c;
  margin: 16px 12px;
  font-size: 12px;
}

.-UuuPq_left-bar-item {
  border-bottom: 1px solid #ededed;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.-UuuPq_left-bar-item.-UuuPq_selected {
  background: #edebe9;
  border-left: 5px solid #25d0b1;
}

.-UuuPq_left-bar-item.-UuuPq_selected .-UuuPq_content {
  padding-left: 25px;
}

.-UuuPq_left-bar-item a {
  cursor: pointer;
  display: block;
}

.-UuuPq_left-bar-item .-UuuPq_content {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.-UuuPq_beta-tag {
  height: min-content;
  flex: 0 0;
}

.eiWGdq_container {
  width: 350px;
  z-index: 100;
  background-color: #fff;
  flex-direction: column;
  transition: left .3s;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 3.2px 7.2px #00000021, 0 .6px 1.8px #0000001a;
}

.eiWGdq_container-closed {
  width: 350px;
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -350px;
}

.eiWGdq_header {
  height: 68px;
  border-bottom: 1px solid var(--border-colors-divider, #edebe9);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.eiWGdq_logo {
  width: 150px;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.eiWGdq_backdrop {
  z-index: 50;
  position: fixed;
  inset: 0;
}

.eiWGdq_content {
  flex: 1 1 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.YLw1Va_layout {
  height: 100vh;
  flex-direction: row;
  display: flex;
  position: relative;
}

.YLw1Va_main {
  min-width: 0;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.YLw1Va_main .YLw1Va_main-inner {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.YLw1Va_main .YLw1Va_main-inner > .YLw1Va_content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.YLw1Va_main .YLw1Va_main-inner > .YLw1Va_with-leftbar {
  width: calc(100% - 16px);
}

.YLw1Va_main .YLw1Va_main-inner > .YLw1Va_with-full-leftbar {
  width: calc(100% - 260px);
}

@media screen and (max-width: 848px) {
  .YLw1Va_main .YLw1Va_main-inner > .YLw1Va_with-full-leftbar, .YLw1Va_main .YLw1Va_main-inner > .YLw1Va_with-leftbar {
    width: 100%;
  }

  .YLw1Va_main {
    padding-left: 0;
  }
}

.YLw1Va_top {
  height: 43px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.YLw1Va_bottom {
  height: 64px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.YLw1Va_right {
  z-index: 3;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
}

.YLw1Va_right .YLw1Va_right-inner {
  height: 100%;
  display: inline-block;
}

.YLw1Va_has-top {
  margin-top: 43px;
}

.YLw1Va_has-bottom {
  margin-bottom: 64px;
}

.YLw1Va_content-layout {
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
}

.YLw1Va_content-main {
  min-width: 0;
  flex: 1;
}

.YLw1Va_content-right {
  flex: 0;
}

.NrP7sG_input-field {
  margin-top: 12px;
}

.DtaMKW_lookup-id-text-field-label {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.DtaMKW_lookup-id-text-field-label .DtaMKW_info-icon {
  cursor: default;
  padding-left: 5px;
}

.DtaMKW_lookup-id-text-field-label .ms-TooltipHost {
  height: 14px;
}

.DtaMKW_lookup-id-section-compact {
  align-items: center;
  animation-name: DtaMKW_fade;
  animation-duration: 1s;
}

.DtaMKW_lookup-id-section-compact .DtaMKW_generated-lookup-id {
  word-wrap: break-word;
}

.DtaMKW_lookup-id-section {
  animation-name: DtaMKW_fade;
  animation-duration: 1s;
}

@keyframes DtaMKW_fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.DtaMKW_customize-button {
  color: #25d0b1;
  cursor: pointer;
  padding-left: 8px;
}

._4CFBrq_header {
  height: 43px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  display: flex;
}

._4CFBrq_header ._4CFBrq_collapse-menu-button {
  width: 42px;
  height: 42px;
  cursor: pointer;
  background: #25d0b1;
  justify-content: center;
  align-items: center;
  display: flex;
}

._4CFBrq_header ._4CFBrq_collapse-menu-button i {
  color: #fff;
  width: 12px;
  height: 12px;
  font-size: 12px;
}

._4CFBrq_header ._4CFBrq_collapse-menu-button:hover {
  background-color: #21bc9e;
}

._4CFBrq_header ._4CFBrq_collapse-menu-button:active {
  opacity: .7;
  background-color: #21bc9e;
}

@media screen and (min-width: 849px) {
  ._4CFBrq_header ._4CFBrq_collapse-menu-button {
    display: none;
  }
}

._4CFBrq_header ._4CFBrq_logo {
  float: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  margin: 0 12px;
  position: relative;
}

@media screen and (max-width: 848px) {
  ._4CFBrq_header ._4CFBrq_logo {
    display: none;
  }
}

._4CFBrq_header ._4CFBrq_space {
  flex: 1;
  display: flex;
}

._4CFBrq_header ._4CFBrq_active-button {
  background-color: #22bca0;
}

._4CFBrq_header ._4CFBrq_team-menu-with-notification {
  position: relative;
}

._4CFBrq_header ._4CFBrq_team-menu-with-notification .ms-Button-label {
  padding-right: 5px;
  position: relative;
}

._4CFBrq_header ._4CFBrq_team-menu-with-notification .ms-Button-label:after {
  content: "•";
  position: absolute;
  top: -5px;
  right: 0;
}

._4CFBrq_header ._4CFBrq_command-button {
  height: 43px;
  min-width: 43px;
  border-width: 0;
  margin-right: 0;
  padding: 0;
  color: #fff !important;
}

._4CFBrq_header ._4CFBrq_command-button > span {
  padding: 0 12px;
}

._4CFBrq_header ._4CFBrq_command-button > span > i {
  color: #fff !important;
}

._4CFBrq_header ._4CFBrq_command-button > span > span > span {
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 28px;
  overflow: hidden;
}

._4CFBrq_header ._4CFBrq_user-button > span > i {
  font-size: 14px;
}

._4CFBrq_header ._4CFBrq_user-button > span > i:after {
  content: "";
  width: 26px;
  height: 26px;
  border: 1px solid #fff;
  border-radius: 14px;
  display: block;
  position: relative;
  top: -22px;
}

._4CFBrq_header ._4CFBrq_menu {
  display: inline-block;
}

._4CFBrq_header ._4CFBrq_right {
  float: right;
}

._4CFBrq_header ._4CFBrq_icon-button {
  color: #fff;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
}

._4CFBrq_team-menu {
  min-width: 160px;
  max-width: 240px;
}

._4CFBrq_dropdown-menu {
  min-width: 128px;
  max-width: 240px;
}

._4CFBrq_username {
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 12px;
  font-size: 14px;
  line-height: 28px;
  overflow: hidden;
  color: #fff !important;
}

.w4UBWq_loading {
  text-align: center;
}

.w4UBWq_loading .w4UBWq_message {
  margin-top: 10px;
  font-weight: bold;
}

.ddVEta_paginator-container {
  margin-top: 10px;
}

.ddVEta_paginator-container .ddVEta_page-navigator {
  pointer-events: none;
  color: #a19f9d;
  margin-right: 8px;
  font-size: 14px;
}

.ddVEta_paginator-container .ddVEta_page-navigator.ddVEta_active {
  pointer-events: all;
  cursor: pointer;
  color: #25d0b1;
}

.ddVEta_paginator-container .ddVEta_page-index {
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.ddVEta_paginator-container .ddVEta_page-index.ddVEta_current-page {
  pointer-events: none;
  color: #a19f9d;
}

.Gq1kWa_spinner-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gNKxfq_table-container {
  width: 100%;
  color: #201f1e;
  -webkit-font-smoothing: antialiased;
  border-collapse: collapse;
  background-color: #fff;
  font-weight: 400;
  overflow-y: auto;
}

.gNKxfq_table-container th, .gNKxfq_table-container td {
  border: none;
  padding: 11px 12px;
  font-size: 14px;
}

.gNKxfq_table-container th {
  text-align: left;
  font-weight: 600;
}

.gNKxfq_table-container tbody > tr:hover {
  background-color: #f3f2f1;
}

.gNKxfq_table-container tr {
  border-bottom: 1px solid #edebe9;
}

.St04_G_list-container {
  margin-top: 20px;
}

.St04_G_resource-table-row {
  cursor: pointer;
}

.St04_G_resource-table-row-info {
  color: #605e5c;
  font-size: 12px;
}

.St04_G_spinner {
  min-width: 100px;
}

.St04_G_spinner div {
  align-items: baseline;
}

.St04_G_empty-state-text {
  font-weight: normal;
}

.HRfCNW_team-usage-table-row {
  cursor: pointer;
}

.HRfCNW_team-usage-table-row td:nth-child(1) {
  vertical-align: top;
}

.HRfCNW_team-usage-table-row .HRfCNW_basic-info {
  max-width: 272px;
}

.HRfCNW_team-usage-table-row .HRfCNW_basic-info .HRfCNW_name {
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
}

.HRfCNW_team-usage-table-row .HRfCNW_basic-info .HRfCNW_lookup-id {
  height: 16px;
  color: #898989;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.HRfCNW_team-usage-table-row .HRfCNW_detail-info, .HRfCNW_team-usage-table-row .HRfCNW_detail-info-capitalize {
  color: #898989;
}

.HRfCNW_team-usage-table-row .HRfCNW_detail-info-capitalize {
  text-transform: capitalize;
}

.zH7aza_empty-state-container {
  width: 100%;
  display: flex;
}

.zH7aza_empty-state-container .zH7aza_empty-state {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.zH7aza_empty-state-container .zH7aza_empty-state .zH7aza_empty-state-text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.zH7aza_empty-state-container .zH7aza_empty-state .zH7aza_empty-state-text label {
  margin-top: 0;
  font-weight: 500;
}

.jh6lJG_team-table-row {
  cursor: pointer;
}

.jh6lJG_team-table-row td:nth-child(1) {
  vertical-align: top;
}

.jh6lJG_team-table-row .jh6lJG_basic-info {
  max-width: 272px;
}

.jh6lJG_team-table-row .jh6lJG_basic-info .jh6lJG_name {
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
}

.jh6lJG_team-table-row .jh6lJG_basic-info .jh6lJG_lookup-id {
  height: 16px;
  color: #898989;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.jh6lJG_team-table-row .jh6lJG_detail-info, .jh6lJG_team-table-row .jh6lJG_detail-info-capitalize {
  color: #898989;
}

.jh6lJG_team-table-row .jh6lJG_detail-info-capitalize {
  text-transform: capitalize;
}

.o618MG_empty-state-container {
  width: 100%;
  display: flex;
}

.o618MG_empty-state-container .o618MG_empty-state {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.o618MG_empty-state-container .o618MG_empty-state .o618MG_empty-state-text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.o618MG_empty-state-container .o618MG_empty-state .o618MG_empty-state-text label {
  margin-top: 0;
  font-weight: 500;
}

.o618MG_bottom-content {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.o618MG_total-count {
  text-transform: lowercase;
  margin-top: 13px;
  font-size: 14px;
  font-weight: 400;
}

.-Crhuq_report {
  background-color: #fff;
  margin-bottom: 48px;
  padding: 32px;
  overflow-y: auto;
}

.-Crhuq_report .-Crhuq_tab-bar {
  margin-bottom: 24px;
  margin-left: -10px;
}

.-Crhuq_report .-Crhuq_section-title {
  margin-top: 25px;
  margin-bottom: 10px;
}

ul.KXbsNq_breadcrumb {
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  display: block;
  overflow: hidden;
}

ul.KXbsNq_breadcrumb > li {
  font-weight: 600;
  display: inline-block;
}

ul.KXbsNq_breadcrumb > li > i {
  color: #605e5c;
  font-size: 12px;
}

ul.KXbsNq_breadcrumb > li > div {
  cursor: default;
  padding: 6px 8px;
  display: inline-block;
}

ul.KXbsNq_breadcrumb > li > div.KXbsNq_pressable {
  cursor: pointer;
  color: #605e5c;
  font-weight: normal;
}

ul.KXbsNq_breadcrumb > li > div.KXbsNq_pressable:hover {
  background-color: #f3f2f1;
}

._67EwaW_team-info {
  margin-top: 26px;
  display: flex;
}

._67EwaW_team-info-text-container {
  margin-left: 24px;
  font-size: 16px;
  font-weight: 400;
}

._67EwaW_team-info-detail-columns {
  color: #898989;
  flex-direction: row;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
}

._67EwaW_team-info-detail-column:nth-child(2n) {
  margin-left: 32px;
}

._67EwaW_team-icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  height: 72px;
  width: 72px;
  color: #fff;
  background-color: #25d0b1;
  border-radius: 50%;
  align-self: start;
  padding: 20px;
  font-size: 32px;
  line-height: 32px;
}

.AUsY0W_nav-bar {
  margin-top: 0;
}

.AUsY0W_tab-bar {
  -webkit-user-select: none;
  user-select: none;
  margin-top: 20px;
}

.daog2W_team-detail {
  background-color: #fff;
  padding: 32px;
}

.t4UHgq_checkbox {
  margin-top: 10px;
}

.t4UHgq_separator {
  border-top: 1px solid #0000001a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.t4UHgq_section-header {
  color: #25d0b1;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 22px;
}

.t4UHgq_feature-flags-box {
  border: 1px solid var(--border-colors-divider, #edebe9);
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 24px;
  display: flex;
}

._0bDFa_container ._0bDFa_dropdown {
  max-width: 480px;
}

._0bDFa_container ._0bDFa_enabled-features {
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
}

._0bDFa_container ._0bDFa_buttons {
  align-items: center;
  display: flex;
}

._0bDFa_container ._0bDFa_enabled-features-title {
  font-weight: 600px;
  font-size: 16px;
}

._0bDFa_container ._0bDFa_checkbox {
  margin-top: 10px;
}

.z5N_gG_container {
  width: 400px;
  height: 200px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  flex-direction: column;
  justify-content: center;
  margin-top: -100px;
  margin-left: -200px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.z5N_gG_container .z5N_gG_icon-and-text {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.z5N_gG_container .z5N_gG_icon-and-text > i {
  margin-right: 12px;
  font-size: 40px;
}

.z5N_gG_container .z5N_gG_icon-and-text > label {
  font-size: 14pt;
  font-weight: 500;
}

.dCkpCa_modal h2 {
  text-transform: capitalize;
  margin-bottom: 20px;
}

.dCkpCa_modal .dCkpCa_message {
  text-align: center;
  margin-bottom: 20px;
}

.dCkpCa_modal .dCkpCa_buttons {
  text-align: center;
}

.dCkpCa_challenge {
  color: #898989;
}

.dCkpCa_destroy-button {
  color: #fff;
  background-color: #e13d3d;
  border-width: 0;
}

.dCkpCa_destroy-button:hover {
  color: #fff;
  background-color: #bd1c1c;
}

.dCkpCa_destroy-button:disabled {
  color: #7f8c8d;
  background-color: #ccc;
}

.dCkpCa_save-button {
  background-color: #ffd633;
  border-width: 0;
}

.dCkpCa_save-button:hover {
  background-color: #cca300;
}

.V71Uaq_permission-toggle {
  color: #201e1f;
  text-align: left;
  width: calc(100% + 24px);
  height: 43px;
  border-style: none;
  padding: 11px 8px 11px 12px;
  font-size: 14px;
  font-weight: 400;
  left: -12px;
}

.V71Uaq_permission-toggle .ms-Button-flexContainer {
  align-items: center;
}

.V71Uaq_permission-toggle .ms-Button-label {
  height: 100%;
  margin-top: 1px;
  margin-left: 0;
}

.V71Uaq_permission-toggle .ms-Button-menuIcon {
  margin-left: auto;
  margin-right: 0;
}

.CkMONW_team-table-row {
  height: 43px;
}

.CkMONW_team-table-row td:first-child {
  width: 100%;
}

.CkMONW_team-table-row td:nth-child(2) {
  min-width: 140px;
}

.CkMONW_invite-btn {
  color: #605e5c;
  border: 1px solid #e1dfdd;
}

.CkMONW_team-table td {
  padding: 0 12px;
}

.CkMONW_team-table td:nth-child(3) {
  min-width: 140px;
}

.CkMONW_team-members {
  margin-top: 20px;
}

.IEGGgW_copy {
  color: #201f1e;
  cursor: pointer;
  background-color: #f3f2f1;
  align-items: center;
  padding: 9px 8px;
  font-size: 14px;
  display: flex;
}

.IEGGgW_copy i {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.xPl0Ma_container {
  font-size: 14px;
  display: flex;
}

.xPl0Ma_container .xPl0Ma_main {
  width: min(564px, 100vw - 124px);
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #faf9f8;
  padding: 9px 2px 9px 8px;
  overflow: hidden;
}

.xPl0Ma_container .xPl0Ma_main .xPl0Ma_method {
  color: #1d9f87;
  margin-right: 8px;
}

.xPl0Ma_container .xPl0Ma_main .xPl0Ma_url {
  color: #83817e;
}

.xPl0Ma_container .xPl0Ma_main > * {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

.xPl0Ma_container .xPl0Ma_copy-wrapper {
  background-color: #faf9f8;
  padding: 4px 8px;
}

.xPl0Ma_container .xPl0Ma_copy-wrapper .xPl0Ma_copy {
  padding: 5px 6px 6px 2px;
  font-size: 12px;
}

.xPl0Ma_container .xPl0Ma_copy-wrapper .xPl0Ma_copy i {
  margin-right: 2px;
}

.Z1Vz2G_team-resource {
  margin-top: 20px;
}

.Z1Vz2G_team-resource .Z1Vz2G_resource-tab-item {
  cursor: pointer;
  color: #201f1e;
  border: 1px solid #cecece;
  border-radius: 2px;
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  display: inline-block;
}

.Z1Vz2G_team-resource .Z1Vz2G_resource-tab-item.Z1Vz2G_active {
  border-color: #25d0b1;
  font-weight: 600;
}

.Z1Vz2G_team-resource .Z1Vz2G_list-container {
  margin-top: 20px;
}

.Z1Vz2G_team-resource .Z1Vz2G_resource-table-row {
  cursor: pointer;
}

.Z1Vz2G_team-resource .Z1Vz2G_resource-table-row-info {
  color: #605e5c;
  font-size: 12px;
}

.Z1Vz2G_team-resource .Z1Vz2G_spinner {
  min-width: 100px;
}

.Z1Vz2G_team-resource .Z1Vz2G_spinner div {
  align-items: baseline;
}

.Z1Vz2G_team-resource .Z1Vz2G_download-button {
  min-width: 100px;
  cursor: pointer;
  color: #25d0b1;
  font-size: 12px;
  font-weight: 600;
}

._8dAjKq_frame {
  width: 60px;
  height: 60px;
  margin: 2px;
  display: inline-block;
}

._8dAjKq_frame > div {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

._8dAjKq_errored {
  background-color: #f8f8f8;
  border-radius: 2px;
}

.B7ctfW_grid-item {
  width: 282px;
  height: 368px;
  border-radius: 10px;
  margin-bottom: 16px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.6px 3.6px #0002, 0 .3px .9px #0000001c;
}

.B7ctfW_grid-item .B7ctfW_image-preview > img, .B7ctfW_grid-item .B7ctfW_image-preview > div {
  width: 250px;
  height: 250px;
  margin: 16px;
}

.B7ctfW_grid-item .B7ctfW_image-preview > img {
  object-fit: contain;
  cursor: pointer;
}

.B7ctfW_grid-item .B7ctfW_checkbox {
  background-color: #fff;
  position: absolute;
  top: 16px;
  left: 16px;
}

.B7ctfW_grid-item .B7ctfW_checkbox .ms-Checkbox-checkbox {
  margin-right: 0;
}

.B7ctfW_grid-item .B7ctfW_label {
  width: 90%;
  height: 40px;
  text-align: center;
  color: #201f1e;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 278px;
  overflow: hidden;
}

.B7ctfW_grid-item .B7ctfW_date {
  color: #605e5c;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 330px;
  left: 50%;
  transform: translate(-50%);
}

.B7ctfW_deleting {
  opacity: .5;
}

.B7ctfW_deleting img {
  filter: grayscale();
}

.B7ctfW_deleting-spinner {
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 16px;
}

.xg8V0q_container {
  background-color: #f3f2f1;
  flex-direction: column;
  display: flex;
}

.xg8V0q_header {
  width: 100%;
  min-width: min(100vw - 40px, 450px);
  color: #22bca0;
  background-color: #22bca0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  display: flex;
}

.xg8V0q_header .xg8V0q_title {
  color: #fff;
}

.xg8V0q_header .xg8V0q_labeller-button {
  color: #fff;
  background-color: #0000;
  border-color: #fff;
  padding: 6px 20px;
  transition: all .25s;
}

.xg8V0q_header .xg8V0q_labeller-button:hover {
  color: inherit;
  background-color: #fff;
}

.xg8V0q_header .xg8V0q_close-button {
  height: 24px;
  width: 24px;
  color: #fff;
}

.xg8V0q_header .xg8V0q_close-button:hover {
  color: inherit;
}

.xg8V0q_image-preview-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.xg8V0q_image-preview-container .xg8V0q_image > img {
  width: 100%;
  max-height: calc(100vh - 227px);
  object-fit: contain;
}

.xg8V0q_image-preview-container .xg8V0q_footer {
  color: #605e5c;
  justify-content: center;
  margin-top: 12px;
  display: flex;
}

.AoFCnq_container .AoFCnq_info-container {
  color: #605e5c;
  margin-bottom: 18px;
  padding: 12px 0;
  font-size: 12px;
  line-height: 16px;
}

.AoFCnq_container .AoFCnq_loading-container {
  margin-top: 196px;
}

.AoFCnq_container .AoFCnq_no-images {
  max-width: 518px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.AoFCnq_container .AoFCnq_no-images .AoFCnq_title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.AoFCnq_container .AoFCnq_no-images .AoFCnq_desc {
  color: #c4c4c4;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
}

.xXderW_custom-model-detail {
  width: 100%;
  margin-top: 20px;
}

.xXderW_custom-model-detail .xXderW_custom-model-list-link {
  color: #25d0b1;
  font-size: 12px;
  font-weight: 400;
}

.xXderW_custom-model-detail .xXderW_custom-model-list-link-arrow {
  color: #a19f9d;
  margin-left: 4px;
  font-size: 10px;
}

.xXderW_custom-model-detail .xXderW_custom-model-detail-name {
  color: #000;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 600;
}

.xXderW_custom-model-detail .xXderW_custom-model-detail-field {
  color: #201f1e;
  border: 1px solid #f3f2f1;
  border-radius: 2px;
  margin-top: 12px;
  margin-right: 16px;
  padding: 4px;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.xXderW_custom-model-detail .xXderW_custom-model-detail-field.xXderW_custom-model-detail-field-frozen {
  background-color: #f3f2f1;
}

.VyIKsW_request-logs-history {
  margin: -4px 34px 0;
}

.VyIKsW_request-logs-history table tr td:not(:first-child) {
  color: #605e5c;
  font-size: 12px;
}

.VyIKsW_request-logs-history .VyIKsW_paginator-wrapper {
  padding: 8px 6px;
}

.VyIKsW_updated_time {
  padding: 16px 4px;
  font-size: 14px;
}

.VyIKsW_empty-state-container {
  padding: 16px 4px;
}

.vRItrW_usage-history {
  min-width: 540px;
  margin: 0 30px;
}

.vRItrW_usage-history h1 {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 18px;
}

.vRItrW_usage {
  min-width: 550px;
  width: 100%;
  border-radius: 2px;
  margin-bottom: 24px;
  padding: 20px;
  display: inline-block;
  box-shadow: 0 1.6px 3.6px #0002, 0 .3px .9px #0000001c;
}

.vRItrW_usage .vRItrW_api-name-label {
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
}

.vRItrW_usage .vRItrW_total-label {
  font-weight: 400;
}

.vRItrW_usage .vRItrW_table_total {
  text-align: right;
  margin-top: 12px;
  padding-right: 12px;
}

.vRItrW_usage table tr th:nth-last-child(-n+4), .vRItrW_usage table tr td:nth-last-child(-n+4) {
  text-align: right;
  width: 100px;
}

.vRItrW_usage table tr td:nth-child(2) {
  color: #898989;
}

.vRItrW_usage .vRItrW_usage-row {
  cursor: pointer;
}

.vRItrW_usage .vRItrW_usage-row .vRItrW_chevron {
  margin-right: 6px;
  font-size: 9px;
  font-weight: 600;
}

.vRItrW_usage .vRItrW_token-title-row {
  color: #605e5c;
  font-size: 12px;
  font-weight: 600;
}

.vRItrW_usage .vRItrW_token-row {
  color: #605e5c;
  background: #faf9f8;
  font-size: 12px;
}

.G1D95G_usage-date-range-selector {
  min-width: 689px;
  margin: 24px 30px;
}

.G1D95G_usage-date-range-selector h1 {
  margin: 10px 0;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.G1D95G_usage-date-range-selector .G1D95G_date-picker-row {
  flex-direction: row;
  align-items: flex-end;
  margin-top: 12px;
  display: flex;
}

.G1D95G_usage-date-range-selector .G1D95G_date-picker-selectors {
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  display: flex;
}

.G1D95G_usage-date-range-selector .G1D95G_date-picker, .G1D95G_usage-date-range-selector .G1D95G_dropdown {
  max-width: 200px;
  height: 62px;
  flex: 1;
  padding: 0 5px;
}

.G1D95G_usage-date-range-selector .G1D95G_search-button {
  margin-left: 5px;
}

.G1D95G_usage-date-range-selector .G1D95G_dropdown-title {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

._6VpU6q_team-usage {
  margin: -18px -30px;
}

._6VpU6q_date-range-selector-with-toggle {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

._6VpU6q_date-range-selector-with-toggle ._6VpU6q_toggle-container {
  white-space: nowrap;
  padding: 22px 30px 22px 0;
}

._6c9UTa_team-list {
  background-color: #fff;
  margin-bottom: 48px;
  padding: 32px;
  overflow-y: auto;
}

._6c9UTa_team-list ._6c9UTa_title {
  margin-bottom: 8px;
}

._6c9UTa_team-list ._6c9UTa_search-filter {
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

._6c9UTa_team-list ._6c9UTa_search-input-field {
  width: 300px;
  padding-top: 10px;
}

._6c9UTa_team-list ._6c9UTa_search-input-field input {
  padding-left: 36px;
  padding-right: 8px;
}

._6c9UTa_team-list ._6c9UTa_search-icon {
  color: #25d0b1;
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  top: auto;
  bottom: 6px;
  left: 12px;
}

._6c9UTa_team-list ._6c9UTa_plan-filter-text, ._6c9UTa_team-list ._6c9UTa_plan-filter, ._6c9UTa_team-list ._6c9UTa_search-by-text, ._6c9UTa_team-list ._6c9UTa_search-by-dropdown {
  padding-top: 10px;
}

._6c9UTa_team-list ._6c9UTa_search-button {
  margin-bottom: -10px;
  margin-left: 16px;
}

._6c9UTa_team-list ._6c9UTa_plan-filter-text {
  margin-left: 16px;
  margin-right: 8px;
}

._6c9UTa_team-list ._6c9UTa_search-by-text {
  margin-right: 8px;
}

._6c9UTa_team-list ._6c9UTa_plan-filter, ._6c9UTa_team-list ._6c9UTa_search-by-dropdown {
  width: 120px;
}

._6c9UTa_team-list ._6c9UTa_search-by-dropdown {
  margin-right: 16px;
}

._6c9UTa_team-list ._6c9UTa_tab-bar {
  margin-bottom: 24px;
  margin-left: -10px;
}

.d1t1th9s {
  box-sizing: border-box;
  --overlay-top: var(--d1t1th9s-0);
  left: var(--d1t1th9s-1);
  top: var(--d1t1th9s-2);
  min-width: var(--d1t1th9s-3);
  min-height: var(--d1t1th9s-4);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 400px;
  max-height: calc(100vh - var(--d1t1th9s-5));
  font-family: var(--gdg-font-family);
  font-size: var(--gdg-editor-font-size);
  text-align: start;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: hidden;
}

@keyframes glide_fade_in-d1t1th9s {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.d1t1th9s.gdg-style {
  background-color: var(--gdg-bg-cell);
  box-shadow: 0 0 0 1px var(--gdg-accent-color), 0px 0px 1px #3e415666, 0px 6px 12px #3e415626;
  border-radius: 2px;
  animation: 60ms glide_fade_in-d1t1th9s;
}

.d1t1th9s.pad {
  padding: var(--d1t1th9s-6) 8.5px 3px;
}

.d1t1th9s .clip-region {
  border-radius: 2px;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.d1t1th9s .clip-region .gdg-growing-entry {
  height: 100%;
}

.d1t1th9s .clip-region input.gdg-input {
  width: 100%;
  border: 0;
  outline: none;
}

.d1t1th9s .clip-region textarea.gdg-input {
  border: 0;
  outline: none;
}

.m15w2ly5 {
  background-color: var(--gdg-bg-cell);
  background: linear-gradient(var(--gdg-bg-cell), var(--gdg-bg-cell-medium));
  z-index: 1;
  box-shadow: 0 0 0 1px var(--gdg-border-color), 0 2px 5px #00000014;
  border-radius: 4px;
  position: absolute;
  bottom: 44px;
  right: 44px;
  overflow: hidden;
}

.m15w2ly5 .header {
  width: 100%;
  height: 4px;
  background-color: var(--gdg-bg-header);
  box-shadow: 0 0 0 1px var(--gdg-border-color);
  position: absolute;
  top: 0;
  left: 0;
}

.m15w2ly5 .locationMarker {
  border: 1px solid var(--gdg-accent-color);
  background-color: var(--gdg-accent-light);
  position: absolute;
}

.s1jz82f8 .dvn-scroller {
  overflow: var(--s1jz82f8-0);
  transform: translate3d(0, 0, 0);
}

.s1jz82f8 .hidden {
  visibility: hidden;
}

.s1jz82f8 .dvn-scroll-inner {
  pointer-events: none;
  display: flex;
}

.s1jz82f8 .dvn-scroll-inner > * {
  flex-shrink: 0;
}

.s1jz82f8 .dvn-scroll-inner .dvn-spacer {
  flex-grow: 1;
}

.s1jz82f8 .dvn-scroll-inner .dvn-stack {
  flex-direction: column;
  display: flex;
}

.s1jz82f8 .dvn-underlay > * {
  position: absolute;
  top: 0;
  left: 0;
}

.s1jz82f8 canvas {
  outline: none;
}

.s1jz82f8 canvas * {
  height: 0;
}

.sxep88s {
  background-color: var(--gdg-bg-cell);
  color: var(--gdg-text-dark);
  border: 1px solid var(--gdg-border-color);
  font-size: var(--gdg-editor-font-size);
  -webkit-transform: translateX(var(--sxep88s-0));
  -ms-transform: translateX(var(--sxep88s-0));
  transform: translateX(var(--sxep88s-0));
  border-radius: 6px;
  padding: 8px;
  transition: transform .15s;
  position: absolute;
  top: 4px;
  right: 20px;
}

.sxep88s .search-bar-inner {
  display: flex;
}

.sxep88s .search-status {
  padding-top: 4px;
  font-size: 11px;
}

.sxep88s .search-progress {
  height: 4px;
  background-color: var(--gdg-text-light);
  position: absolute;
  bottom: 0;
  left: 0;
}

.sxep88s input {
  width: 220px;
  color: var(--gdg-textDark);
  background-color: var(--gdg-bg-cell);
  border: 0;
  outline: none;
}

.sxep88s button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: var(--gdg-text-medium);
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.sxep88s button:hover {
  color: var(--gdg-text-dark);
}

.sxep88s button .button-icon {
  width: 16px;
  height: 16px;
}

.sxep88s button:disabled {
  opacity: .4;
  pointer-events: none;
}

.r1kzy40b {
  background-color: var(--gdg-bg-header-has-focus);
  box-shadow: 0 0 0 1px var(--gdg-border-color);
  color: var(--gdg-text-group-header);
  min-height: var(--r1kzy40b-0);
  font: var(--gdg-header-font-style) var(--gdg-font-family);
  border: none;
  border-radius: 9px;
  outline: none;
  flex-grow: 1;
  padding: 0 8px;
}

.c1sqdbw3 {
  background-color: var(--gdg-bg-header);
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.wzg2m5k {
  min-width: 10px;
  min-height: 10px;
  max-width: 100%;
  max-height: 100%;
  width: var(--wzg2m5k-0);
  height: var(--wzg2m5k-1);
  contain: strict;
  position: relative;
  overflow: clip;
}

.wzg2m5k > :first-child {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.b1bqsp5z {
  flex-wrap: wrap;
  display: flex;
}

.b1bqsp5z .boe-bubble {
  height: 20px;
  background-color: var(--gdg-bg-bubble);
  color: var(--gdg-text-dark);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin: 2px;
  padding: 0 8px;
  display: flex;
}

.b1bqsp5z textarea {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.df2kt4a {
  flex-wrap: wrap;
  display: flex;
}

.df2kt4a .doe-bubble {
  height: 24px;
  background-color: var(--gdg-bg-cell);
  color: var(--gdg-text-dark);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 2px;
  padding: 0 8px;
  display: flex;
  box-shadow: 0 0 1px #3e415666, 0 1px 3px #3e415666;
}

.df2kt4a .doe-bubble img {
  height: 16px;
  object-fit: contain;
  margin-right: 4px;
}

.df2kt4a textarea {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.i1eozt10 {
  height: 100%;
  display: flex;
}

.i1eozt10 .centering-container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.i1eozt10 .centering-container img, .i1eozt10 .centering-container canvas {
  max-height: calc(100vh - var(--overlay-top)  - 20px);
  object-fit: contain;
  -webkit-user-select: none;
  user-select: none;
}

.i1eozt10 .centering-container canvas {
  max-width: 380px;
}

.i1eozt10 .edit-icon {
  width: 48px;
  height: 48px;
  color: var(--gdg-accent-color);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 12px;
  right: 0;
}

.i1eozt10 .edit-icon > * {
  width: 24px;
  height: 24px;
}

.i1eozt10 textarea {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.mlbeo71 {
  word-break: break-word;
  -webkit-touch-callout: default;
  padding-top: 6px;
}

.mlbeo71 > * {
  margin: 0;
}

.mlbeo71 :last-child {
  margin-bottom: 0;
}

.mlbeo71 p img {
  width: 100%;
}

.ijuk0po {
  width: 100%;
  height: 100%;
  resize: none;
  white-space: pre-wrap;
  min-width: 100%;
  font-size: var(--gdg-editor-font-size);
  line-height: 16px;
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.ijuk0po::-webkit-input-placeholder {
  color: var(--gdg-text-light);
}

.ijuk0po::-moz-placeholder {
  color: var(--gdg-text-light);
}

.ijuk0po:-ms-input-placeholder {
  color: var(--gdg-text-light);
}

.ijuk0po::placeholder {
  color: var(--gdg-text-light);
}

.invalid .ijuk0po {
  -webkit-text-decoration: underline #d60606;
  text-decoration: underline #d60606;
}

.saq3p5l {
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  min-width: 100%;
  font-size: var(--gdg-editor-font-size);
  line-height: 16px;
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  margin: 0;
  padding: 0 0 2px;
}

.gf8vzix {
  margin-top: 6px;
  position: relative;
}

.mdwzdl1 {
  min-width: var(--mdwzdl1-0);
  width: 100%;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  color: var(--gdg-text-dark);
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.mdwzdl1 .gf8vzix {
  min-width: 0;
  flex-shrink: 1;
}

.mdwzdl1 .spacer {
  flex: 1;
}

.mdwzdl1 .edit-icon {
  cursor: pointer;
  color: var(--gdg-accent-color);
  height: 24px;
  width: 24px;
  -webkit-transition: all "0.125s ease";
  transition: all "0.125s ease";
  border-radius: 6px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.mdwzdl1 .edit-icon > * {
  width: 16px;
  height: 16px;
}

.mdwzdl1 .edit-hover:hover {
  background-color: var(--gdg-accent-light);
  transition: background-color .15s;
}

.mdwzdl1 .checkmark-hover:hover {
  color: #fff;
  background-color: var(--gdg-accent-color);
}

.mdwzdl1 .md-edit-textarea {
  width: 0;
  height: 0;
  opacity: 0;
  margin-top: 25px;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
}

.mdwzdl1 .ml-6 {
  margin-left: 6px;
}

.n1czszh3 {
  color: var(--gdg-text-dark);
  margin: 6px 0 3px;
  display: flex;
}

.n1czszh3 > input {
  font-size: var(--gdg-editor-font-size);
  font-family: var(--gdg-font-family);
  color: var(--gdg-text-dark);
  background-color: var(--gdg-bg-cell);
  padding: 0;
}

.uf0sjo8 {
  min-height: 21px;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.uf0sjo8 .link-area {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--gdg-link-color);
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 8px;
  overflow: hidden;
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
}

.uf0sjo8 .edit-icon {
  width: 32px;
  color: var(--gdg-accent-color);
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uf0sjo8 .edit-icon > * {
  width: 24px;
  height: 24px;
}

.uf0sjo8 textarea {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

._6HyyIq_change-date-input-config {
  flex-direction: column;
  margin-top: 24px;
  display: flex;
}

.-By2oG_custom-model-config .-By2oG_replace-with-field-title, .-By2oG_custom-model-config .-By2oG_model-id-title {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
}

.-By2oG_custom-model-config {
  flex-direction: column;
  margin-top: 24px;
  display: flex;
}

.-By2oG_custom-model-config .-By2oG_model-id-value {
  font-size: 14px;
}

.-By2oG_custom-model-config .-By2oG_replace-with-field-title {
  margin-top: 16px;
}

.-By2oG_custom-model-config .-By2oG_change-date-format-dropdown {
  margin-top: 12px;
}

.sxCjaW_example {
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-end;
  margin-bottom: 10px;
  display: flex;
}

.sxCjaW_example .sxCjaW_example-key-value-table {
  flex-direction: row;
  display: flex;
}

.sxCjaW_example .sxCjaW_example-column {
  flex-direction: column;
  display: flex;
}

.sxCjaW_example .sxCjaW_example-column + .sxCjaW_example-column {
  margin-left: 5.9px;
}

.sxCjaW_example .sxCjaW_example-title-text {
  color: #0078d4;
  margin-bottom: 4px;
  font-size: 12px;
}

.sxCjaW_example .sxCjaW_example-cell-text {
  color: #201f1e;
  padding: 5px;
  font-size: 16px;
  font-weight: 600;
}

.sxCjaW_example .sxCjaW_example-cell-text.sxCjaW_key-cell {
  background: #dcfcbd;
}

.sxCjaW_example .sxCjaW_example-cell-text.sxCjaW_value-cell {
  background: #ffe2b7;
}

.sxCjaW_example .sxCjaW_example-desc-text {
  color: #0078d4;
  margin-left: 10px;
  font-size: 12px;
  display: block;
}

.M790zq_input-field {
  margin-bottom: 16px;
}

.M790zq_input-field .ms-Dropdown.is-disabled .ms-Dropdown-title {
  color: #0000;
}

.M790zq_values-title-row {
  flex-direction: column;
  margin: 30px 0 5px;
  display: flex;
}

.M790zq_values-title-row .M790zq_values-title-text {
  color: #201f1e;
  font-size: 18px;
  font-weight: bold;
}

.M790zq_values-title-row .M790zq_values-error-text {
  color: #a4262c;
  font-size: 12px;
}

.M790zq_pattern-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.M790zq_pattern-row .M790zq_regex-input {
  flex: 1 1 0;
}

.M790zq_pattern-row .M790zq_build-button {
  margin-top: 30px;
}

.M790zq_pattern-row .M790zq_regex-builder-button {
  align-self: flex-start;
  margin-top: 30px;
}

.M790zq_regex-builder-button {
  color: #0078d4;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.M790zq_regex-builder-button:hover {
  color: #0078d4b3;
}

.M790zq_key-section .M790zq_title-section {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 4px 8px;
}

.M790zq_key-section .M790zq_title-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.M790zq_key-section .M790zq_title-text {
  color: #201f1e;
  font-size: 18px;
  font-weight: 700;
}

.M790zq_key-section .M790zq_table-header-text {
  color: #201f1e;
  font-size: 14px;
}

.M790zq_key-section .M790zq_key-column {
  flex: 1 1 0;
}

.M790zq_key-section .M790zq_fuzzy-column {
  width: 156px;
  flex: none;
}

.M790zq_key-section .M790zq_table-header-cell {
  padding: 10px 4px 8px;
}

.M790zq_key-section .M790zq_table-header-row, .M790zq_key-section .M790zq_token-row {
  border-bottom: 1px solid #e9e9e9;
  flex-direction: row;
  display: flex;
}

.M790zq_key-section .M790zq_key-input {
  max-width: 200px;
  margin: 10px 0;
}

.M790zq_key-section .M790zq_table-cell {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.M790zq_key-section .M790zq_table-cell.M790zq_fuzzy-cell {
  justify-content: space-between;
}

.M790zq_key-section .M790zq_empty-row {
  text-align: center;
  color: #201f1e;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
  display: block;
}

.M790zq_key-section .M790zq_new-key-button {
  cursor: pointer;
  color: #0078d4;
  font-size: 13px;
  display: block;
}

.M790zq_key-section .M790zq_new-key-button:hover {
  color: #0078d4b3;
}

.M790zq_key-section .M790zq_regex-builder-button {
  align-self: flex-start;
  margin: 11px 0;
}

.M790zq_key-section .M790zq_section-error {
  color: #a4262c;
  font-size: 12px;
}

.sBhBqW_normal-icon-button, .sBhBqW_normal-icon-button:disabled {
  width: 34px;
  height: 34px;
  background-color: #0000;
}

.Ba45ja_container {
  height: 34px;
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.Ba45ja_content {
  background: #fff;
  border: 1px solid #edebe9;
  flex-direction: row;
  align-items: center;
  display: flex;
  box-shadow: 0 3.2px 7.2px #00000021, 0 .6px 1.8px #0000001a;
}

.Ba45ja_text {
  color: #a19f9d;
  text-align: center;
  height: 100%;
  border-left: 1px solid #edebe9;
  border-right: 1px solid #edebe9;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.H2FdNW_grab-mouse-cursor {
  cursor: move;
  cursor: grab;
}

.H2FdNW_grab-mouse-cursor:active {
  cursor: move;
  cursor: grabbing;
}

.H2FdNW_container {
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
}

.H2FdNW_single-image-frame {
  position: absolute;
  inset: 0;
}

.H2FdNW_single-image-frame-scrollable {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  overflow: auto;
}

.H2FdNW_single-image-frame-scrollable::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.H2FdNW_single-image-frame-viewport, .H2FdNW_single-image-frame-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.H2FdNW_single-image-container {
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
}

.H2FdNW_multiple-image-container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.H2FdNW_multiple-image-content {
  position: absolute;
  inset: 0;
}

.H2FdNW_multiple-image-viewport {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.H2FdNW_multiple-image-scrollable-view {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  overflow: auto;
}

.H2FdNW_multiple-image-scrollable-view::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.H2FdNW_multiple-image-content-list {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.H2FdNW_bottom-overlay-area {
  flex-direction: row;
  gap: 8px;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}

.H2FdNW_image-view-container {
  margin: auto;
  position: relative;
}

.H2FdNW_image-view-overlay {
  position: relative;
  inset: 0;
}

.H2FdNW_image-view-text-area {
  background-color: #25d0b11f;
  border: 1px solid #25d0b1;
  position: absolute;
}

.H2FdNW_image-view-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.H2FdNW_image-view-img {
  transform-origin: center;
}

.UdLF6G_panel .UdLF6G_option-detail {
  border-top: 1px solid #edebe9;
  margin-top: 20px;
}

.UdLF6G_panel .UdLF6G_option-detail .UdLF6G_key-value-config-view {
  padding: 26px 0;
}

.UdLF6G_panel .UdLF6G_footer {
  border-top: 1px solid #edebe9;
  justify-content: end;
  margin: 0 16px;
  padding: 10px 0 30px;
  display: flex;
}

.UdLF6G_panel .UdLF6G_footer button {
  margin: 0 6px;
}

.UdLF6G_panel .UdLF6G_footer button:last-child {
  margin: 0 0 0 6px;
}

.DI50FG_editor {
  flex: 1;
  align-items: center;
  display: flex;
}

.DI50FG_editor input {
  border: none;
  flex: 1;
  margin-right: 12px;
}

.DI50FG_editor input:focus-visible {
  outline: none;
}

.yoDTya_editor {
  width: 0;
  flex: 1;
  display: flex;
}

.yoDTya_editor input {
  border: none;
}

.yoDTya_editor input:focus {
  outline: none;
}

.ot30gW_editor {
  width: 0;
  flex: 1;
  align-items: center;
  display: flex;
}

.ot30gW_editor input {
  width: 40px;
  border: none;
}

.ot30gW_editor input:focus {
  outline: none;
}

.-WJTcW_container .-WJTcW_hint {
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid #edebe9;
  flex-direction: row;
  padding: 10px;
  display: flex;
}

.-WJTcW_container .-WJTcW_hint .-WJTcW_title {
  color: #323130;
  margin-right: 16px;
  font-size: 10px;
  font-weight: 700;
}

.-WJTcW_container .-WJTcW_hint .-WJTcW_description {
  color: #323130;
  flex: 1;
  font-size: 10px;
}

.-WJTcW_container .-WJTcW_hint .-WJTcW_description--bold {
  font-weight: 700;
}

.-WJTcW_container .-WJTcW_hint .-WJTcW_description--highlight {
  color: #25d0b1;
}

.-WJTcW_container .-WJTcW_table {
  max-height: calc(100vh - 250px);
  outline: .5px solid #edebe9;
}

.-WJTcW_container .-WJTcW_table--no-result {
  display: none;
}

.-WJTcW_container .-WJTcW_empty-view {
  justify-content: center;
  padding: 20px;
  display: flex;
}

.-WJTcW_container .-WJTcW_empty-view .-WJTcW_title {
  font-size: 14px;
}

.-WJTcW_tooltip {
  text-align: center;
  min-width: 60px;
  color: #605e5c;
  background: #fff;
  border-radius: 2px;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  box-shadow: 0 6.4px 14.4px #00000021, 0 1.2px 3.6px #0000001a;
}

.-WJTcW_tooltip .-WJTcW_tooltip-highlight-text {
  color: #0078d4;
}

.ehya9W_header .ehya9W_ordering-btn {
  font-size: 12px;
}

.ehya9W_header {
  border-bottom: 1px solid #edebe9;
  align-items: center;
  display: flex;
}

.ehya9W_header .ehya9W_nav-menu-btn {
  color: #a19f9d;
}

.ehya9W_header .ehya9W_nav-menu-btn--toggled {
  color: #25d0b1;
}

.ehya9W_header .ehya9W_ordering-btn {
  margin-left: 12px;
}

.ehya9W_header .ehya9W_csv-file-input {
  display: none;
}

.ehya9W_nav-bar {
  border-bottom: 1px solid #edebe9;
  align-items: center;
  padding: 8px 12px;
  display: flex;
}

.ehya9W_nav-bar .ehya9W_breadcrumb {
  margin: 0;
}

.ehya9W_nav-bar .ehya9W_button-group {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.ehya9W_nav-bar .ehya9W_button-group button {
  margin-left: 8px;
}

.ehya9W_nav-bar .ehya9W_button-group .ehya9W_save-button {
  color: #000;
  background-color: #ffd633;
  border-color: #ffd633;
}

.avR5Ta_nav-bar {
  align-items: center;
  display: flex;
}

.avR5Ta_nav-bar .avR5Ta_breadcrumb {
  margin: 0;
}

.avR5Ta_nav-bar .avR5Ta_button-group {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.avR5Ta_tooltip-text {
  font-size: 12px;
}

.avR5Ta_tooltip-text--highlight {
  color: #0078d4;
  font-size: 12px;
  font-weight: 600;
}

.jEM3Ja_link-group {
  border-bottom: 1px solid #edebe9;
  align-items: center;
  padding: 7px 10px;
  display: flex;
}

.jEM3Ja_link-group .jEM3Ja_label {
  margin-left: 8px;
  font-size: 10px;
  font-weight: 700;
}

.jEM3Ja_menu-link--error {
  color: #a4262c;
  background-color: #fde7e9;
}

.jEM3Ja_menu-link--error:hover {
  color: #a4262c;
}

._9j-NGq_data-non-saved-prompt-dialog .ms-Dialog-main {
  min-height: 150px;
}

._9j-NGq_data-non-saved-prompt-dialog .ms-Dialog-subText {
  color: inherit;
}

._9j-NGq_data-non-saved-prompt-dialog ._9j-NGq_button-actions {
  align-items: center;
  display: flex;
}

._9j-NGq_data-non-saved-prompt-dialog ._9j-NGq_cancel-button {
  border: none;
  margin-left: -8px;
}

._9j-NGq_data-non-saved-prompt-dialog ._9j-NGq_cancel-button:hover {
  opacity: .7;
  color: inherit;
}

._9j-NGq_data-non-saved-prompt-dialog ._9j-NGq_cancel-button:active {
  opacity: .3;
}

._9j-NGq_data-non-saved-prompt-dialog ._9j-NGq_dont-save-button {
  margin-left: auto;
  margin-right: 10px;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_command-group button, .HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_ordering-btn {
  font-size: 12px;
}

.HxtzcW_form {
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.HxtzcW_form .HxtzcW_nav-bar {
  border-bottom: 1px solid #edebe9;
  padding: 8px 12px;
}

.HxtzcW_form .HxtzcW_container {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_nav-menu {
  min-width: 237px;
  border-right: 1px solid #edebe9;
  flex: 1;
  display: none;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_nav-menu--expanded {
  height: 100%;
  display: block;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header {
  border-bottom: 1px solid #edebe9;
  align-items: center;
  display: flex;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_nav-menu-btn {
  color: #a19f9d;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_nav-menu-btn--toggled {
  color: #25d0b1;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_ordering-btn {
  margin-left: 12px;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_command-group {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_command-group .HxtzcW_divider {
  height: 24px;
  width: 1px;
  background: #edebe9;
  margin-right: 16px;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_command-group button {
  margin-right: 8px;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_header .HxtzcW_csv-file-input {
  display: none;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_content {
  background-color: #faf9f8;
  flex: 1;
  padding-bottom: 48px;
  display: flex;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_footer {
  background-color: #fff;
  border-top: 1px solid #edebe9;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_footer .HxtzcW_count-label {
  color: #605e5c;
  font-size: 12px;
}

.HxtzcW_form .HxtzcW_container .HxtzcW_content-container .HxtzcW_footer .HxtzcW_link {
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

._n-CtG_report-error-button {
  align-self: flex-end;
  margin-top: 32px;
}

.wzJ7mq_audit-log-job-table {
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  display: flex;
}

.wzJ7mq_audit-log-job-table > * {
  width: 575px;
  height: 43px;
  border-bottom: 1px solid #ededed;
  padding-left: 12px;
}

.wzJ7mq_table-header {
  display: flex;
}

.wzJ7mq_table-header > * {
  align-self: center;
  font-weight: 600;
}

.wzJ7mq_available-csv {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.wzJ7mq_csv-date {
  width: 75%;
}

.wzJ7mq_csv-download {
  font-size: 12px;
  font-weight: 600;
  color: #25d0b1 !important;
}

.wzJ7mq_generating {
  font-size: 12px;
  color: #605e5c !important;
}

.YPbc5G_section {
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 20px;
}

.YPbc5G_form {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.YPbc5G_form > * {
  margin-right: 12px;
}

.YPbc5G_headMessage {
  margin-bottom: 21px;
}

.YPbc5G_head {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

.YPbc5G_message {
  margin-top: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: block;
}

.YPbc5G_dropdown {
  width: 200px;
}

.U2D8Na_formatter-step-card {
  width: 248px;
  max-width: 248px;
  min-height: 94px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  box-shadow: 0 .3px .9px #0000001a, 0 1.6px 3.6px #00000021;
}

.U2D8Na_formatter-step-card:hover {
  border: 1px solid #25d0b1;
  border-radius: 5px;
}

.U2D8Na_formatter-step-card .U2D8Na_title {
  color: #323130;
  margin-left: 14px;
  padding-top: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.U2D8Na_formatter-step-card .U2D8Na_desc {
  color: #a19f9d;
  margin: 4px 14px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.U2D8Na_formatter-step-selector {
  width: 550px;
  height: 100%;
  box-shadow: 0 0 15px #0000001a;
}

.U2D8Na_formatter-step-selector .U2D8Na_heading {
  color: #323130;
  border-bottom: 1px solid #edebe9;
  padding: 26px 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.U2D8Na_formatter-step-selector .U2D8Na_grid {
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin: 24px 20px;
  display: grid;
}

.cIXPVa_small-icon-button {
  width: 32px;
  height: 32px;
  color: #a19f9d;
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cIXPVa_small-icon-button > i {
  height: 16px;
  width: 16px;
  display: block;
}

.cIXPVa_small-icon-button > i > svg {
  height: 16px;
  width: 16px;
}

.cIXPVa_small-icon-button:hover {
  color: #a4262c;
  background-color: #ffe7e7;
}

.cIXPVa_small-icon-button:active {
  color: #a4262c;
  background: linear-gradient(0deg, #0003, #0003), #ffe7e7;
}

.JeDBFa_formatter-step-item {
  max-width: 836px;
  width: calc(100% - 40px);
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-shadow: 0 0 15px #0000000d;
}

.JeDBFa_formatter-step-item:hover {
  background-color: #f3f2f1;
}

.JeDBFa_formatter-step-item .JeDBFa_left {
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.JeDBFa_formatter-step-item .JeDBFa_right {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.JeDBFa_formatter-step-item .JeDBFa_right .JeDBFa_label {
  color: #605e5c;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #edebe9;
  border-radius: 4px;
  margin-left: 6px;
  padding: 4px 10px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  overflow-x: hidden;
}

.JeDBFa_formatter-step-item .JeDBFa_right .JeDBFa_trash-icon {
  margin-left: 12px;
  margin-right: 15px;
}

.JeDBFa_formatter-step-item .JeDBFa_drag-icon {
  opacity: 0;
  color: #bdbdbd;
  margin-top: 2px;
  margin-left: 12px;
}

.JeDBFa_formatter-step-item .JeDBFa_index {
  color: #a19f9d;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  display: inline-block;
}

.JeDBFa_formatter-step-item .JeDBFa_item-title {
  color: #323130;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  display: inline-block;
}

.JeDBFa_formatter-step-item-error {
  border: 1px solid #a4262c;
}

.JeDBFa_formatter-step-item-error .JeDBFa_index, .JeDBFa_formatter-step-item-error .JeDBFa_item-title {
  color: #a4262c;
}

.JeDBFa_formatter-step-item-selected {
  border: 1px solid #25d0b1;
}

.JeDBFa_formatter-editor {
  height: 100%;
  width: 100%;
  background-color: #faf9f8;
  flex-direction: column;
  display: flex;
}

.JeDBFa_formatter-editor .JeDBFa_title {
  background-color: #fff;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  padding: 16px 20px;
  display: flex;
}

.JeDBFa_formatter-editor .JeDBFa_title .JeDBFa_heading {
  color: #323130;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.JeDBFa_formatter-editor .JeDBFa_title .JeDBFa_desc {
  color: #a19f9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.JeDBFa_formatter-editor .JeDBFa_title .JeDBFa_error {
  color: #a4262c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.JeDBFa_formatter-editor .JeDBFa_vertical-line {
  height: 30px;
  width: 1px;
  background-color: #ccc;
  margin: 10px auto;
}

.JeDBFa_formatter-editor .JeDBFa_add-button {
  color: #fff;
  width: 32px;
  height: 32px;
  min-width: 32px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.JeDBFa_formatter-editor .JeDBFa_add-button .ms-Icon {
  color: #fff;
}

.JeDBFa_formatter-editor .JeDBFa_add-step {
  text-align: center;
  color: #25d0b1;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.JeDBFa_formatter-editor .JeDBFa_placeholder {
  margin-top: 24px;
}

.JeDBFa_formatter-editor .JeDBFa_placeholder .JeDBFa_text {
  text-align: center;
  color: #a19f9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.JeDBFa_formatter-editor .JeDBFa_step-list {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.-mVGnq_remove-characters .-mVGnq_grid {
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.-mVGnq_remove-characters .-mVGnq_filter-group {
  margin-bottom: 16px;
}

.-mVGnq_remove-characters .-mVGnq_custom-value .ms-TextField-fieldGroup {
  min-height: 150px;
}

.-mVGnq_remove-characters .-mVGnq_info-icon {
  color: #25d0b1;
  margin-left: 8px;
}

.LtrOra_swap-month-day .LtrOra_choice-group .ms-ChoiceField {
  width: 100%;
  border: 1px solid #d2d0ce;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
}

.LtrOra_keep-only-one-language .LtrOra_choice-group {
  margin-bottom: 8px;
}

.LtrOra_keep-only-one-language .LtrOra_choice-group .ms-ChoiceFieldGroup-flexContainer {
  justify-content: space-between;
  display: flex;
}

.LtrOra_keep-only-one-language .LtrOra_choice-group .ms-ChoiceField {
  width: 232px;
  border: 1px solid #d2d0ce;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  display: inline-block;
}

.LtrOra_keep-only-one-language .LtrOra_hint-text {
  color: #a19f9d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._9_LGnG_container {
  color: #201f1e;
  background-color: #f3f2f1;
  border-radius: 2px;
  align-items: center;
  padding: 0;
  line-height: 20px;
  display: inline-flex;
}

._9_LGnG_container-error {
  background-color: #ffe7e7;
}

._9_LGnG_icon-label-group {
  gap: 6px;
  padding: 3px 8px;
  display: inline-flex;
}

._9_LGnG_icon {
  size: 12px;
  color: #0078d4;
}

._9_LGnG_icon-error {
  color: #eb5757;
}

._9_LGnG_label {
  color: #201f1e;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: hidden;
}

._9_LGnG_cancel-button {
  gap: 10px;
  padding: 7px 5px;
  display: inline-flex;
}

._9_LGnG_cancel-button:focus, ._9_LGnG_cancel-button:hover {
  background-color: inherit;
}

.v9NEXG_cursor-pointer {
  cursor: pointer;
}

.v9NEXG_formatter-step-editor-section {
  margin: 16px 20px;
  box-shadow: 0 0 15px #0000001a;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-header-text {
  color: #323130;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-header-expand-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-header-expand-button .ms-Icon {
  height: 16px;
  width: 16px;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-header-expand-button > svg {
  color: #212121;
  height: 16px;
  width: 16px;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-desc {
  color: #a19f9d;
  padding: 4px 16px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-desc a, .v9NEXG_formatter-step-editor-section .v9NEXG_section-desc a:visited {
  color: #25d0b1;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-content {
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

.v9NEXG_formatter-step-editor-section .v9NEXG_section-footer {
  flex-direction: row-reverse;
  margin-top: 20px;
  display: flex;
}

.v9NEXG_formatter-step-editor-section-error .v9NEXG_section-header-text {
  color: #a4262c;
}

.v9NEXG_formatter-field-selector .v9NEXG_error {
  color: #a4262c;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.v9NEXG_formatter-field-selector .ms-Dropdown-title {
  height: auto;
  padding-left: 0;
}

.v9NEXG_formatter-field-selector .ms-Dropdown-titleIsPlaceHolder {
  padding-left: 8px;
}

.v9NEXG_formatter-condition-matcher .v9NEXG_condition-matcher-desc {
  color: #a19f9d;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.v9NEXG_formatter-condition-matcher .ms-Dropdown-container {
  margin-top: 14px;
}

.v9NEXG_formatter-condition-matcher .ms-TextField {
  margin-top: 14px;
}

.v9NEXG_formatter-output-settings .v9NEXG_output-choice-group {
  margin-bottom: 8px;
}

.v9NEXG_formatter-output-settings .v9NEXG_output-choice-group .ms-ChoiceFieldGroup-flexContainer {
  justify-content: space-between;
  display: flex;
}

.v9NEXG_formatter-output-settings .v9NEXG_output-choice-group .ms-ChoiceField {
  width: 228px;
  border: 1px solid #d2d0ce;
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  display: inline-block;
}

.v9NEXG_formatter-output-settings .v9NEXG_new-field {
  margin-top: 18px;
  margin-bottom: 18px;
}

.v9NEXG_formatter-output-settings .v9NEXG_output-desc {
  color: #a19f9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.v9NEXG_formatter-step-editor {
  width: 550px;
  height: 100%;
  background: #fff;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.v9NEXG_formatter-step-editor .v9NEXG_editor-header {
  border-bottom: 1px solid #edebe9;
  justify-content: space-between;
  align-items: center;
  padding: 26px 20px;
  display: flex;
}

.v9NEXG_formatter-step-editor .v9NEXG_editor-header-text {
  color: #323130;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.v9NEXG_formatter-step-editor .v9NEXG_sections {
  overflow-y: scroll;
}

.v9NEXG_formatter-dropdown-field-list {
  text-align: center;
  border-radius: 0 0 3px 2px;
  padding: 16px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.v9NEXG_formatter-dropdown-field-list .v9NEXG_error {
  color: #a4262c;
}

.v9NEXG_formatter-dropdown-field-list .v9NEXG_error .v9NEXG_try-again {
  background-color: inherit;
  cursor: pointer;
  color: #0078d4;
  border: none;
  display: inline-block;
}

.v9NEXG_formatter-dropdown-field-list .v9NEXG_empty {
  color: #a19f9d;
}

.v9NEXG_formatter-selection-field-tag-container {
  flex-wrap: wrap;
  gap: 3px;
  padding: 2px;
  display: flex;
}

._2zbepW_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

._2zbepW_container ._2zbepW_navbar-top {
  justify-content: space-between;
  align-content: top;
  flex-direction: row;
  flex-shrink: 0;
  margin: 8px 12px;
  display: flex;
}

._2zbepW_container ._2zbepW_navbar-bottom {
  height: 44px;
  flex-direction: row;
  flex-shrink: 0;
  place-content: center space-between;
  margin: -2px 12px 0;
  display: flex;
  position: relative;
  bottom: -2px;
}

._2zbepW_container > hr {
  height: 2px;
  background-color: #edebe9;
  border: 0;
  flex-shrink: 0;
  margin: 0 12px;
}

._2zbepW_container ._2zbepW_scrollable-view {
  flex: 1 1 0;
  overflow: auto;
}

._2zbepW_container ._2zbepW_content {
  width: 100%;
  height: 100%;
}

.OG6X1a_container {
  counter-reset: step;
  padding: 20px;
}

.OG6X1a_container .OG6X1a_step {
  max-width: 568px;
  border-left: 1px solid #e1dfdd;
  margin-left: 14px;
  padding-bottom: 36px;
  padding-left: 28px;
  position: relative;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_title {
  margin-bottom: 12px;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_description {
  color: #605e5c;
  padding: 0 0 12px;
  line-height: 24px;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_description b {
  font-weight: 600;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_description code {
  background-color: #f3f2f1;
  border-radius: 4px;
  padding: 0 4px;
  font-family: monospace;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_action-button {
  border: 1.5px solid #8a8886;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 600;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_action-button div.ms-Spinner {
  margin-right: 8px;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_action-button:disabled {
  border: 0;
}

.OG6X1a_container .OG6X1a_step .OG6X1a_action-button:disabled div.ms-Spinner {
  filter: grayscale();
  margin-right: 8px;
}

.OG6X1a_container .OG6X1a_step:before {
  counter-increment: step;
  content: "0" counter(step);
  width: 24px;
  height: 24px;
  color: #25d0b1;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 2px solid #25d0b1;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: block;
  position: absolute;
  top: -1px;
  left: -15px;
}

.OG6X1a_container .OG6X1a_step:first-of-type {
  margin-top: 31px;
}

.OG6X1a_container .OG6X1a_step:last-child {
  border-left: 0;
}

.OG6X1a_container .OG6X1a_info {
  color: #20201e;
  background-color: #fffbef;
  border-left: 3px solid #ffd633;
  border-radius: 2px;
  margin: 12px;
  padding: 8px;
  font-size: 14px;
  display: inline-block;
}

.OG6X1a_container .OG6X1a_info h3 {
  align-items: center;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.OG6X1a_container .OG6X1a_info h3 i {
  margin-right: 8px;
}

.OG6X1a_container .OG6X1a_info a {
  color: #20201e;
  text-decoration: underline;
}

.OG6X1a_container .OG6X1a_info a:hover {
  color: #666;
}

.AZuhVa_title {
  font-weight: 600;
}

.AZuhVa_desc {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.tfZdCW_info-note {
  background-color: #f3f2f1;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 4px 12px 4px 32px;
  display: flex;
  position: relative;
}

.tfZdCW_info-note > span {
  margin: 6px 0;
}

.tfZdCW_info-note .tfZdCW_icon {
  font-size: 14px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.Hru1VG_container {
  color: #201f1e;
  flex-direction: row;
  padding: 20px;
  display: flex;
}

.Hru1VG_container table.Hru1VG_model-version-table {
  flex-grow: 1 2;
  text-align: left;
  border-collapse: collapse;
  width: calc(100% - 389px);
}

.Hru1VG_container table.Hru1VG_model-version-table tr {
  border-bottom: 1px solid #edebe9;
}

.Hru1VG_container table.Hru1VG_model-version-table th {
  height: 51px;
  font-size: 14px;
  font-weight: 600;
}

.Hru1VG_container table.Hru1VG_model-version-table th.Hru1VG_created-at-column > span {
  cursor: pointer;
}

.Hru1VG_container table.Hru1VG_model-version-table th.Hru1VG_created-at-column > span > i {
  color: #605e5c;
  margin-left: 4px;
  font-size: 12px;
}

.Hru1VG_container table.Hru1VG_model-version-table th.Hru1VG_actions-column {
  padding-left: 10px;
}

.Hru1VG_container table.Hru1VG_model-version-table td {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Hru1VG_container table.Hru1VG_model-version-table td.Hru1VG_tag-column {
  max-width: 296px;
  width: 296px;
}

.Hru1VG_container table.Hru1VG_model-version-table td.Hru1VG_created-at-column {
  max-width: 216px;
  width: 216px;
}

.Hru1VG_container table.Hru1VG_model-version-table td.Hru1VG_labels-column > div {
  height: 51px;
  flex-direction: column;
  justify-content: center;
  margin-right: 24px;
  display: flex;
}

.Hru1VG_container table.Hru1VG_model-version-table td.Hru1VG_labels-column > div > div {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
}

.Hru1VG_container table.Hru1VG_model-version-table td.Hru1VG_actions-column {
  width: 159px;
}

.Hru1VG_container table.Hru1VG_model-version-table td button {
  width: 159px;
  font-size: 14px;
  font-weight: 600;
}

.Hru1VG_container table.Hru1VG_model-version-table td button i.ms-Icon {
  margin-right: 8px;
}

.Hru1VG_container table.Hru1VG_model-version-table td button:disabled {
  border: 0;
}

.Hru1VG_container table.Hru1VG_model-version-table td button:disabled div.ms-Spinner {
  filter: grayscale();
  margin-right: 8px;
}

.Hru1VG_container div.Hru1VG_info {
  width: 365px;
  flex-shrink: 0;
  margin-top: 4px;
  margin-left: 24px;
}

.Hru1VG_no-interaction {
  pointer-events: none;
}

.dNY13q_content {
  min-width: 405px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.dNY13q_header {
  color: #201f1e;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.dNY13q_footer {
  justify-content: flex-end;
  gap: 10px;
  display: flex;
}

.YRieUG_danger-button {
  color: #201f1e;
  background-color: #ffd633;
  border: none;
}

.YRieUG_danger-button:hover {
  color: #201f1e;
  background-color: #cca300;
  border: none;
}

.YRieUG_danger-button:active {
  color: #201f1e;
  background-color: #997a00;
  border: none;
}

.YRieUG_danger-button:disabled {
  color: #a29f9b;
  background-color: #f4f4f4;
  border: none;
}

.q50HsG_footer {
  justify-content: space-between;
  gap: 10px;
  display: flex;
}

.q50HsG_footer-section {
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

.dVPRZW_container {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.dVPRZW_content {
  flex-direction: column;
  flex: 1;
  gap: 17px;
  padding: 20px;
  display: flex;
  overflow: auto;
}

.dVPRZW_block {
  border: 1px solid #edebe9;
  border-radius: 5px;
  padding: 20px;
}

.dVPRZW_block-title {
  color: #323130;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.dVPRZW_block-content {
  max-width: 485px;
  flex-direction: column;
  display: flex;
}

.dVPRZW_workspace-setups__content {
  gap: 20px;
}

.dVPRZW_workspace-setups__header {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.dVPRZW_workspace-setups__header-title {
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.dVPRZW_workspace-setups__header-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.dVPRZW_connected_workspace__container {
  flex-direction: column;
  gap: 28px;
  display: flex;
}

.dVPRZW_connected_workspace__list, .dVPRZW_connected_workspace__list--hidden {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.dVPRZW_connected_workspace__list-title {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.dVPRZW_workspace-item__container {
  border-top: 1px solid #edebe9;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  display: flex;
}

.dVPRZW_workspace-item__name {
  color: #201f1e;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.dVPRZW_general-settings__content {
  gap: 10px;
}

.dVPRZW_general-settings__fields {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.dVPRZW_general-settings__field-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.dVPRZW_advanced-settings__content {
  gap: 10px;
}

.dVPRZW_advanced-settings__fields {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.dVPRZW_general-settings__extractor-type .ms-TextField-fieldGroup {
  border: none;
}

.dVPRZW_general-settings__extractor-type .ms-TextField-field {
  background-color: #faf9f8;
  border-color: #faf9f8;
  border-radius: 2px;
}

.dVPRZW_contact-us__content {
  gap: 10px;
}

.dVPRZW_contact-us__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.dVPRZW_contact-us__description a {
  color: #53b2a1;
}

.dVPRZW_footer__container {
  padding: 0 20px 30px;
}

.dVPRZW_footer__separator {
  height: 1px;
  background-color: #edebe9;
  margin-bottom: 11px;
}

.dVPRZW_footer__buttons {
  justify-content: flex-end;
  display: flex;
}

.dVPRZW_hide {
  display: none;
}

.dVPRZW_image-frame {
  width: 223px;
  height: 100px;
  margin-bottom: 10px;
}

.dVPRZW_image-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dVPRZW_upload-button-wrapper {
  display: flex;
}

.dVPRZW_remove-image-button {
  color: #a4262c;
  background: #a4262c0d;
  margin-left: 5px;
}

.dVPRZW_import-buttons {
  flex: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.dVPRZW_document-processing-mode-label__container {
  margin-top: 2px;
  padding-left: 28px;
}

.dVPRZW_document-processing-mode-label__text {
  color: #201f1e;
}

.dVPRZW_document-processing-mode-label__description, .dVPRZW_document-processing-mode-label__disabled .dVPRZW_document-processing-mode-label__text {
  color: #a19f9d;
}

.dVPRZW_advanced-settings__field-label {
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.dVPRZW_subtitle {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.-Ic4Qa_content {
  flex-direction: column;
  align-items: center;
  padding: 12px 22px 12px 16px;
  display: flex;
}

.-Ic4Qa_content .-Ic4Qa_input-field {
  width: 100%;
  margin-bottom: 12px;
}

.-Ic4Qa_content .-Ic4Qa_input-field .-Ic4Qa_check-box {
  margin-top: 8px;
}

.-Ic4Qa_content .-Ic4Qa_advanced-form-settings-label {
  color: #25d0b1;
}

.-Ic4Qa_content .-Ic4Qa_advanced-form-settings {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.-Ic4Qa_content .-Ic4Qa_advanced-form-settings * {
  cursor: pointer;
}

.-Ic4Qa_content .-Ic4Qa_advanced-form-settings i {
  padding-right: 6px;
  font-size: 8pt;
}

.-Ic4Qa_content .-Ic4Qa_advanced-form-settings .-Ic4Qa_expanded {
  transform: scaleY(-1);
}

.-Ic4Qa_content .-Ic4Qa_invisible-image-upload-input {
  display: none;
}

.-Ic4Qa_content .-Ic4Qa_upload-button:focus-within {
  box-shadow: 0 0 6px 6px #dcd73e33;
}

.-Ic4Qa_content .-Ic4Qa_upload-button:active {
  color: #c9c940;
  border: 1px solid #c9c940;
}

.-Ic4Qa_content .-Ic4Qa_separator {
  height: 1px;
  width: 100%;
  background-color: #c5c5c6;
  margin: 16px 0;
}

.-Ic4Qa_content .-Ic4Qa_form-id, .-Ic4Qa_content .-Ic4Qa_form-name, .-Ic4Qa_content .-Ic4Qa_extractor-type {
  width: 100%;
  margin-bottom: 12px;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section {
  width: 100%;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section > label {
  margin-bottom: 4px;
  font-size: 16px;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section .-Ic4Qa_button-container {
  margin-top: 8px;
  margin-bottom: 20px;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section .-Ic4Qa_custom-model-item {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section .-Ic4Qa_custom-model-item .-Ic4Qa_custom-model-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: hidden;
}

.-Ic4Qa_content .-Ic4Qa_custom-model-section .-Ic4Qa_custom-model-item i {
  color: initial;
}

.-Ic4Qa_content .-Ic4Qa_image-frame {
  width: 223px;
  height: 100px;
  margin-bottom: 10px;
}

.-Ic4Qa_content .-Ic4Qa_image-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.-Ic4Qa_content .-Ic4Qa_upload-button-wrapper {
  display: flex;
}

.-Ic4Qa_content .-Ic4Qa_remove-image-button {
  color: #a4262c;
  background: #a4262c0d;
  margin-left: 5px;
}

.-Ic4Qa_confirm_replace_dialog .-Ic4Qa_title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.-Ic4Qa_confirm_replace_dialog .-Ic4Qa_filename {
  color: #9b9997;
  margin-bottom: 20px;
}

.-Ic4Qa_confirm_replace_dialog .-Ic4Qa_message {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}

.phRlFG_form-inspector {
  width: 340px;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  display: flex;
  box-shadow: 0 3.2px 7.2px #0002, 0 .6px 1.8px #0000001c;
}

.phRlFG_form-inspector > div.ms-Pivot--tabs {
  margin-left: 16px;
  padding: 12px 0;
}

.phRlFG_form-inspector > div.ms-Pivot--tabs > button {
  width: 88px;
  font-size: 14px;
}

.phRlFG_form-inspector > div.ms-Pivot--tabs > button > span {
  display: block;
}

.phRlFG_form-inspector > div.ms-Pivot--tabs .is-disabled {
  color: #b4b2b0;
  background-color: #f4f4f4;
}

.phRlFG_form-inspector > div[role="tabpanel"] {
  flex: 1;
  display: flex;
  overflow-y: hidden;
}

.phRlFG_form-inspector > div[role="tabpanel"] > div {
  width: 100%;
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
}

.phRlFG_nothing-detected-desc {
  margin-top: 10px;
  padding-left: 6px;
  padding-right: 24px;
}

.t-R8ta_advanced-settings {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.t-R8ta_advanced-settings label {
  font-size: 10px;
}

.t-R8ta_advanced-settings .t-R8ta_toggle-area {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-left: 2px;
  display: flex;
}

.t-R8ta_advanced-settings .t-R8ta_toggle-area i {
  padding-right: 6px;
  font-size: 10px;
}

.t-R8ta_advanced-settings .t-R8ta_toggle-area label {
  cursor: pointer;
}

.t-R8ta_advanced-settings .t-R8ta_toggle-area .t-R8ta_expanded {
  transform: scaleY(-1);
}

.t-R8ta_advanced-settings .t-R8ta_space {
  flex: 1;
}

.t-R8ta_advanced-settings .t-R8ta_delete-button {
  cursor: pointer;
  padding: 4px;
}

.dy3KzW_watermark-toggle {
  float: left;
  display: flex;
}

.QYIUbq_code-word {
  background-color: #f7f7f7;
  padding: 2px;
  font-family: monospace;
}

.QYIUbq_code-block {
  margin: 12px 24px;
}

.QYIUbq_function-doc {
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.QYIUbq_function-doc .QYIUbq_function-title {
  margin-bottom: 8px;
}

.QYIUbq_function-doc .QYIUbq_parameters-container {
  padding-top: 10px;
}

.QYIUbq_function-doc .QYIUbq_parameters-container > p {
  margin-bottom: 4px;
  font-size: 11pt;
}

.QYIUbq_function-doc .QYIUbq_parameters-container .QYIUbq_parameters {
  margin-right: 20px;
}

.QYIUbq_function-doc .QYIUbq_parameters-container li {
  margin-left: 20px;
  font-size: 10pt;
  line-height: 16pt;
}

.QYIUbq_function-doc .QYIUbq_parameters-container li .QYIUbq_parameter-name {
  font-weight: bold;
}

.QYIUbq_function-doc .QYIUbq_parameters-container li .QYIUbq_optional {
  margin-left: 6px;
  font-style: italic;
  font-weight: normal;
}

.QYIUbq_function-doc:first-child {
  padding-top: 0;
}

.QYIUbq_function-doc:last-child {
  border-bottom: 0;
}

@media only screen and (max-width: 1200px) {
  .dExicW_container {
    width: calc(100vw - 48px);
  }
}

@media only screen and (min-width: 1200px) {
  .dExicW_container {
    width: 1152px;
  }
}

.dExicW_editor-container {
  height: 300px;
  border: 1px solid #ccc;
}

.dExicW_editor-expanded {
  height: 610px;
}

.dExicW_tab-content {
  height: 250px;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 12px 20px;
  overflow-y: scroll;
}

.dExicW_section-toggle {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  display: flex;
}

.dExicW_section-toggle * {
  cursor: pointer;
}

.dExicW_section-toggle > i {
  padding-right: 8px;
  font-size: 10px;
}

.dExicW_section-toggle > label {
  font-size: 12px;
}

.dExicW_section-toggle .dExicW_expanded {
  transform: scaleY(-1);
}

.dExicW_water-mark-setting {
  margin-top: 16px;
}

.FMig6a_sticky {
  position: sticky;
  top: 0;
}

.FMig6a_container {
  height: 100%;
  overflow-y: auto;
}

.FMig6a_container .FMig6a_inner-content {
  max-width: 1200px;
  flex-direction: row;
  margin: 24px auto auto;
  display: flex;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_sections > div:not(:last-child) {
  border-bottom: 1px solid #edebe9;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_sections {
  width: min(100%, 100vw - 4px);
  margin-bottom: 80px;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_navigate-bar {
  border-left: 1px solid #edebe9;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_navigate-bar .FMig6a_navigate-item {
  width: 216px;
  padding: 15px 12px;
  font-size: 14px;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_navigate-bar .FMig6a_selected {
  color: #fff;
  background-color: #f3f2f1;
  border-left: 2px solid #25d0b1;
  font-weight: bold;
}

.FMig6a_container .FMig6a_inner-content .FMig6a_navigate-bar a {
  color: #000;
}

@media screen and (max-width: 800px) {
  .FMig6a_container .FMig6a_inner-content .FMig6a_navigate-bar {
    display: none;
  }
}

.FMig6a_section-selector {
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 22px;
  margin-left: 20px;
  display: flex;
}

.FMig6a_section-selector .FMig6a_section {
  cursor: pointer;
  border: 1px solid #e1dfdd;
  border-radius: 10px;
  padding: 15px;
  font-size: 20px;
}

.FMig6a_section-selector .FMig6a_selected {
  color: #fff;
  background-color: #000;
}

.FMig6a_copy:hover {
  background-color: #edebe9;
}

.FMig6a_code-samples {
  padding-bottom: 24px;
}

.FMig6a_code-samples .FMig6a_pivot {
  margin-bottom: 12px;
}

.FMig6a_code-samples .FMig6a_pivot svg {
  vertical-align: middle;
}

.FMig6a_code-samples .FMig6a_pivot .ms-Pivot-text {
  vertical-align: middle;
}

.FMig6a_code-samples .FMig6a_code-section {
  position: relative;
}

.FMig6a_code-samples .FMig6a_code-section .FMig6a_copy-button {
  position: absolute;
  top: 0;
  right: 0;
}

.FMig6a_code-samples .FMig6a_code-section > pre > code {
  background-color: #faf9f8;
  padding: 16px;
  font-family: Menlo, monospace;
  font-size: 14px;
}

.FMig6a_info-block {
  background-color: #f6fdfc;
  border-left: 3px solid #1d9f87;
  border-radius: 2px;
  padding: 8px;
  font-size: 14px;
  display: inline-block;
}

.FMig6a_endpoint-url-block {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 24px;
  font-size: 14px;
  display: flex;
}

.FMig6a_endpoint-url-block .FMig6a_main {
  width: min(564px, 100vw - 124px);
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #faf9f8;
  padding: 9px 2px 9px 8px;
  overflow: hidden;
}

.FMig6a_endpoint-url-block .FMig6a_main .FMig6a_method {
  color: #1d9f87;
  margin-right: 8px;
}

.FMig6a_endpoint-url-block .FMig6a_main .FMig6a_url {
  color: #83817e;
}

.FMig6a_endpoint-url-block .FMig6a_main.FMig6a_token-text {
  width: min(410px, 100vw - 278px);
  margin-left: 4px;
}

.FMig6a_endpoint-url-block .FMig6a_main.FMig6a_token-text span {
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 14px;
  margin-top: 7px;
  display: block;
  overflow: hidden;
}

.FMig6a_endpoint-url-block .FMig6a_main > * {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

.FMig6a_endpoint-url-block .FMig6a_copy-wrapper {
  background-color: #faf9f8;
  padding: 4px 8px;
}

.FMig6a_endpoint-url-block .FMig6a_copy-wrapper .FMig6a_copy {
  padding: 5px 6px 6px 2px;
  font-size: 12px;
}

.FMig6a_endpoint-url-block .FMig6a_copy-wrapper .FMig6a_copy i {
  margin-right: 2px;
}

.FMig6a_endpoint-url-block .FMig6a_token-dropdown {
  width: 150px;
}

.FMig6a_endpoint-url-block .FMig6a_token-dropdown .ms-Dropdown-title {
  background-color: #faf9f8;
  border: none;
}

.FMig6a_endpoint-url-block .FMig6a_token-dropdown .ms-Dropdown:focus:after {
  border: none;
}

.FMig6a_endpoint-url-block .FMig6a_token-dropdown .ms-Dropdown-caretDownWrapper, .FMig6a_endpoint-url-block .FMig6a_token-dropdown .ms-Dropdown-title {
  height: 39px;
  line-height: 39px;
}

.FMig6a_endpoint-url-block .FMig6a_no-token {
  width: min(636px, 100vw - 52px);
  height: 39px;
  color: #83817e;
  background-color: #faf9f8;
  padding: 8px;
  line-height: 23px;
}

.FMig6a_endpoint-url-block .FMig6a_no-token a {
  color: #22bca0;
}

.FMig6a_endpoint-url-block:last-child {
  margin-bottom: 0;
}

.FMig6a_title {
  margin: 0 20px;
  padding-top: 10px;
  padding-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
}

.FMig6a_section {
  margin: 0 20px;
  padding: 24px 0;
}

.FMig6a_section h1 {
  color: #3b3a39;
  padding-bottom: 24px;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
}

.FMig6a_section h3 {
  align-items: center;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.FMig6a_section h3 i {
  margin-right: 8px;
}

.FMig6a_section p {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.FMig6a_section .FMig6a_copy-section {
  margin-top: 16px;
  margin-bottom: 24px;
}

.FMig6a_section .FMig6a_info-block > .FMig6a_subtitle {
  padding-top: unset;
}

.FMig6a_section .FMig6a_steps li:not(:last-child) {
  margin-bottom: 24px;
}

.FMig6a_section .FMig6a_steps li {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  line-height: 16.8px;
  list-style-position: inside;
}

.FMig6a_section .FMig6a_steps li .FMig6a_copy-button {
  height: 30px;
  margin: 0 4px;
  padding: 0 4px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.FMig6a_section .FMig6a_steps li .FMig6a_copy-button > i {
  position: relative;
  top: -2px;
}

.FMig6a_section .FMig6a_steps li .FMig6a_copy-button > i > svg {
  position: relative;
  top: 6px;
}

.FMig6a_section .FMig6a_steps li .FMig6a_copy-button > span {
  padding-right: 4px;
  position: relative;
  top: -2px;
}

.FMig6a_section .FMig6a_steps .FMig6a_download-buttons {
  height: 40px;
  margin-top: 12px;
}

.FMig6a_section .FMig6a_steps .FMig6a_download-buttons .ms-Image {
  cursor: pointer;
  margin-right: 16px;
  display: inline-block;
}

.FMig6a_section .FMig6a_description {
  white-space: pre-wrap;
  font-size: 14px;
}

.FMig6a_open-app-button {
  color: #1d9f87;
  border-color: #1d9f87;
  margin: 0 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
}

.FMig6a_workspace-list {
  background: #faf9f8;
  margin-top: 24px;
  padding: 20px;
}

.FMig6a_workspace-list-header {
  color: var(--grey-palette-grey-190, #201f1e) !important;
  padding-bottom: 8px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
}

.FMig6a_workspace-list-item {
  max-width: 486px;
  height: 44px;
  border-top: 1px solid var(--border-colors-divider, #edebe9);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.FMig6a_workspace-list-item-name {
  flex: 1;
  padding-bottom: unset !important;
  color: var(--grey-palette-grey-190, #201f1e) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.FMig6a_workspace-create-button {
  margin-top: 28px;
}

.FMig6a_workspace-create-button .ms-Button-label {
  color: #201f1e !important;
}

.FMig6a_workspace-create-button .ms-Button-icon {
  color: #201f1e !important;
}

.JwgOga_placeholder {
  pointer-events: none;
  background-color: #faf9f8;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.JwgOga_placeholder:after {
  content: "";
  border: 2px dashed #d2d0ce;
  position: absolute;
  inset: 20px;
}

.JwgOga_placeholder > div {
  pointer-events: auto;
  margin: 0 10px;
}

.JwgOga_placeholder .JwgOga_instructions {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6px;
  display: flex;
}

.JwgOga_placeholder .JwgOga_instructions button {
  margin: 8px;
}

.JwgOga_placeholder .JwgOga_settings {
  color: #83817e;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.JwgOga_placeholder .JwgOga_settings .JwgOga_settings-dropdown {
  font-size: 14px;
}

.JwgOga_placeholder .JwgOga_settings .JwgOga_settings-dropdown .ms-Dropdown-title {
  background-color: #faf9f8;
  border: none;
}

.JwgOga_placeholder .JwgOga_settings .JwgOga_settings-dropdown .ms-Dropdown:focus:after {
  border: none;
}

.JwgOga_placeholder .JwgOga_settings .JwgOga_settings-dropdown .ms-Dropdown-caretDownWrapper, .JwgOga_placeholder .JwgOga_settings .JwgOga_settings-dropdown .ms-Dropdown-title {
  height: 20px;
  line-height: 20px;
}

.JwgOga_placeholder .JwgOga_settings a {
  color: #22bca0;
  margin-left: 5px;
}

.JwgOga_image-selector {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 20px;
  display: flex;
}

.JwgOga_image-selector .JwgOga_image-selector-content {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.JwgOga_image-selector .JwgOga_image-frame {
  width: 100px;
  height: 100px;
  margin: 5px;
  display: inline-block;
}

.JwgOga_image-selector img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: auto;
  object-fit: cover;
  border-radius: 5px;
  transition: all .15s;
  transform: scale(1);
}

.JwgOga_image-selector img:hover {
  z-index: 1000;
  transition: all .15s;
  position: relative;
  transform: scale(1.5);
}

.JwgOga_image-selector .JwgOga_image-selector-desc {
  color: #323130;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

._821BLG_detection-test {
  height: 100%;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

._821BLG_report ._821BLG_section h1 {
  border-bottom: 1px solid #ccc;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;
  padding: 12px 0;
  font-size: 18px;
  display: flex;
}

._821BLG_report ._821BLG_section pre {
  background-color: #ddd;
  padding: 12px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6em;
  overflow-x: auto;
}

._821BLG_report ._821BLG_section pre pre {
  padding: 0;
}

._821BLG_report ._821BLG_section pre pre._821BLG_highlighted {
  background-color: #ffd63320;
}

._821BLG_report ._821BLG_section img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  margin: 0 auto;
}

.PmgOzq_ocr-test-report-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: calc(100% - 40px);
  margin: 20px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row {
  vertical-align: top;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row td {
  padding: 12px 12px 12px 0;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row td.PmgOzq_type-column {
  min-width: 128px;
  width: 128px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row td.PmgOzq_name-column {
  min-width: 176px;
  width: 176px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row td.PmgOzq_confidence-column {
  min-width: 90px;
  width: 90px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_header-row {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_header-row .PmgOzq_header-text {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row {
  border-bottom: 1px solid #e9e9e9;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-cell {
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-cell.PmgOzq_image-cell {
  cursor: pointer;
  padding: 10px 12px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-cell.PmgOzq_image-cell img {
  max-width: 500px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-text {
  color: #201f1e;
  font-size: 14px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-text pre {
  background-color: #ddd;
  padding: 10px;
  font-family: monospace;
  overflow-x: scroll;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-text pre.PmgOzq_prompt {
  background-color: #ccc;
  padding: 12px;
  font-size: 9pt;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row.PmgOzq_body-row .PmgOzq_body-text span.PmgOzq_title {
  margin-right: 8px;
  font-style: italic;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row .PmgOzq_value-list {
  padding-left: 25px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row .PmgOzq_auto-extraction-table {
  border-collapse: collapse;
  width: auto;
  white-space: pre-wrap;
  margin: 12px;
  font-size: 14px;
}

.PmgOzq_ocr-test-report-table .PmgOzq_table-row .PmgOzq_auto-extraction-table td {
  border: 1px solid #000;
  padding: 2px 6px;
}

.KK8D0q_report-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: calc(100% - 40px);
  margin: 20px;
}

.KK8D0q_report-table .KK8D0q_table-row {
  vertical-align: top;
}

.KK8D0q_report-table .KK8D0q_table-row td {
  padding: 12px 12px 12px 0;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_header-row {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_header-row .KK8D0q_header-text {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_body-row {
  border-bottom: 1px solid #e9e9e9;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_body-row .KK8D0q_body-cell {
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_body-row .KK8D0q_body-text {
  color: #201f1e;
  font-size: 14px;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_body-row .KK8D0q_body-text pre {
  background-color: #ddd;
  padding: 10px;
  font-family: monospace;
  overflow-x: scroll;
}

.KK8D0q_report-table .KK8D0q_table-row.KK8D0q_body-row .KK8D0q_body-text span.KK8D0q_title {
  margin-right: 8px;
  font-style: italic;
}

.G3VWUa_warped-image-wrapper {
  height: 500px;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.G3VWUa_name-column {
  min-width: 120px;
  width: 120px;
}

.G3VWUa_confidence-column {
  min-width: 150px;
  width: 150px;
}

.G3VWUa_bbox-column {
  min-width: 190px;
  width: 190px;
}

.PPhp-a_preview-image {
  height: 500px;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.PPhp-a_preview-image .PPhp-a_toggle-button-holder {
  position: absolute;
  bottom: 0;
  right: 0;
}

.PPhp-a_type-column, .PPhp-a_value-column {
  min-width: 120px;
  width: 120px;
}

.PPhp-a_confidence-column {
  min-width: 150px;
  width: 150px;
}

.PPhp-a_bboxes-column {
  min-width: 190px;
  width: 190px;
}

.VTuLyG_container {
  max-width: 800px;
  padding: 8px 20px;
}

.VTuLyG_section {
  margin-bottom: 10px;
}

.VTuLyG_section h3 {
  padding-bottom: 18px;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 600px) {
  .VTuLyG_section:first-child h3 {
    display: none;
  }
}

.VTuLyG_section:not(:last-child) {
  padding-bottom: 20px;
}

.VTuLyG_header {
  font-size: 18px;
  font-weight: 600;
}

.VTuLyG_large-header {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.VTuLyG_large-header .VTuLyG_icon {
  margin-bottom: 0;
}

.VTuLyG_inline-header {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
}

.VTuLyG_icon {
  height: 24px;
  width: 24px;
  background-color: #faae68;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.VTuLyG_icon i {
  color: #fff;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.VTuLyG_icon i svg {
  width: 16px;
  height: 16px;
}

.VTuLyG_grid-item {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  display: flex;
  box-shadow: 0 .6px 1.8px #0000001a, 0 3.2px 7.2px #00000021;
}

.VTuLyG_grid-item .VTuLyG_description, .VTuLyG_grid-item .VTuLyG_alt-description {
  font-size: 14px;
  line-height: 26.64px;
}

.VTuLyG_grid-item .VTuLyG_alt-description {
  color: #9b9997;
  margin-top: 8px;
}

.VTuLyG_grid-item:hover {
  background-color: #faf9f8;
}

.VTuLyG_pre-built-grid {
  grid-gap: 16px;
  display: grid;
}

.VTuLyG_pre-built-grid .VTuLyG_grid-item .VTuLyG_icon {
  background-color: #6888fa;
}

.VTuLyG_pre-built-grid .VTuLyG_grid-item .VTuLyG_large-header {
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .VTuLyG_pre-built-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.VTuLyG_large-grid {
  grid-gap: 16px;
  grid-template-columns: 1fr;
  display: grid;
}

.VTuLyG_large-grid .VTuLyG_grid-item {
  padding: 24px;
}

.VTuLyG_fixed-layout-block .VTuLyG_icon {
  background-color: #ff6d7d;
}

.VTuLyG_custom-model-block .VTuLyG_icon {
  background-color: #25d0b1;
}

.zDyjpG_header {
  font-weight: 400;
}

.zDyjpG_extractor-type {
  margin-bottom: 20px;
}

.zDyjpG_form-group-mode-choice-group {
  margin-top: 15px;
}

.zDyjpG_form-group-mode-choice-group .ms-ChoiceFieldLabel {
  white-space: pre-wrap;
}

.zDyjpG_form-group-mode-choice-group .ms-ChoiceField + .ms-ChoiceField {
  margin-top: 25px;
}

.zDyjpG_form-group-mode-title {
  margin-top: 20px;
  margin-bottom: 2px;
}

.zDyjpG_form-group-mode-desc {
  margin-bottom: 28px;
}

.zDyjpG_form-group-type-option {
  margin-left: 28px;
}

.zDyjpG_form-group-type-option-title {
  margin-bottom: 6px;
}

.zDyjpG_custom-model-extra {
  margin-top: 20px;
}

.zDyjpG_custom-model-extra .ms-Toggle label {
  font-weight: 600;
}

.zDyjpG_custom-model-extra .zDyjpG_description {
  color: #605e5c;
  font-size: 12px;
}

.zDyjpG_footer {
  margin-top: 30px;
}

._2iJlHa_content {
  max-width: 405px;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  display: flex;
}

._2iJlHa_header {
  color: #201f1e;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

._2iJlHa_footer {
  justify-content: flex-end;
  gap: 10px;
  display: flex;
}

.YIPQBW_dropdown {
  align-items: center;
  gap: 10px;
  display: flex;
}

.YIPQBW_dropdown label {
  margin-bottom: 4px;
}

.YIPQBW_ms-dropdown {
  width: 210px;
}

.YIPQBW_ms-dropdown .ms-Dropdown-title {
  color: #201f1e;
  border-top: none;
  border-left: none;
  border-right: none;
}

.YIPQBW_ms-dropdown i {
  color: #201f1e;
}

.e1bCLa_container {
  padding: 0 20px;
}

.e1bCLa_ms-dropdown {
  width: 210px;
}

.e1bCLa_ms-dropdown .ms-Dropdown-title {
  color: #201f1e;
  border-top: none;
  border-left: none;
  border-right: none;
}

.e1bCLa_ms-dropdown i {
  color: #201f1e;
}

.e1bCLa_empty {
  color: #888;
}

.e1bCLa_control {
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}

.e1bCLa_control .e1bCLa_button {
  color: #323130;
  background-color: #ffd633;
  border: 0;
  padding: 0 12px;
}

.e1bCLa_control .e1bCLa_button i {
  font-size: 10px;
  font-weight: 600;
}

.e1bCLa_control .e1bCLa_filters {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.e1bCLa_control .e1bCLa_filters .e1bCLa_dropdown {
  align-items: center;
  gap: 10px;
  display: flex;
}

.e1bCLa_control .e1bCLa_filters .e1bCLa_dropdown label {
  margin-bottom: 4px;
}

.e1bCLa_control .e1bCLa_filters .e1bCLa_search-input-field {
  width: 200px;
}

.e1bCLa_control .e1bCLa_filters .e1bCLa_search-input-field input {
  padding-left: 36px;
  padding-right: 8px;
}

.e1bCLa_control .e1bCLa_filters .e1bCLa_search-icon {
  color: #25d0b1;
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  top: auto;
  bottom: 6px;
  left: 12px;
}

.e1bCLa_grid {
  flex-flow: wrap;
  align-content: flex-start;
  gap: 16px;
  display: flex;
}

.e1bCLa_grid-item {
  cursor: pointer;
  height: 211px;
  width: 223.4px;
  background-color: #f3f2f1;
  position: relative;
  box-shadow: 0 .3px .9px #0000001a, 0 1.6px 3.6px #00000021;
}

.e1bCLa_grid-item .e1bCLa_item-info {
  height: 111px;
  width: 223.4px;
  background-color: #fff;
  padding: 8px 12px 0;
  position: absolute;
  bottom: 0;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header {
  align-items: center;
  margin-bottom: 12px;
  display: flex;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_inline-header {
  color: #8a8886;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 140%;
  overflow: hidden;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_icon i {
  color: #fff;
  width: 16px;
  height: 16px;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_icon i svg {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_unpinned {
  color: #c8c6c4;
}

.e1bCLa_grid-item .e1bCLa_item-info .e1bCLa_large-header .e1bCLa_pinned {
  color: #25d0b1;
}

.e1bCLa_grid-item .e1bCLa_item-image {
  height: 100%;
}

.e1bCLa_grid-item .e1bCLa_item-image .e1bCLa_item-image-frame {
  width: 100%;
  height: 100px;
}

.e1bCLa_grid-item .e1bCLa_item-image .e1bCLa_item-image-frame > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.e1bCLa_grid-item .e1bCLa_description {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #201f1e;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  overflow: hidden;
}

.e1bCLa_grid-item .e1bCLa_alt-description {
  color: #a19f9d;
  border-top: 1px solid #edebe9;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.e1bCLa_grid-item .e1bCLa_alt-description > * {
  align-self: center;
}

.e1bCLa_grid-item .e1bCLa_alt-description > i, .e1bCLa_grid-item .e1bCLa_alt-description > i > svg {
  height: 15px;
}

@media screen and (max-width: 550px) {
  .e1bCLa_control {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 10px;
  }

  .e1bCLa_control .e1bCLa_dropdown {
    align-items: unset;
    width: 100%;
    justify-content: space-between;
  }

  .e1bCLa_control .e1bCLa_button {
    width: 100%;
  }

  .e1bCLa_grid {
    display: block;
  }

  .e1bCLa_grid .e1bCLa_grid-item {
    width: 100%;
    margin-bottom: 16px;
  }

  .e1bCLa_grid .e1bCLa_grid-item .e1bCLa_item-info {
    width: 100%;
  }
}

.e1bCLa_grid-item:hover .e1bCLa_item-info {
  background-color: #faf9f8;
}

.e1bCLa_grid-item.e1bCLa_create {
  background-color: #faf9f8;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.e1bCLa_grid-item.e1bCLa_create .e1bCLa_icon {
  margin-right: 12px;
}

.e1bCLa_grid-item.e1bCLa_create:hover {
  background-color: #f3f2f1;
}

.e1bCLa_grid-item.e1bCLa_skeleton {
  cursor: default;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.e1bCLa_grid-item.e1bCLa_skeleton .e1bCLa_large-header .e1bCLa_icon {
  background-color: #f3f2f1;
}

.e1bCLa_grid-item.e1bCLa_skeleton .e1bCLa_large-header .e1bCLa_inline-header {
  height: 19px;
  width: 100px;
  background-color: #f3f2f1;
}

.e1bCLa_grid-item.e1bCLa_skeleton .e1bCLa_description {
  height: 24px;
  background-color: #f3f2f1;
  margin-bottom: 12px;
}

.e1bCLa_grid-item.e1bCLa_skeleton .e1bCLa_alt-description {
  height: 18px;
  width: 150px;
  background-color: #f3f2f1;
}

.e1bCLa_grid-item.e1bCLa_skeleton:after {
  content: "";
  background-image: linear-gradient(90deg, #fff0 0, #fff3 20%, #ffffff80 60%, #fff0);
  animation: 1.5s infinite e1bCLa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes e1bCLa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.e1bCLa_grid-item.e1bCLa_skeleton:hover {
  background-color: inherit;
}

.e1bCLa_paginator {
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 22px;
  display: flex;
}

.e1bCLa_icon.e1bCLa_combined {
  background-color: #faae68;
}

.e1bCLa_icon.e1bCLa_fixed-layout {
  background-color: #ff6d7d;
}

.e1bCLa_icon.e1bCLa_custom-model {
  background-color: #25d0b1;
}

.e1bCLa_icon.e1bCLa_prebuilt {
  background-color: #6888fa;
}

.e1bCLa_extractor-card-big-icon {
  text-align: center;
  background-color: #fff;
  margin: 20px;
}

.e1bCLa_extractor-card-big-icon > i {
  margin-top: 22px;
}

.IWrrYW_field-item-edit-panel-complex-option {
  padding: 10px 0;
}

.IWrrYW_field-item-edit-panel-complex-option-sub-text {
  color: #605e5c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.IWrrYW_field-item-edit-panel-container {
  height: 100%;
  background-color: #fff;
  padding: 20px;
  overflow-y: auto;
}

.IWrrYW_field-item-edit-panel {
  border: 1px solid #edebe9;
  padding: 0 16px;
}

.IWrrYW_field-item-edit-panel .IWrrYW_field-item-edit-panel-header {
  background: #faf9f8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -16px;
  padding: 12px 16px;
  font-weight: 600;
  display: flex;
}

.IWrrYW_field-item-edit-panel .IWrrYW_field-item-edit-panel-name-field {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
}

.IWrrYW_field-item-edit-panel .IWrrYW_field-item-edit-panel-type-field {
  width: 100%;
  margin-bottom: 22px;
}

.IWrrYW_field-item-edit-panel .IWrrYW_field-item-edit-panel-is-list-field, .IWrrYW_field-item-edit-panel .IWrrYW_field-item-edit-panel-input-as-checkbox-field {
  margin-bottom: 20px;
}

.IWrrYW_field-item-edit-panel-what-is-table {
  background: #faf9f8;
  margin: 0 -16px;
  padding: 16px;
}

.IWrrYW_field-item-edit-panel-what-is-table .IWrrYW_field-item-edit-panel-what-is-table-title {
  color: #323130;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.IWrrYW_field-item-edit-panel-what-is-table .IWrrYW_field-item-edit-panel-what-is-table-desc {
  color: #323130;
  padding-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.IWrrYW_field-item-edit-panel-subfield-grid {
  grid-template-columns: 0fr 1fr 1fr 1fr fit-content(80px);
  column-gap: 8px;
  padding: 16px 0 8px;
  display: grid;
}

.IWrrYW_field-item-edit-panel-subfield-grid-header {
  color: #201f1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.ZLbzsG_main-field-item {
  width: 100%;
  height: 46px;
  background: #25d0b11a;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  margin-bottom: 10px;
  padding: 7px 7px 7px 12px;
  display: flex;
}

.ZLbzsG_main-field-item .ZLbzsG_main-field-item-name {
  color: #201f1e;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.ZLbzsG_main-field-item .ZLbzsG_main-field-item-type {
  color: #201f1e;
  align-items: flex-end;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.ZLbzsG_field-item {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px 12px;
  display: flex;
}

.ZLbzsG_field-item .ZLbzsG_field-item-name {
  color: #201f1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.ZLbzsG_field-item .ZLbzsG_field-item-type {
  color: #201f1e;
  opacity: .4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.PnHcIa_page-header {
  min-height: 76px;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  padding-block: 16px;
  display: flex;
}

.PnHcIa_page-header-divider {
  min-height: 77px;
  border-bottom: 1px solid #edebe9;
}

.PnHcIa_page-header-left {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.PnHcIa_page-header-title {
  color: #323130;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.PnHcIa_page-header-subtitle {
  color: #a19f9d;
  padding-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.PnHcIa_page-header-right {
  align-self: flex-end;
}

.CC4mMa_arrow-bullet {
  height: 32px;
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
}

.CC4mMa_arrow-bullet-is-last:before {
  content: " ";
  height: 50%;
  width: 1px;
  border-left: 1px solid #25d0b1;
  display: block;
  position: absolute;
  top: 0;
}

.CC4mMa_arrow-bullet-not-last {
  border-left: 1px solid #25d0b1;
}

.CC4mMa_field-item {
  width: 100%;
  cursor: pointer;
  height: 52px;
  background-color: #fff;
  border: 1px solid #edebe9;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  box-shadow: 0 0 15px #0000000d;
}

.CC4mMa_field-item:hover {
  background-color: #f3f2f1;
}

.CC4mMa_field-item .CC4mMa_field-item-name {
  color: #201f1e;
  margin-left: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.CC4mMa_field-item .CC4mMa_field-item-type {
  color: #201f1e;
  opacity: .4;
  margin-left: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.CC4mMa_field-item .CC4mMa_field-item-left {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
}

.CC4mMa_field-item .CC4mMa_field-item-right {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.CC4mMa_field-item .CC4mMa_field-item-right .CC4mMa_trash-icon {
  margin-left: 12px;
  margin-right: 15px;
}

.CC4mMa_field-item-selected {
  border: 1px solid #25d0b1;
}

.CC4mMa_field-item-error {
  border: 1px solid #a4262c;
}

.CC4mMa_field-item-error .CC4mMa_field-item-name {
  color: #a4262c;
}

.CC4mMa_field-item-no-sub-field {
  color: #a19f9d;
  height: 32px;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding-left: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: flex;
}

.CC4mMa_field-item-sub-field {
  height: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  display: flex;
}

.CC4mMa_field-item-sub-field .CC4mMa_field-item-sub-field-title {
  color: #201f1e;
  text-overflow: ellipsis;
  flex-direction: row;
  flex: 1 1 0;
  align-items: center;
  gap: 14px;
  padding-left: 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  overflow: hidden;
}

.CC4mMa_field-item-sub-field .CC4mMa_field-item-sub-field-type {
  color: #a19f9d;
  flex-grow: 0;
  padding-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.CC4mMa_document-type-panel {
  background: #faf9f8;
  padding: 12px 20px 16px;
}

.CC4mMa_document-type-panel-content {
  align-items: left;
  flex-direction: column;
  display: flex;
}

.CC4mMa_document-type-panel-content .CC4mMa_document-type-panel-content-title {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.CC4mMa_document-type-panel-content .CC4mMa_document-type-panel-content-text-field {
  max-width: 300px;
  margin-bottom: 6px;
}

.CC4mMa_document-type-panel-content .CC4mMa_document-type-panel-content-help-text {
  color: #605e5c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.CC4mMa_fields-panel {
  background: #faf9f8;
  flex-direction: column;
  flex: 1;
  padding: 12px 20px 16px;
  display: flex;
}

.CC4mMa_fields-panel .CC4mMa_fields-panel-content {
  align-items: left;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.CC4mMa_fields-panel .CC4mMa_fields-panel-content-scrollable {
  flex: 1 1 0;
  padding: 0;
  overflow-y: auto;
}

.CC4mMa_fields-panel .CC4mMa_fields-panel-content-help-text {
  color: #605e5c;
  align-items: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  display: flex;
}

.CC4mMa_fields-panel .CC4mMa_fields-panel-content-field-list {
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
  display: flex;
}

.CC4mMa_fields-panel .CC4mMa_fields-panel-content-select-template-footer {
  height: 74px;
  width: 100%;
  flex-direction: row;
  order: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 0;
  display: flex;
}

.CC4mMa_field-schema-editor {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.CC4mMa_not-saved-prompt-dialog-button-actions {
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: flex;
}

.CC4mMa_field-schema-editor-panel-header {
  padding-inline: 20px;
}

.CC4mMa_templates-panel {
  background: #faf9f8;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  display: flex;
}

.CC4mMa_templates-panel .CC4mMa_template-panel-heading {
  color: #201f1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.CC4mMa_templates-panel .CC4mMa_templates-choice-group {
  background: #fff;
  border: 1px solid #edebe9;
  margin-top: 8px;
}

.CC4mMa_templates-panel .CC4mMa_templates-choice-group .ms-ChoiceField {
  margin-top: 0;
  padding: 8px 12px;
}

.CC4mMa_templates-panel .CC4mMa_templates-choice-group .ms-ChoiceField:nth-child(2n) {
  background: #faf9f8;
}

.CC4mMa_templates-panel .CC4mMa_help-text {
  color: #201f1e;
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.CC4mMa_templates-panel .CC4mMa_full-width-button {
  width: 100%;
  margin-top: 8px;
}

.CC4mMa_templates-panel .CC4mMa_instruction {
  color: #605e5c;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.CC4mMa_field-schema-editor-panel-content {
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 0;
  display: flex;
}

.CC4mMa_field-schema-editor-page-header-buttons {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  display: flex;
}

.CC4mMa_split-view {
  width: 100%;
  height: 100%;
  min-height: 0;
  flex-direction: row;
  gap: 0;
  display: flex;
}

.CC4mMa_split-view .CC4mMa_split-view-left {
  max-width: 420px;
  min-width: 330px;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.CC4mMa_split-view .CC4mMa_split-view-right {
  min-width: 400px;
  flex-grow: 1;
}

.CC4mMa_no-field-placeholder {
  color: #605e5c;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.CC4mMa_template-preview {
  height: 100%;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.CC4mMa_template-preview .CC4mMa_template-preview-scrollable {
  flex: 1 1 0;
  overflow-y: auto;
}

.CC4mMa_template-preview .CC4mMa_template-preview-title {
  color: #201f1e;
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.CC4mMa_editor-panel {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.CC4mMa_editor-panel .CC4mMa_editor-panel-content-divider {
  height: 4px;
  border-top: 1px solid var(--border-colors-divider, #edebe9);
  border-bottom: 1px solid var(--border-colors-divider, #edebe9);
  background: #f3f3f3;
}

.Fl5-pG_mission-bar {
  height: 64px;
  min-width: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
  display: flex;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-left {
  min-width: 0;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-icon {
  width: 20px;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-text {
  min-width: 0;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  display: flex;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-title {
  color: #323130;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-subtitle {
  color: #605e5c;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
}

.Fl5-pG_mission-bar .Fl5-pG_mission-bar-right {
  flex: 1 0 content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  display: flex;
}

.Fl5-pG_mission-bar-type-info {
  background: #fff4ce;
}

.Fl5-pG_mission-bar-type-warning {
  background: #fed9cc;
}

.Fl5-pG_mission-bar-type-success {
  background: #dff6dd;
}

._3dV3Aa_split-view {
  height: 100%;
  flex-direction: row;
  display: flex;
}

._3dV3Aa_split-view ._3dV3Aa_split-view-left {
  height: 100%;
  flex-direction: column;
  flex: 1.5 auto;
  display: flex;
}

._3dV3Aa_split-view ._3dV3Aa_split-view-right {
  flex: auto;
}

._3dV3Aa_split-view ._3dV3Aa_split-view-divider {
  width: 2px;
  min-width: 2px;
  background-color: #f3f2f1;
}

._0zO7ZG_container {
  height: 100%;
  margin: 0;
  padding: 20px;
  position: relative;
}

._0zO7ZG_container ._0zO7ZG_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

._0zO7ZG_container ._0zO7ZG_subtitle {
  color: #605e5c;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  display: inline-block;
}

._0zO7ZG_container ._0zO7ZG_button-bar {
  border-bottom: 1px solid #edebe9;
  padding-top: 4px;
  padding-left: 8px;
}

._0zO7ZG_container ._0zO7ZG_button-bar ._0zO7ZG_delete-button:not(:disabled) {
  color: #d83b01;
}

._0zO7ZG_container ._0zO7ZG_button-bar ._0zO7ZG_delete-button:not(:disabled) .ms-Icon {
  color: #d83b01;
}

._0zO7ZG_container ._0zO7ZG_button-bar ._0zO7ZG_delete-button:hover {
  opacity: .7;
}

._0zO7ZG_container ._0zO7ZG_button-bar ._0zO7ZG_delete-button:active, ._0zO7ZG_container ._0zO7ZG_button-bar ._0zO7ZG_delete-button:disabled {
  opacity: unset;
}

._0zO7ZG_container ._0zO7ZG_loading-container {
  height: calc(100vh - 300px);
}

._0zO7ZG_container ._0zO7ZG_info-container {
  height: 32px;
  color: #605e5c;
  margin-bottom: 12px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 16px;
}

._0zO7ZG_container ._0zO7ZG_info-container ._0zO7ZG_uploading-files i {
  float: left;
  margin-right: 8px;
  font-size: 16px;
}

._0zO7ZG_container ._0zO7ZG_info-container ._0zO7ZG_file-count {
  float: right;
}

._0zO7ZG_reminder {
  text-align: center;
  margin-top: calc(100vh - 758px);
  position: sticky;
  bottom: 24px;
  left: 0;
  right: 0;
}

._0zO7ZG_reminder > div {
  color: #000;
  background-color: #ffd633;
  padding: 12px 24px;
  display: inline-block;
}

.MCji9q_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
  overflow: hidden;
}

.MCji9q_container .MCji9q_content {
  width: 80%;
  height: 40vh;
  max-width: 913px;
  min-height: 450px;
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.MCji9q_container .MCji9q_content .MCji9q_header .MCji9q_title {
  font-size: 22px;
}

.MCji9q_container .MCji9q_content .MCji9q_dotted-box {
  box-sizing: border-box;
  border: 1px dashed #25d0b1;
  border-radius: 2px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction {
  gap: 14px;
  display: flex;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction > div {
  border: 1px solid #000;
  flex: 1;
  padding: 10px;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction > div .MCji9q_title {
  font-size: 14px;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction > div ul {
  list-style: none;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction > div ul li {
  color: #605e5c;
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  position: relative;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction > div ul li > i {
  font-size: 10px;
  position: absolute;
  left: -20px;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_dos {
  background-color: #25d0b11a;
  border-color: #25d0b1;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_dos .MCji9q_title {
  color: #1d9f87;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_dos i {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  color: #25d0b1;
  font-weight: 600;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_donts {
  background-color: #a4262c1a;
  border-color: #a4262c;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_donts .MCji9q_title {
  color: #a4262c;
}

.MCji9q_container .MCji9q_content .MCji9q_instruction .MCji9q_donts i {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  color: #a4262c;
}

@media only screen and (max-width: 750px) {
  .MCji9q_container .MCji9q_content .MCji9q_instruction {
    flex-direction: column;
  }
}

.MCji9q_container .MCji9q_empty-state {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.MCji9q_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.MCji9q_container li, .MCji9q_subtitle {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.oDHNHG_container {
  width: 100%;
  background-color: #f3f2f1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 12px 16px;
  display: flex;
}

.oDHNHG_container button:last-child {
  height: 32px;
  margin-bottom: 3px;
}

.oDHNHG_input-block {
  width: 100%;
  margin-bottom: 12px;
}

.oDHNHG_input-block label.ms-Dropdown-label {
  font-size: 16px;
}

.oDHNHG_format-label {
  align-items: center;
  display: flex;
}

.oDHNHG_format-label .oDHNHG_info-icon {
  cursor: default;
  padding-top: 5px;
  padding-left: 5px;
}

.nvDmkq_frozen-field {
  background-color: #f3f2f1;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 6px 8px 8px;
  display: flex;
}

.nvDmkq_frozen-field span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 20px;
  overflow: hidden;
}

.nvDmkq_frozen-field .nvDmkq_field-subtitle {
  color: #a19f9d;
}

.nvDmkq_frozen-field .nvDmkq_field-subtitle .nvDmkq_format:before {
  content: " - ";
}

.gnQwNG_container {
  width: 340px;
  height: 100%;
  box-shadow: 0 3.2px 7.2px #0002, 0 .6px 1.8px #0000001c;
}

.gnQwNG_content {
  width: 100%;
  height: calc(100% - 64px);
  padding: 20px;
  overflow-y: auto;
}

.gnQwNG_content > div.ms-Pivot--tabs {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.gnQwNG_content > div.ms-Pivot--tabs > button {
  font-size: 14px;
}

.gnQwNG_content .gnQwNG_title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 22px;
  font-size: 14px;
  line-height: 20px;
}

.gnQwNG_content .gnQwNG_desc {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
}

.gnQwNG_content .gnQwNG_subtitle {
  margin-top: 9px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.gnQwNG_content .gnQwNG_info {
  color: #a19f9d;
  margin-top: 4px;
  font-size: 14px;
}

.gnQwNG_content .gnQwNG_preset {
  margin-top: 16px;
  margin-bottom: 15px;
}

.gnQwNG_content .gnQwNG_add-new-field-button {
  width: 100%;
  color: #25d0b1;
  border: 1.5px solid #25d0b1;
  margin-top: 15px;
  font-weight: 600;
}

.gnQwNG_content .gnQwNG_add-new-field-button span.ms-Button-textContainer {
  flex-grow: 0;
}

.gnQwNG_content .gnQwNG_field-list {
  height: calc(100vh - 414px);
  margin-top: 16px;
  overflow-y: auto;
}

.gnQwNG_content .gnQwNG_field-list.gnQwNG_with-preset {
  height: calc(100vh - 480px);
  margin-top: 0;
}

.gnQwNG_bottom {
  width: calc(100% - 40px);
  height: 64px;
  max-height: 64px;
  border-top: 1px solid #ccc;
  margin: 0 20px;
}

.gnQwNG_bottom .gnQwNG_next-step-button {
  float: right;
  margin-top: 10px;
}

.gnQwNG_bottom .gnQwNG_next-step-button:not(:disabled) {
  color: #000;
  background-color: #ffd633;
  border-color: #ffd633;
  font-weight: 600;
}

.gnQwNG_bottom .gnQwNG_next-step-button:hover:not(:disabled) {
  background-color: #997a00;
  border-color: #997a00;
}

.gnQwNG_bottom .gnQwNG_next-step-button:active:not(:disabled) {
  background-color: #cca300;
  border-color: #cca300;
}

.gnQwNG_teaching-bubble-desc {
  color: #fff;
  line-height: 20px;
}

.gnQwNG_teaching-bubble-desc .gnQwNG_teaching-bubble-desc-bold {
  font-weight: 800;
}

.dZr_Eq_loading-content {
  padding-left: 25px;
  position: relative;
}

.dZr_Eq_loading-content .dZr_Eq_spinner-container {
  flex-direction: row;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.dZr_Eq_loading-content .dZr_Eq_loading-label {
  color: #5ecebd;
}

.qEdg7G_fsl-active-model-picker {
  width: 284px;
  padding: 16px;
}

.qEdg7G_fsl-active-model-picker-title {
  color: #323130;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.qEdg7G_fsl-active-model-picker-desc {
  color: #605e5c;
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.qEdg7G_fsl-active-model-picker-choice-group, .qEdg7G_fsl-active-model-picker-model-version {
  margin-top: 12px;
}

.qEdg7G_fsl-active-model-picker-model-button {
  margin-top: 20px;
}

.qEdg7G_fsl-active-model-picker-model-button .ms-Button {
  width: 100%;
}

._6_cvPW_mission-bar {
  margin: 0 -20px;
}

.DUp42a_data-item-table {
  box-sizing: border-box;
  width: 100%;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
}

.DUp42a_data-item-table-label {
  color: #323130;
  opacity: .5;
  min-width: 190px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.DUp42a_data-item-table-topbar {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.DUp42a_data-item-table-scrollable {
  width: 100%;
  isolation: isolate;
  overflow-x: auto;
}

.DUp42a_data-item-table-content {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #edebe9;
  flex: 1 1 0;
}

.DUp42a_data-item-table-header-cell {
  color: #3231307f;
  z-index: 1;
  height: 36px;
  background-color: #f3f2f1;
  padding: 10px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: sticky;
  top: 0;
}

.DUp42a_data-item-table-header-cell:not(:last-child) {
  border-right: 1px solid #edebe9;
}

.DUp42a_data-item-table-cell {
  color: #323130;
  min-width: 150px;
  min-height: 36px;
  word-break: break-all;
  white-space: pre-wrap;
  background: #fff;
  border-top: 1px solid #edebe9;
  padding: 10px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.DUp42a_data-item-table-cell:not(:last-child) {
  border-right: 1px solid #edebe9;
}

.DUp42a_data-item-table-edit-cell {
  color: #323130;
  min-width: 150px;
  min-height: 36px;
  padding: 10px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.DUp42a_data-item-table-trash-cell {
  color: #323130;
  max-width: 44px;
  min-height: 36px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.Mwy-_G_container {
  height: 75%;
  max-height: 75%;
  width: 0;
  z-index: 100;
  background: #faf9f8;
  padding: 0;
  position: fixed;
  bottom: 0;
  box-shadow: 0 0 10px #00000026;
}

.Mwy-_G_container-open {
  width: 100%;
  opacity: 1;
  transition: bottom .3s, opacity;
  bottom: 0;
}

.Mwy-_G_container-closed {
  height: 75%;
  opacity: 0;
  transition: bottom .3s, opacity 0s .3s;
  position: fixed;
}

.Mwy-_G_z-layout {
  height: 100%;
  width: 100%;
  position: relative;
}

.Mwy-_G_table-layout {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  position: absolute;
  inset: 0;
}

.Mwy-_G_table {
  width: 100%;
  height: 100%;
}

.Mwy-_G_drag-handle {
  height: 6px;
  cursor: ns-resize;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.Mwy-_G_drag-handle:hover {
  border-top: 1px solid #25d0b1;
}

.ktSQ3a_message-bar {
  margin: 10px 0;
}

.ktSQ3a_message-bar .ktSQ3a_button {
  font-size: 12px;
}

.FZdpZW_frame {
  width: 60px;
  height: 60px;
  margin: 2px;
  display: inline-block;
}

.FZdpZW_frame > div {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.FZdpZW_errored {
  background-color: #f8f8f8;
  border-radius: 2px;
}

.rsJUjW_data-item {
  box-sizing: border-box;
  width: 100%;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
}

.rsJUjW_data-item-label {
  color: #323130;
  opacity: .5;
  min-width: 190px;
  padding-top: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.rsJUjW_data-item-value {
  word-break: break-word;
  color: #201f1e;
  min-height: 36px;
  white-space: pre-wrap;
  flex-grow: 1;
  padding: 8px 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.rsJUjW_data-item-value-container {
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.rsJUjW_data-item-value-image-row {
  flex-direction: row;
  gap: 10px;
  display: flex;
}

.rsJUjW_data-item-value-image-list {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.rsJUjW_data-item-value-image {
  background: #fff;
  border: 1px solid #edebe9;
}

.rsJUjW_data-item-value-image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  max-width: 100px;
  max-height: 100px;
}

.rsJUjW_data-item-list-value {
  color: #201f1e;
  background-color: #fff;
  flex-grow: 1;
  grid-template-columns: 20px 1fr;
  column-gap: 4px;
  margin-top: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: grid;
}

.rsJUjW_data-item-list-value-row-bullet:before {
  content: " • ";
}

.rsJUjW_data-item-list-value-row-bullet {
  border-right: 2px solid #faf9f8;
  padding: 8px 6px;
}

.rsJUjW_data-item-list-value-row-content {
  background-color: #fff;
  padding: 8px 6px;
}

.rsJUjW_data-item-list-value-container {
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.rsJUjW_data-item-list-value-actions {
  padding-top: 6px;
}

.rsJUjW_raw-json-viewer {
  white-space: pre-wrap;
}

.rsJUjW_data-item-editing-list-value {
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  display: flex;
}

.rsJUjW_data-item-editing-list-value-item {
  flex-direction: row;
  gap: 4px;
  display: flex;
}

.rsJUjW_data-item .ms-TextField {
  flex: 1;
}

._1uQ2Aq_placeholder {
  color: #323130;
  text-align: center;
  height: 52px;
  background: #faf9f8;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

._1uQ2Aq_container {
  container-type: inline-size;
}

@container (max-width: 400px) {
  ._1uQ2Aq_container .ms-Button-label {
    display: none;
  }
}

._1uQ2Aq_action-button-bar {
  flex-direction: row;
  gap: 4px;
  display: flex;
}

._1uQ2Aq_callout-image-frame {
  width: 100%;
}

._1uQ2Aq_callout-image-frame img {
  width: 100%;
  object-fit: contain;
}

.qC87XW_container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
}

.qC87XW_handle-panel-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.qC87XW_handle-panel-content {
  width: 100%;
  height: 100%;
}

.qC87XW_handle-panel-handle {
  width: 6px;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.qC87XW_handle-panel-handle:hover {
  border-left: 3px solid #25d0b1;
}

._8kFAKG_container {
  font: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #000;
  background: #faf9f8;
  flex-direction: column;
  padding: 20px 0;
  display: flex;
}

._8kFAKG_line {
  padding: 0 20px;
  line-height: 1.8em;
  display: inline-block;
}

._8kFAKG_highlighted-line {
  background-color: #f4f2e8;
  line-height: 1.8em;
  display: inline-block;
}

._8kFAKG_first-highlighted-line {
  padding-top: 10px;
}

._8kFAKG_last-highlighted-line {
  padding-bottom: 10px;
}

._7m-lqq_container {
  width: 166px;
  height: 34px;
  background: #fff;
  border: 1px solid #edebe9;
  flex-direction: row;
  align-items: center;
  display: flex;
  box-shadow: 0 3.2px 7.2px #00000021, 0 .6px 1.8px #0000001a;
}

._7m-lqq_text {
  color: #a19f9d;
  text-align: center;
  height: 100%;
  border-left: 1px solid #edebe9;
  border-right: 1px solid #edebe9;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.HtjqIa_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0;
  display: flex;
  overflow: auto;
}

.HtjqIa_placeholder {
  flex-flow: column;
  flex: 1;
  margin: 20px;
  display: flex;
}

.HtjqIa_options-panel {
  margin-top: 20px;
}

.HtjqIa_extraction-mode {
  background: #effcfa;
  padding: 8px;
  display: inline-block;
}

.HtjqIa_extraction-mode h3 {
  margin-right: 16px;
  padding-bottom: 0;
  font-size: 16px;
  display: inline;
}

.HtjqIa_extraction-mode button {
  color: #53b2a1;
  font-size: 12px;
}

.HtjqIa_data-container {
  flex: 1;
  display: flex;
}

.HtjqIa_json-content {
  height: 100%;
  flex-direction: column;
  flex: 2;
  padding: 15px 20px 20px;
  display: flex;
}

.HtjqIa_image-content {
  min-width: 100px;
  height: 100%;
  background-color: #faf9f8;
  flex: 1;
  display: flex;
  position: relative;
}

.HtjqIa_image-not-found {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.HtjqIa_llm-prompt-container {
  flex: 1 1 0;
  overflow-y: auto;
}

.HtjqIa_llm-prompt-container pre {
  white-space: pre-wrap;
  background: #faf9f8;
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
}

.HtjqIa_llm-prompt-container pre .HtjqIa_copy-button {
  position: absolute;
  top: 0;
  right: 0;
}

.HtjqIa_raw-json-container, .HtjqIa_key-value-json-container {
  flex: 1 1 0;
  overflow-y: auto;
}

.HtjqIa_action-button-group {
  flex-direction: row;
  margin-bottom: 10px;
  display: flex;
}

.HtjqIa_llm-prompt-id {
  margin-bottom: 8px;
}

.HtjqIa_llm-prompt-id span {
  color: #444;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 2px;
  padding: 3px 8px;
  font-size: 13px;
}

.nnAtUG_schema-not-set-warning {
  height: 352px;
  background: #fff4ce;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  padding: 24px;
  display: flex;
}

.nnAtUG_schema-not-set-warning-content {
  max-width: 540px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

.nnAtUG_schema-not-set-warning-title {
  color: #323130;
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.nnAtUG_schema-not-set-warning-desc {
  color: #323130;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.NzVlTG_image-selector {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.NzVlTG_image-selector .NzVlTG_image-frame {
  width: 100px;
  height: 100px;
  border-radius: 2px;
  margin: 5px;
  display: inline-block;
}

.NzVlTG_image-selector img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: auto;
  object-fit: cover;
  transition: all .15s;
  transform: scale(1);
}

.NzVlTG_image-selector img:hover {
  z-index: 1000;
  transition: all .15s;
  position: relative;
  transform: scale(1.5);
}

._4-d5jG_ocr-test {
  height: 100%;
  flex-flow: column;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: auto;
}

._4-d5jG_sample-image-box {
  text-align: left;
  margin-bottom: 20px;
}

._4-d5jG_sample-image-box h1 {
  margin-bottom: 10px;
  font-size: 16px;
}

._4-d5jG_sample-image-box ._4-d5jG_template-description {
  color: #605e5c;
  margin-left: 14px;
  font-size: 12px;
  font-weight: 400;
}

._4-d5jG_sample-image-box ._4-d5jG_template-description i {
  vertical-align: bottom;
  margin-bottom: 3px;
  margin-right: 2px;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_warped-image-wrapper {
  height: 500px;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_control-button {
  width: 32px;
  height: 32px;
  min-width: 0;
  padding: 0;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_pagination {
  text-align: center;
  color: #605e5c;
  background: #e1dfdd80;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  bottom: 16px;
  left: 24px;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_extract-error {
  text-align: center;
  padding: 100px 20px;
  font-size: 16px;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_extract-error ._4-d5jG_reason {
  color: #605e5c;
  font-size: 14px;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_extract-error img {
  margin-bottom: -60px;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_jump-to-json {
  font-size: 14px;
  position: absolute;
  bottom: 17px;
  left: 92px;
}

._4-d5jG_report ._4-d5jG_section h1 {
  border-bottom: 1px solid #ccc;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 18px;
  display: flex;
}

._4-d5jG_report ._4-d5jG_section pre {
  background-color: #ddd;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6em;
  overflow-x: auto;
}

._4-d5jG_report ._4-d5jG_section pre pre {
  padding: 0;
}

._4-d5jG_report ._4-d5jG_section pre pre._4-d5jG_highlighted {
  background-color: #ffd63320;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_field-attr, ._4-d5jG_report ._4-d5jG_section ._4-d5jG_tokens-view {
  margin: 10px;
}

._4-d5jG_report ._4-d5jG_section img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  margin: 0 auto;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_tokens-view ul {
  margin: 4px 0;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_tokens-view li {
  background-color: #f3f2f1;
  margin: 0 4px;
  padding: 2px 6px;
  display: inline;
}

._4-d5jG_report ._4-d5jG_section ._4-d5jG_tokens-view li:first-child {
  margin-left: 0;
}

._4-d5jG_report ._4-d5jG_badge {
  border: 1px solid #ccc;
  margin: 0 4px;
  padding: 0 4px;
  font-size: 10px;
  display: inline;
}

._4-d5jG_report ._4-d5jG_download-json {
  margin-top: 15px;
}

._4-d5jG_report ._4-d5jG_download-json i {
  vertical-align: bottom;
  margin-bottom: 2px;
  margin-right: 2px;
  font-size: 13px;
}

._4-d5jG_extraction-mode {
  background: #effcfa;
  margin-bottom: 20px;
  padding: 8px;
  display: inline-block;
}

._4-d5jG_extraction-mode h3 {
  margin-right: 16px;
  padding-bottom: 0;
  font-size: 16px;
  display: inline;
}

._4-d5jG_extraction-mode button {
  color: #53b2a1;
  font-size: 12px;
}

.MV2dma_schema-not-set-warning {
  padding: 0 20px;
}

.MV2dma_ocrtest {
  width: 100%;
  height: 100%;
}

.eolfSG_form-extraction-mode-selector {
  height: 100%;
  flex-flow: column;
  align-items: center;
  display: flex;
}

.eolfSG_top {
  flex: 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 20px 20px 0;
  display: flex;
}

.eolfSG_top h3 {
  padding-bottom: 0;
  font-size: 16px;
}

.eolfSG_top button {
  color: #201f1e;
}

.eolfSG_top button i {
  font-size: 12px;
  font-weight: bolder;
}

.eolfSG_tabs {
  -webkit-user-select: none;
  user-select: none;
  flex-flow: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  padding: 15px 20px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.eolfSG_tab {
  cursor: pointer;
  border: 3px solid #0000;
  padding: 10px;
}

.eolfSG_tab .eolfSG_inner {
  width: 176px;
  border-radius: 4px;
  box-shadow: 1px 1px 6px #0000001a;
}

.eolfSG_tab .eolfSG_bottom {
  padding: 12px;
}

.eolfSG_tab .eolfSG_bottom h3 {
  color: #323130;
  font-size: 14px;
}

.eolfSG_tab .eolfSG_bottom span {
  color: #605e5c;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
}

.eolfSG_tab.eolfSG_selected {
  border: 3px solid #25d0b1;
  border-radius: 8px;
}

.LFn-ZW_container {
  border: 2px dashed var(--primary-color, #25d0b1);
  background: #cff1eab3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 24px;
  display: flex;
}

.LFn-ZW_icon {
  color: #25d0b1;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LFn-ZW_text {
  color: #25d0b1;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.KFENNG_badge {
  height: 28px;
  border-radius: 2px;
  padding: 4px 10px;
  display: inline-block;
}

.KFENNG_badge-type-correct {
  color: #27ae60;
  background: #effcfa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.KFENNG_badge-type-pending {
  color: #d83b01;
  background: #ffede7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.KFENNG_badge-type-in-progress {
  color: #d88201;
  background: #fff2e7;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.WICeNq_selectable-card {
  cursor: pointer;
  background-color: #faf9f8;
  border-radius: 10px;
  box-shadow: 0 3.2px 7.2px #00000021;
}

.WICeNq_selectable-card[disabled] {
  cursor: default;
  pointer-events: none;
}

.WICeNq_selectable-card:hover:not([disabled]) {
  background: #f4f2f1;
}

.WICeNq_selectable-card:disabled {
  background: #faf9f8;
}

.WICeNq_selectable-card-selected {
  border: 1px solid #25d0b1;
}

.WICeNq_action-button-card {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.WICeNq_image-card {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.WICeNq_image-preview {
  width: 236px;
  height: 270px;
}

.WICeNq_image-preview > img, .WICeNq_image-preview > div {
  width: 236px;
  height: 250px;
  margin: 10px 0;
}

.WICeNq_image-preview > img {
  object-fit: contain;
  cursor: pointer;
}

.WICeNq_checkbox {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 12px;
  left: 12px;
}

.WICeNq_checkbox .ms-Checkbox-checkbox {
  margin-right: 0;
}

.WICeNq_badge {
  margin-top: 12px;
}

.WICeNq_filename {
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
}

.WICeNq_uploaded-at {
  color: #605e5c;
  margin-top: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.WICeNq_sample-image-selectable-card {
  position: relative;
}

.WICeNq_selectable-card-is-deleting {
  opacity: .5;
  pointer-events: none;
}

.WICeNq_deleting-spinner {
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 16px;
}

.Nf5LGW_fsl-instant-model-editor {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.Nf5LGW_fsl-instant-model-editor-left {
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  display: flex;
}

.Nf5LGW_fsl-instant-model-editor-left-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Nf5LGW_fsl-instant-model-editor-toolbar {
  padding: 8px 0;
}

.Nf5LGW_fsl-instant-model-editor-image-list-container {
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
  position: relative;
}

.Nf5LGW_fsl-instant-model-editor-image-list-scrollable {
  flex: 1 1 0;
  padding: 20px 0;
  position: relative;
  overflow-y: auto;
}

.Nf5LGW_fsl-instant-model-editor-image-list {
  flex-flow: wrap;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.Nf5LGW_styled-mission-bar {
  margin-left: 0;
  margin-right: 0;
}

.Nf5LGW_dropzone {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.Nf5LGW_split-view {
  margin: 0 20px;
}

.E-n_bq_split-view {
  flex-direction: row;
  flex: 1 1 0;
  margin-right: 10px;
  display: flex;
}

.E-n_bq_split-view-content {
  flex-direction: column;
  flex: 2 1 0;
  display: flex;
}

.E-n_bq_page-header {
  padding: 20px;
}

.E-n_bq_command-bar {
  flex-direction: row;
  display: flex;
}

.E-n_bq_command-bar > button {
  margin-left: 6px;
}

.E-n_bq_info-button {
  margin-right: 8px;
}

.E-n_bq_content {
  flex: 1 1 0;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px 0;
  overflow-y: auto;
}

.E-n_bq_image-view-container {
  width: 100%;
  height: 100%;
  min-width: 200px;
  background: #faf9f8;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.E-n_bq_image-viewer {
  flex: 1;
}

.E-n_bq_extraction-reviewer {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.E-n_bq_content-list-value-field {
  width: 100%;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.E-n_bq_content-list-value-field .E-n_bq_content-list-value-field-row {
  flex-direction: row;
  display: flex;
}

.E-n_bq_content-list-value-field .E-n_bq_content-list-value-field-add-button {
  margin-top: 8px;
  margin-left: -4px;
  padding-left: 0;
}

.E-n_bq_teaching-bubble-content {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.Fo6qiq_styled-mission-bar {
  margin-left: 0;
  margin-right: 0;
}

.Fo6qiq_fsl-standard-model-editor {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.Fo6qiq_fsl-standard-model-editor-left {
  height: 100%;
  flex-direction: column;
  padding-right: 20px;
  display: flex;
}

.Fo6qiq_not-enabled-warning {
  height: 352px;
  background: #deecf9;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  padding: 24px;
  display: flex;
}

.Fo6qiq_not-enabled-warning-content {
  max-width: 540px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

.Fo6qiq_not-enabled-warning-title {
  color: #323130;
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.Fo6qiq_not-enabled-warning-desc {
  color: #323130;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.Fo6qiq_not-eanbled-warning-button {
  margin-top: 24px;
}

.Fo6qiq_command-bar {
  flex-direction: row;
  gap: 6px;
  display: flex;
}

.Fo6qiq_sample-images-panel {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Fo6qiq_sample-images-panel-toolbar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Fo6qiq_sample-images-panel-image-list-container {
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
  position: relative;
}

.Fo6qiq_sample-images-panel-image-list-scrollable {
  flex: 1 1 0;
  position: relative;
  overflow-y: auto;
}

.Fo6qiq_sample-images-panel-image-list {
  flex-flow: wrap;
  gap: 20px;
  margin-top: 28px;
  display: flex;
}

.Fo6qiq_status-text {
  color: #a19f9d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Fo6qiq_paginator {
  margin: 20px 0;
}

.Fo6qiq_training-banner {
  background: #ecf0ff;
  padding: 10px 10px 10px 38px;
  position: relative;
}

.Fo6qiq_training-banner .Fo6qiq_training-banner-spinner {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Fo6qiq_training-banner .Fo6qiq_training-banner-spinner .ms-Spinner-circle {
  border-color: #c7e0f4 #0078d4 #c7e0f4 #c7e0f4;
}

.Fo6qiq_training-banner .Fo6qiq_training-banner-spinner-title {
  color: #0078d4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Fo6qiq_training-banner .Fo6qiq_training-banner-spinner-desc {
  color: #605e5c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.Fo6qiq_dropzone {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}

.Fo6qiq_split-view {
  margin: 0 20px;
}

._4q5m0q_field-extra-container {
  margin-bottom: 12px;
}

._4q5m0q_field-extra-container ._4q5m0q_field2-container {
  float: left;
  margin-right: 2px;
  line-height: 38px;
}

._4q5m0q_field-extra-container ._4q5m0q_field2-container ._4q5m0q_field2-label {
  margin-right: 8px;
  display: inline;
}

._4q5m0q_field-extra-container ._4q5m0q_field2-container ._4q5m0q_field2-text {
  color: #605e5c;
  line-height: 20px;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container {
  float: right;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: -6px;
  display: inline-flex;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_setting-button {
  color: #2ec293;
  font-weight: 600;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_setting-button .ms-Icon {
  color: #2ec293;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_setting-button:hover {
  opacity: .7;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_setting-button:active {
  opacity: .5;
  color: #2ec293;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_setting-button:active .ms-Icon {
  color: #2ec293;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_action-button:disabled {
  color: #b4b2b0;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_action-button:disabled .ms-Icon {
  color: #b4b2b0;
}

._4q5m0q_field-extra-container ._4q5m0q_action-buttons-container ._4q5m0q_delete-button .ms-Icon {
  color: #464646;
  margin: 0;
}

._4q5m0q_field-extra-container:after {
  content: " ";
  clear: both;
  display: block;
}

.Gie6zG_document-field-card {
  width: 100%;
}

.UTXPZG_description {
  color: #605e5c;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 12px;
}

._7ONHpW_prompt, ._7ONHpW_task-name, ._7ONHpW_settings {
  padding-top: 10px;
}

._7ONHpW_settings {
  overflow: auto;
}

._7ONHpW_settings > div {
  float: left;
  width: 50%;
  padding: 10px;
}

._7ONHpW_prompt-selection {
  display: flex;
}

._7ONHpW_prompt-selection ._7ONHpW_prompt-selection-dropdown {
  flex: 1;
  padding-right: 10px;
}

._7ONHpW_prompt-selection ._7ONHpW_delete-prompt-button i > svg {
  height: 16px;
}

._7ONHpW_explaination {
  color: #666;
  margin-top: 4px;
  font-size: 10pt;
}

._7ONHpW_description {
  color: #666;
  font-size: 10pt;
}

.qpayhq_container {
  height: 438px;
  width: 586px;
  background: #f8f8f8;
  margin-left: 12px;
}

.qpayhq_container .qpayhq_content {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 16px 20px 0;
  display: flex;
  position: relative;
}

.qpayhq_container .qpayhq_error {
  color: #a4262c;
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
}

.qpayhq_container .qpayhq_field {
  font-size: 14px;
  line-height: 16px;
}

.qpayhq_container .qpayhq_name-field-row {
  display: flex;
}

.qpayhq_container .qpayhq_name-field {
  width: 138px;
  margin-top: 6px;
}

.qpayhq_container .qpayhq_name-text-field {
  max-width: 240px;
}

.qpayhq_container .qpayhq_tips {
  margin-top: 16px;
}

.qpayhq_container .qpayhq_header {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.qpayhq_container .qpayhq_header-bar {
  margin-top: 10px;
  display: flex;
}

.qpayhq_container .qpayhq_type-header {
  width: 142px;
}

.qpayhq_container .qpayhq_info-header {
  width: 248px;
}

.qpayhq_container .qpayhq_exact-match-header {
  width: 100px;
}

.qpayhq_container .qpayhq_divider1 {
  height: 1px;
  border-bottom: 1px solid #edebe9;
  margin-top: 16px;
}

.qpayhq_container .qpayhq_divider2 {
  height: 1px;
  border-bottom: 1px solid #edebe9;
  margin-top: 10px;
}

.qpayhq_container .qpayhq_match-mode-choice-group {
  margin-top: 12px;
}

.qpayhq_container .qpayhq_match-mode-choice-group .ms-ChoiceField {
  margin-top: 10px;
  margin-right: 60px;
  display: inline-block;
}

.qpayhq_container .qpayhq_list-container {
  flex-grow: 1;
  margin-top: 8px;
  position: relative;
}

.qpayhq_container .qpayhq_list {
  position: absolute;
  inset: 0;
}

.qpayhq_container .qpayhq_list .qpayhq_row {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.qpayhq_container .qpayhq_list .qpayhq_row .ms-Button-icon {
  color: #000;
}

.qpayhq_container .qpayhq_list .qpayhq_pattern-field, .qpayhq_container .qpayhq_list .qpayhq_type-field {
  margin-right: 20px;
}

.qpayhq_container .qpayhq_list .qpayhq_row > div:nth-child(1) {
  width: 138px;
}

.qpayhq_container .qpayhq_list .qpayhq_row > div:nth-child(2) {
  width: 252px;
}

.qpayhq_container .qpayhq_list .qpayhq_row > div:nth-child(3) {
  width: 90px;
}

.qpayhq_container .qpayhq_list .qpayhq_sticky {
  background: #f8f8f8;
  position: sticky;
  bottom: 0;
}

.qpayhq_container .qpayhq_add-new-info-button {
  color: #0078d4;
  margin-left: -10px;
}

.qpayhq_container .qpayhq_add-new-info-button:hover {
  color: #00213b;
}

.qpayhq_container .qpayhq_add-new-info-button:active {
  color: #003e6e;
}

.tvTj4G_container {
  min-height: 438px;
  width: 216px;
}

.tvTj4G_container .tvTj4G_list {
  height: 406px;
  box-sizing: border-box;
  border: 1px solid #edebe9;
  position: relative;
}

.tvTj4G_container .tvTj4G_placeholder {
  color: #a19f9d;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tvTj4G_container .tvTj4G_empty-placeholder {
  text-align: center;
  vertical-align: middle;
  overflow-wrap: anywhere;
  max-width: 216px;
  max-height: 420px;
  position: absolute;
  top: 50%;
  overflow-y: hidden;
  transform: translateY(-50%);
}

.tvTj4G_container .tvTj4G_buttons .ms-Button-icon {
  color: #000;
}

.tvTj4G_container .tvTj4G_focus-area:focus-visible {
  outline: none;
}

.tvTj4G_list-item {
  width: 100%;
  padding: 16px 8px;
}

.tvTj4G_list-item .tvTj4G_match-info {
  color: #a19f9d;
  font-size: 10px;
  line-height: 12px;
}

.tvTj4G_list-item .tvTj4G_title {
  color: #000;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 16px;
}

.tvTj4G_list-item .tvTj4G_default-title {
  color: #a19f9d;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 16px;
}

.tvTj4G_list-item .tvTj4G_available-info {
  color: #605e5c;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
}

.tvTj4G_list-item .tvTj4G_error {
  color: #a4262c;
  font-size: 12px;
  line-height: 16px;
}

.tvTj4G_selected-list-item {
  background: #edebe9;
}

.vMp-BW_model-title {
  font-weight: 600;
}

.vMp-BW_desc {
  margin-bottom: 16px;
}

.vMp-BW_desc div:first-child {
  margin-bottom: 20px;
}

.vMp-BW_filter-text-field {
  max-width: 576px;
  width: 576px;
  margin-right: 12px;
}

.vMp-BW_filter-text-icon {
  left: 0;
  right: auto;
}

.vMp-BW_sorting-dropdown {
  max-width: 224px;
  width: 224px;
}

.vMp-BW_row {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.vMp-BW_error {
  height: 32px;
  width: 100%;
  color: #201f1e;
  background: #f5879133;
  align-items: center;
  padding-left: 12px;
  display: flex;
}

.vMp-BW_error i {
  color: #a4262c;
  margin-right: 8px;
}

.vMp-BW_separator {
  height: 1px;
  width: 100%;
  background-color: #c5c5c6;
  margin: 24px 0;
}

.vMp-BW_fallback-dropdown-label {
  padding-right: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.vMp-BW_fallback-dropdown {
  max-width: 395px;
  width: 395px;
}

.r0f3Oq_text-area {
  padding-top: 10px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items {
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 4px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_auto-extraction-items-label {
  margin-top: 15px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_auto-extraction-items-label label {
  margin-right: 4px;
  display: inline;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_auto-extraction-items-label i {
  font-size: 14px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_row {
  display: flex;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_check-box {
  white-space: nowrap;
  margin-top: 8px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_setting {
  height: inherit;
  margin-top: 8px;
}

.yRjzEW_container .yRjzEW_auto-extraction-items .yRjzEW_setting:hover {
  background-color: #0000;
}

.yRjzEW_container label {
  font-size: 16px;
}

.yRjzEW_prebuilt-extractor .yRjzEW_auto-extraction-items .yRjzEW_auto-extraction-items-label {
  margin-top: 0;
  margin-bottom: 12px;
}

.yRjzEW_prebuilt-extractor .yRjzEW_auto-extraction-items .yRjzEW_items {
  max-width: 1200px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 14px;
  display: grid;
}

@media screen and (max-width: 850px) {
  .yRjzEW_prebuilt-extractor .yRjzEW_auto-extraction-items .yRjzEW_items {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 850px) and (max-width: 1025px) {
  .yRjzEW_prebuilt-extractor .yRjzEW_auto-extraction-items .yRjzEW_items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .yRjzEW_prebuilt-extractor .yRjzEW_auto-extraction-items .yRjzEW_items {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ScDUdG_inspector-note {
  flex-direction: column;
  margin-bottom: 16px;
  display: flex;
}

.ScDUdG_inspector-note > span {
  margin: 6px 0;
}

.RvhQWa_scrollable-modal .ms-Dialog-title {
  padding-bottom: 8px;
}

.RvhQWa_scrollable-modal .ms-Dialog-inner {
  padding-left: 0;
  padding-right: 0;
}

.RvhQWa_scrollable-modal .RvhQWa_scrollable-container {
  min-height: 176px;
  width: 100%;
}

.RvhQWa_scrollable-modal .RvhQWa_scrollable-contentContainer, .RvhQWa_scrollable-modal .RvhQWa_footer {
  padding: 0 24px;
}

.RvhQWa_scrollable-modal .RvhQWa_scrollable-root {
  height: calc(100% - 50px);
}

.cSwZlq_image-token-modal .ms-ChoiceField {
  margin-top: 10px;
  margin-right: 60px;
  display: inline-block;
}

.cSwZlq_image-token-modal .ms-Dialog-title {
  padding-bottom: 8px;
}

.cSwZlq_image-token-modal .cSwZlq_image {
  height: 32px;
  width: 122px;
  object-fit: contain;
}

.cSwZlq_image-token-modal table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: 0;
}

.cSwZlq_image-token-modal table thead {
  border-collapse: separate;
  border-spacing: 0 50px;
}

.cSwZlq_image-token-modal table thead td {
  border-top: 1px solid #edebe9;
  border-bottom: 1px solid #edebe9;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cSwZlq_image-token-modal table thead td span {
  font-weight: 600;
}

.cSwZlq_image-token-modal .cSwZlq_name-value-cell {
  width: 400px;
}

.cSwZlq_image-token-modal td {
  padding-top: 10px;
  padding-right: 20px;
}

.cSwZlq_image-token-modal .cSwZlq_name-value-field {
  max-width: 148px;
}

.cSwZlq_image-token-modal .cSwZlq_separator {
  height: 1px;
  width: 100%;
  background-color: #c5c5c6;
  margin: 16px 0;
}

.cSwZlq_image-token-modal .cSwZlq_add-tokens-session {
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.cSwZlq_image-token-modal .cSwZlq_add-tokens-session .cSwZlq_add-tokens-title {
  font-weight: 600;
}

.cSwZlq_image-token-modal .cSwZlq_add-tokens-session .ms-Button {
  margin-left: auto;
}

.cSwZlq_image-token-modal .cSwZlq_invisible-image-upload-input {
  display: none;
}

.cSwZlq_image-token-modal .cSwZlq_error-td {
  padding: 0;
}

.YqPEsq_spinButtonError {
  margin-top: 4px;
}

.xk9--a_pattern-range {
  flex-direction: row;
  flex: 1;
  display: flex;
}

.xk9--a_pattern-range .xk9--a_spin-separator {
  width: 48px;
}

.xk9--a_pattern-range .xk9--a_spin-button {
  width: 100px;
}

.xk9--a_reminder {
  flex-direction: column;
  margin-top: 8px;
  display: flex;
}

.xk9--a_reminder > span {
  font-size: 10px;
}

.Pvyxgq_key-value-modal .ms-Dialog-title {
  padding-bottom: 8px;
}

.Pvyxgq_input-field {
  margin-bottom: 16px;
}

.Pvyxgq_input-field .ms-Dropdown.is-disabled .ms-Dropdown-title {
  color: #0000;
}

.Pvyxgq_modal-title-text {
  color: #201f1e;
  padding-top: 18px;
  padding-bottom: 3px;
  font-size: 18px;
  display: block;
}

.Pvyxgq_modal-desc-row {
  margin-bottom: 8px;
}

.Pvyxgq_modal-desc-text {
  color: #201f1e;
  font-size: 14px;
}

.Pvyxgq_modal-desc-text.Pvyxgq_see-example-button {
  cursor: pointer;
  color: #0078d4;
}

.Pvyxgq_modal-desc-text.Pvyxgq_see-example-button:hover {
  color: #0078d4b3;
}

.Pvyxgq_values-title-row {
  flex-direction: column;
  margin: 25px 0 5px;
  display: flex;
}

.Pvyxgq_values-title-row .Pvyxgq_values-title-text {
  color: #201f1e;
  font-size: 18px;
  font-weight: bold;
}

.Pvyxgq_values-title-row .Pvyxgq_values-error-text {
  color: #a4262c;
  font-size: 12px;
}

.Pvyxgq_pattern-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.Pvyxgq_pattern-row .Pvyxgq_regex-input {
  flex: 1 1 0;
  margin-right: 10px;
}

.Pvyxgq_pattern-row .Pvyxgq_build-button {
  margin-top: 30px;
}

.Pvyxgq_pattern-row .Pvyxgq_regex-builder-button {
  align-self: flex-start;
  margin-top: 30px;
}

.Pvyxgq_regex-builder-button {
  color: #0078d4;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
}

.Pvyxgq_regex-builder-button:hover {
  color: #0078d4b3;
}

.Pvyxgq_key-section .Pvyxgq_title-section {
  border-bottom: 1px solid #e9e9e9;
  padding: 9px 4px;
}

.Pvyxgq_key-section .Pvyxgq_title-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.Pvyxgq_key-section .Pvyxgq_title-text {
  color: #201f1e;
  font-size: 18px;
  font-weight: 700;
}

.Pvyxgq_key-section .Pvyxgq_table-header-text {
  color: #201f1e;
  font-size: 14px;
  font-weight: 600;
}

.Pvyxgq_key-section .Pvyxgq_key-column {
  flex: 1 1 0;
}

.Pvyxgq_key-section .Pvyxgq_fuzzy-column {
  width: 156px;
  flex: none;
}

.Pvyxgq_key-section .Pvyxgq_table-header-cell {
  padding: 10px 4px;
}

.Pvyxgq_key-section .Pvyxgq_table-header-row, .Pvyxgq_key-section .Pvyxgq_token-row {
  border-bottom: 1px solid #e9e9e9;
  flex-direction: row;
  display: flex;
}

.Pvyxgq_key-section .Pvyxgq_key-input {
  width: 193px;
  margin: 10px 0;
}

.Pvyxgq_key-section .Pvyxgq_table-cell {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.Pvyxgq_key-section .Pvyxgq_table-cell.Pvyxgq_fuzzy-cell {
  justify-content: space-between;
}

.Pvyxgq_key-section .Pvyxgq_empty-row {
  text-align: center;
  color: #201f1e;
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
  display: block;
}

.Pvyxgq_key-section .Pvyxgq_fuzzy-toggle {
  margin: 0;
}

.Pvyxgq_key-section .Pvyxgq_delete-button {
  cursor: pointer;
  padding: 15px 24px;
  font-size: 16px;
}

.Pvyxgq_key-section .Pvyxgq_delete-button:hover {
  color: #00000080;
}

.Pvyxgq_key-section .Pvyxgq_new-key-button {
  cursor: pointer;
  color: #0078d4;
  font-size: 13px;
  display: block;
}

.Pvyxgq_key-section .Pvyxgq_new-key-button:hover {
  color: #0078d4b3;
}

.Pvyxgq_key-section .Pvyxgq_regex-builder-button {
  align-self: flex-start;
  margin: 11px 0;
}

.Pvyxgq_key-section .Pvyxgq_section-error {
  color: #a4262c;
  font-size: 12px;
}

.o0AXda_container .o0AXda_label {
  font-size: 16px;
}

.o0AXda_container .o0AXda_description {
  color: #605e5c;
  margin-bottom: 16px;
  font-size: 14px;
}

.GOBAbq_container {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-left: 16px;
  display: flex;
  overflow-y: hidden;
}

.GOBAbq_container .GOBAbq_separator {
  height: 1px;
  width: calc(100% - 24px);
  background-color: #ccc;
}

.GOBAbq_content {
  flex: 1;
  overflow-y: auto;
}

.GOBAbq_save-button-container {
  height: 64px;
  border-top: 1px solid #ccc;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 24px;
  display: flex;
}

.GOBAbq_save-button-container > .GOBAbq_save-button {
  background-color: #ffd633;
  border-color: #ffd633;
  margin-top: 12px;
}

.GOBAbq_save-button-container > .GOBAbq_save-button:hover {
  background-color: #cca300;
  border-color: #cca300;
}

.GOBAbq_save-button-container > .GOBAbq_save-button:active {
  background-color: #997a00;
  border-color: #997a00;
}

.GOBAbq_save-button-container > .GOBAbq_disabled {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.wxTGya_text-token-modal .ms-ChoiceField {
  margin-top: 10px;
  margin-right: 60px;
  display: inline-block;
}

.wxTGya_text-token-modal .wxTGya_note {
  flex-direction: column;
  margin-bottom: 16px;
  display: flex;
}

.wxTGya_text-token-modal .wxTGya_note > span {
  margin: 6px 0;
}

.wxTGya_text-token-modal table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: 0;
}

.wxTGya_text-token-modal table thead {
  border-collapse: separate;
  border-spacing: 0 50px;
}

.wxTGya_text-token-modal table thead td {
  border-top: 1px solid #edebe9;
  border-bottom: 1px solid #edebe9;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wxTGya_text-token-modal table thead td span {
  font-weight: 600;
}

.wxTGya_text-token-modal td {
  padding-top: 10px;
  padding-right: 20px;
}

.wxTGya_text-token-modal .wxTGya_text-token-field {
  max-width: 122px;
}

.wxTGya_text-token-modal .wxTGya_field-value-field {
  max-width: 148px;
}

.wxTGya_text-token-modal .wxTGya_separator {
  height: 1px;
  width: 100%;
  background-color: #c5c5c6;
  margin: 16px 0;
}

.wxTGya_text-token-modal .wxTGya_add-tokens-session {
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.wxTGya_text-token-modal .wxTGya_add-tokens-session .wxTGya_add-tokens-title {
  font-weight: 600;
}

.wxTGya_text-token-modal .wxTGya_add-tokens-session .ms-Button {
  margin-left: auto;
}

.wxTGya_text-token-modal .wxTGya_error-td {
  padding: 0;
}

.XWHhgG_content {
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 12px 22px 12px 6px;
  display: flex;
}

.XWHhgG_sub-content {
  width: 100%;
  flex: 1;
  padding-bottom: 20px;
  padding-right: 10px;
}

.XWHhgG_tab-bar {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 8px;
  display: flex;
}

.XWHhgG_tab-bar > div > button {
  width: 92px;
}

.XWHhgG_tab-bar > div:nth-child(2) {
  flex: 1 1 0;
  overflow-y: auto;
}

.XWHhgG_custom_extraction_items {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.XWHhgG_custom_extraction_items .XWHhgG_custom_extraction_items_label {
  margin-right: 4px;
  font-size: 16px;
}

.XWHhgG_custom_extraction_items i {
  font-size: 14px;
}

.XWHhgG_custom_extraction_items_dropdown {
  margin-bottom: 10px;
}

.XWHhgG_button-container {
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
  padding-right: 16px;
  display: flex;
}

.XWHhgG_separator--before-advance-token-setup, .XWHhgG_separator {
  height: 1px;
  width: 100%;
  background-color: #c5c5c6;
  margin: 16px 0;
}

.XWHhgG_separator--before-advance-token-setup {
  margin: 10px 0 30px;
}

.XWHhgG_prebuilt-extractor {
  min-width: 300px;
  padding: 20px;
}

.XWHhgG_prebuilt-extractor .XWHhgG_custom_extraction_items_dropdown {
  width: 300px;
}

.XWHhgG_prebuilt-extractor .XWHhgG_document-field-cards {
  max-width: 1200px;
  flex-wrap: wrap;
  column-gap: 14px;
  display: flex;
}

.XWHhgG_prebuilt-extractor .XWHhgG_document-field-card {
  width: calc(33.3333% - 10px);
}

@media screen and (max-width: 1200px) {
  .XWHhgG_prebuilt-extractor .XWHhgG_document-field-card {
    width: calc(50% - 7px);
  }
}

@media screen and (max-width: 850px) {
  .XWHhgG_prebuilt-extractor .XWHhgG_document-field-cards {
    flex-flow: column;
  }

  .XWHhgG_prebuilt-extractor .XWHhgG_document-field-card {
    width: 100%;
  }
}

.XWHhgG_footer {
  width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 0 20px 10px;
}

.XWHhgG_footer-separator {
  height: 1px;
  background-color: #edebe9;
  margin-bottom: 11px;
}

.XWHhgG_footer-buttons {
  justify-content: flex-end;
  display: flex;
}

.mqyUSq_anchor-inspector {
  flex-direction: column;
  margin: 10px 24px 10px 8px;
  display: flex;
}

.mqyUSq_anchor-inspector h1 {
  margin-bottom: 8px;
  font-size: 18px;
}

.mqyUSq_anchor-inspector .mqyUSq_delete-button {
  align-self: flex-end;
  margin: 5px 0;
  font-size: 11px;
}

.RsgBva_description {
  margin-bottom: 20px;
}

.RsgBva_date-time-field-modal .ms-ChoiceField {
  margin-top: 10px;
  margin-right: 40px;
  display: inline-block;
}

.RsgBva_water-mark-setting {
  margin-top: 16px;
}

._6J7yaa_detection-region-field {
  width: 100%;
  border-bottom: 1px solid #ccc;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  padding-bottom: 12px;
  display: flex;
}

._6J7yaa_detection-region-field p {
  font-size: 10pt;
}

._6J7yaa_detection-region-field ._6J7yaa_field-type-select-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._6J7yaa_detection-region-field ._6J7yaa_field-type-select-row ._6J7yaa_field-type-select {
  width: calc(100% - 36px);
}

._6J7yaa_detection-region-field ._6J7yaa_field-type-select-row ._6J7yaa_delete-button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  margin-top: 16px;
}

._6J7yaa_detection-region-field ._6J7yaa_extra-button {
  align-self: flex-start;
  margin-top: 12px;
}

._6J7yaa_detection-region-field:last-child {
  border: 0;
}

._6J7yaa_detection-region-field:after {
  content: "";
  clear: both;
  display: table;
}

._6J7yaa_action-buttons-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: -4px;
  display: flex;
}

._6J7yaa_action-button:disabled .ms-Icon {
  color: #b4b2b0;
}

._6J7yaa_action-button .ms-Icon {
  color: #464646;
}

._1OI7W_detection-region-insepctor {
  flex-direction: column;
  align-items: center;
  margin: 10px 24px 10px 8px;
  display: flex;
}

._1OI7W_detection-region-insepctor ._1OI7W_add_extraction_field {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

._1OI7W_detection-region-insepctor ._1OI7W_add_extraction_field ._1OI7W_add_extraction_field_label {
  margin-right: 4px;
  font-size: 16px;
}

._1OI7W_detection-region-insepctor ._1OI7W_add_extraction_field i {
  font-size: 14px;
}

._1OI7W_detection-region-insepctor ._1OI7W_sub-content {
  width: 100%;
  padding-right: 10px;
}

._1OI7W_detection-region-insepctor ._1OI7W_button-container {
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button {
  margin-left: 20px;
  font-size: 11px;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button .ms-Icon {
  color: #464646;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button:hover {
  font-size: 11px;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button:hover .ms-Icon {
  color: #2ec293;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button:active {
  font-size: 11px;
}

._1OI7W_detection-region-insepctor ._1OI7W_delete-button:active .ms-Icon {
  color: #464646;
}

.nWMaGG_anchor-group {
  margin-top: 10px;
}

.nWMaGG_anchor-group .nWMaGG_option-checkbox {
  padding-top: 10px;
}

.nWMaGG_description {
  white-space: pre-wrap;
  padding-bottom: 10px;
}

.nWMaGG_water-mark-setting {
  margin-top: 30px;
}

.sHRu3G_water-mark-setting {
  padding-bottom: 34px;
}

._8Omm9G_description {
  margin-bottom: 20px;
}

._8Omm9G_water-mark-setting {
  margin-top: 20px;
}

._6XYBQq_form-non-saved-prompt-dialog .ms-Dialog-main {
  min-height: 150px;
}

._6XYBQq_form-non-saved-prompt-dialog .ms-Dialog-subText {
  color: inherit;
}

._6XYBQq_form-non-saved-prompt-dialog ._6XYBQq_button-actions {
  align-items: center;
  display: flex;
}

._6XYBQq_form-non-saved-prompt-dialog ._6XYBQq_cancel-button {
  border: none;
  margin-left: -8px;
}

._6XYBQq_form-non-saved-prompt-dialog ._6XYBQq_cancel-button:hover {
  opacity: .7;
  color: inherit;
}

._6XYBQq_form-non-saved-prompt-dialog ._6XYBQq_cancel-button:active {
  opacity: .3;
}

._6XYBQq_form-non-saved-prompt-dialog ._6XYBQq_dont-save-button {
  margin-left: auto;
  margin-right: 10px;
}

.VESzjq_labeller {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  display: flex;
  overflow: auto;
}

.VESzjq_labeller .VESzjq_canvas {
  background-repeat: no-repeat;
  background-origin: content-box;
  margin: auto;
}

.VESzjq_floating-left {
  width: 48px;
  margin-top: -72px;
  margin-left: 16px;
  position: fixed;
  top: 50%;
}

.VESzjq_floating-left .VESzjq_info {
  color: #fff;
  width: 24px;
  height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 50%;
  margin: auto auto 12px;
  font-size: 26px;
  display: block;
}

.VESzjq_floating-left .VESzjq_info.VESzjq_disabled {
  pointer-events: none;
  opacity: .5;
}

.VESzjq_floating-left .VESzjq_toolbox {
  background-color: #464646;
}

.VESzjq_floating-left .VESzjq_toolbox .VESzjq_tool {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.VESzjq_floating-left .VESzjq_toolbox div.VESzjq_selected {
  box-shadow: inset 0 0 18px #bbb63599;
}

.VESzjq_floating-left .VESzjq_toolbox div:hover {
  background-color: #aa0;
}

.VESzjq_floating-left .VESzjq_toolbox div:active {
  background-color: #c9c940;
}

.LNzWYG_container {
  height: 100%;
  background-color: #faf9f8;
  display: flex;
  overflow: auto;
}

.LNzWYG_content {
  width: 100%;
  max-width: 618px;
  background-color: #fff;
  flex-direction: column;
  margin: 45px auto auto;
  padding: 24px 20px;
  display: flex;
}

.LNzWYG_content .LNzWYG_upload-icon {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-size: 60px;
}

.LNzWYG_content .LNzWYG_instruction1 {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.LNzWYG_content .LNzWYG_drop-zone {
  width: 100%;
  height: 128px;
  background-color: #faf9f8;
  border: 1px dashed #d2d0ce;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.LNzWYG_content .LNzWYG_separator {
  width: 100%;
  border-top: 1px solid #edebe9;
  margin-top: 24px;
  margin-bottom: 24px;
}

.LNzWYG_content .LNzWYG_instruction2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
}

.LNzWYG_content .LNzWYG_instruction3 {
  color: #605e5c;
  margin-top: 8px;
  font-size: 14px;
  line-height: 140%;
}

.LNzWYG_content .LNzWYG_instruction3 .LNzWYG_highlights {
  color: #000;
}

.LNzWYG_content .LNzWYG_dos-and-donts {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  display: flex;
}

.LNzWYG_content .LNzWYG_dos, .LNzWYG_content .LNzWYG_donts {
  width: calc(50% - 12px);
}

.LNzWYG_content .LNzWYG_dos-title, .LNzWYG_content .LNzWYG_donts-title {
  font-size: 14px;
  font-weight: 600;
}

.LNzWYG_content .LNzWYG_dos-title {
  color: #25d0b1;
}

.LNzWYG_content .LNzWYG_donts-title {
  color: #a4262c;
}

.LNzWYG_content .LNzWYG_dos-image, .LNzWYG_content .LNzWYG_donts-image {
  width: 100%;
  height: 166px;
  object-fit: cover;
  margin-top: 13px;
}

.LNzWYG_content .LNzWYG_dos-and-donts-item {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
}

.LNzWYG_content .LNzWYG_tick, .LNzWYG_content .LNzWYG_cross {
  height: 13px;
  width: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  margin-bottom: -1px;
  margin-right: 11px;
  display: inline-block;
}

.LNzWYG_content .LNzWYG_tick {
  background-image: url("icon-green-tick.4b446b59.svg");
}

.LNzWYG_content .LNzWYG_cross {
  background-image: url("icon-red-cross.c0cfcc92.svg");
}

.r0Sp_q_container {
  width: 305px;
  background-color: #f6fdfc;
  border-left: 3px solid #1d9f87;
  border-radius: 2px;
  margin-top: 12px;
  margin-left: 35px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  box-shadow: 0 1.2px 3.6px #0000001a, 0 6.4px 14.4px #00000021;
}

.r0Sp_q_title {
  align-items: center;
  padding: 0;
  font-size: 14px;
  display: flex;
}

.r0Sp_q_title-icon {
  margin-right: 7.5px;
  font-size: 12px;
}

.r0Sp_q_title-container {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.r0Sp_q_close-button {
  color: #1d9f87;
  height: 16px;
  padding: 0;
  font-size: 12px;
}

.r0Sp_q_close-button:hover {
  opacity: .7;
}

.r0Sp_q_highlight {
  font-weight: 600;
}

.r0Sp_q_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.r0Sp_q_remind-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #53b2a1;
  margin-top: 8px;
}

.r0Sp_q_remind-button:hover {
  opacity: .7;
}

.LKxBJW_steps {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  display: flex;
}

.LKxBJW_step {
  opacity: .3;
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: #8a8886;
  border-radius: 50%;
  margin: 0 4.5px;
}

.LKxBJW_step.LKxBJW_active {
  opacity: 1;
  background: #323130;
}

.LKxBJW_buttons {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 26px 20px;
  display: flex;
}

.LKxBJW_skip-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #1d9f87;
  font-size: 14px;
  font-weight: 400;
}

.LKxBJW_skip-button:hover {
  opacity: .7;
}

.sLZhda_text-content {
  padding: 20px;
}

.sLZhda_title {
  color: #1d9f87;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  display: block;
}

.sLZhda_description {
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
}

.sLZhda_separator {
  width: 100%;
  border-top: 1px solid #f3f2f1;
}

.sLZhda_navigate-step-content {
  padding: 12px 20px;
}

.sLZhda_step-title {
  color: #201f1e;
  font-size: 14px;
  font-weight: 400;
}

.sLZhda_step-button {
  width: 100%;
  cursor: pointer;
  color: #201f1e;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.sLZhda_step-button:hover {
  opacity: .7;
}

.sLZhda_step-button.sLZhda_disabled, .sLZhda_step-button:hover.sLZhda_disabled {
  pointer-events: none;
  opacity: .5;
}

.sLZhda_step-arrow {
  font-weight: 700;
}

.sLZhda_buttons {
  justify-content: space-between;
  align-items: center;
  padding: 28px 20px;
  display: flex;
}

.sLZhda_checkbox {
  max-width: 50%;
}

.sLZhda_checkbox .ms-Checkbox-label {
  align-items: center;
}

._0gTj3q_text-content {
  padding: 20px 20px 0;
}

._0gTj3q_highlight {
  color: #a4262c;
}

._0gTj3q_title {
  color: #323130;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

._0gTj3q_description {
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
}

._0gTj3q_description:not(:first-child) {
  margin-top: 20px;
}

._0gTj3q_extra-padding {
  margin-bottom: 20px;
}

._0gTj3q_image {
  width: 100%;
  height: 182px;
}

.V_6CWG_text-content {
  padding: 20px 20px 0;
}

.V_6CWG_highlight {
  color: #a4262c;
}

.V_6CWG_title {
  color: #323130;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.V_6CWG_description {
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
}

.V_6CWG_description:not(:first-child) {
  margin-top: 20px;
}

.V_6CWG_image {
  width: 100%;
}

.VT2gSq_text-content {
  padding: 20px 20px 0;
}

.VT2gSq_highlight {
  color: #6888fa;
}

.VT2gSq_title {
  color: #323130;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.VT2gSq_description {
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
}

.VT2gSq_description:not(:first-child) {
  margin-top: 20px;
}

.VT2gSq_extra-padding {
  margin-bottom: 20px;
}

.VT2gSq_image {
  width: 100%;
  height: 182px;
}

.Vqezwq_text-content {
  padding: 20px 20px 0;
}

.Vqezwq_highlight {
  color: #a4262c;
}

.Vqezwq_title {
  color: #323130;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: block;
}

.Vqezwq_description {
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  display: block;
}

.Vqezwq_description:not(:first-child) {
  margin-top: 20px;
}

.Vqezwq_image {
  width: 100%;
}

.gmF3la_form-group-table, .gmF3la_form-group-table-no-hover {
  padding-top: 16px;
}

.gmF3la_form-group-table-no-hover tbody > tr:hover {
  background-color: #0000;
}

.gmF3la_form-group-token-group-table-row td {
  vertical-align: text-top;
}

.gmF3la_form-group-token-group-table-row td:nth-child(1) {
  min-width: 100px;
}

.gmF3la_form-group-token-group-table-row td:nth-child(2) {
  min-width: 150px;
}

.gmF3la_form-group-token-group-table-row td:nth-child(3) {
  min-width: 100px;
}

.gmF3la_form-group-token-group-table-row td:nth-child(4) {
  width: 100px;
}

.gmF3la_form-group-token-group-table-row .gmF3la_action-container {
  display: flex;
}

.gmF3la_form-group-token-group-table-row .gmF3la_action-button {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.gmF3la_form-group-anchor-table-row td:nth-child(1) {
  width: 100%;
}

.gmF3la_form-group-anchor-table-row td:nth-child(2) {
  width: 100px;
}

.gmF3la_form-group-anchor-table-row .gmF3la_action-container {
  display: flex;
}

.gmF3la_form-group-anchor-table-row .gmF3la_action-button {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.gmF3la_form-group-document-detection-table-row td:nth-child(1) {
  width: 30%;
  white-space: nowrap;
}

.gmF3la_form-group-document-detection-table-row td:nth-child(2) {
  width: 70%;
  color: #605e5c;
}

.gmF3la_form-group-document-detection-table-row .gmF3la_action-container {
  display: flex;
}

.gmF3la_form-group-document-detection-table-row .gmF3la_action-button {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.gmF3la_document-detection-modal-document-type-dropdown {
  margin-bottom: 20px;
}

.gmF3la_document-detection-modal-footer {
  margin-top: 20px;
}

.gmF3la_form-group-editor {
  height: 100%;
  padding: 20px;
  overflow: auto;
}

.gmF3la_form-group-editor h1 {
  border-bottom: 1px solid #ccc;
  margin-top: 12px;
  padding: 12px 0;
  font-size: 18px;
}

.gmF3la_form-group-editor .gmF3la_description {
  margin: 10px 20px;
  padding: 10px 0 0;
  display: block;
}

.gmF3la_form-group-editor .gmF3la_section {
  margin-bottom: 20px;
  padding: 0 12px;
}

.gmF3la_form-group-editor .gmF3la_buttons {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.gmF3la_form-group-editor .gmF3la_group-name {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20pt;
}

.gmF3la_form-group-form-list {
  position: relative;
}

.gmF3la_form-group-form-list .ms-DetailsRow-cell {
  color: #000;
  font-size: 14px;
}

.gmF3la_form-group-form-list .ms-DetailsRow:hover {
  background-color: #fff;
}

.gmF3la_form-group-form-list .gmF3la_add-new-form-button {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.gmF3la_form-group-form-list .gmF3la_add-new-form-button-empty-list {
  width: 220px;
  height: 131px;
  background: #faf9f8;
  border: 1px solid #d2d0ce;
  justify-content: center;
  padding: 24px;
  box-shadow: 0 .6px 1.8px #0000001a, 0 3.2px 7.2px #00000021;
}

.gmF3la_form-group-form-list .gmF3la_add-new-form-button-empty-list span {
  font-size: 16px;
}

.gmF3la_form-group-form-list .gmF3la_add-new-form-button-empty-list .gmF3la_form-group-document-detection-add-rule-icon {
  color: #201f1e;
  font-size: 20px;
}

.gmF3la_form-group-form-list .gmF3la_add-new-area {
  flex-direction: column;
  align-items: flex-start;
  margin: 22px 0 2px;
  padding: 10px;
  display: flex;
}

.gmF3la_form-group-form-list .gmF3la_add-rule-icon {
  color: #25d0b1;
  margin-right: 10px;
}

.gmF3la_form-group-form-list .gmF3la_add-new-form-button-icon {
  margin-right: 10px;
}

.gmF3la_form-group-form-list .gmF3la_action-container {
  display: flex;
}

.gmF3la_form-group-form-list .gmF3la_action-button {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.gmF3la_form-group-form-list .gmF3la_loading-container {
  background-color: #ffffff80;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.gmF3la_anchor-modal-description {
  margin: 10px 0;
}

.gmF3la_form-group-token-group-content {
  width: 100%;
  flex-direction: column;
  padding: 4px 0 4px 8px;
  display: flex;
}

.gmF3la_form-group-token-group-content .gmF3la_token-row {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.gmF3la_form-group-token-group-content .gmF3la_link {
  margin-left: 8px;
  padding: 8px 0 6px;
  font-size: 14px;
}

.gmF3la_form-group-token-group-content .gmF3la_token-form-name {
  padding-bottom: 10px;
}

.gmF3la_form-group-token-group-modal-form .gmF3la_match-mode-choice-group {
  margin-top: 20px;
}

.gmF3la_form-group-token-group-modal-form .gmF3la_match-mode-choice-group .ms-ChoiceFieldLabel {
  white-space: pre-wrap;
}

.gmF3la_form-group-token-group-modal-form .gmF3la_match-mode-choice-group .ms-ChoiceField + .ms-ChoiceField {
  margin-top: 25px;
}

.gmF3la_form-group-fallback-form-content {
  border-bottom: 1px solid #edebe9;
  padding: 15px 16px 15px 20px;
}

.gmF3la_form-group-fallback-form-content .gmF3la_title {
  color: #201f1e;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
}

.gmF3la_form-group-fallback-form-content .gmF3la_link {
  font-size: 14px;
}

.gmF3la_form-group-fallback-form-content .gmF3la_action-button {
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
}

.gmF3la_form-group-fallback-form-content .gmF3la_fallback-form-item {
  flex-direction: row;
  justify-content: space-between;
  margin-left: 7px;
  display: flex;
}

._4ZaMTa_modal ._4ZaMTa_upload-image-field {
  margin-top: 12px;
}

._4ZaMTa_modal ._4ZaMTa_upload-image-field ._4ZaMTa_file-section {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

._4ZaMTa_modal ._4ZaMTa_upload-image-field ._4ZaMTa_file-section ._4ZaMTa_file-name-container {
  height: 30px;
  width: 320px;
  border-bottom: 1px solid #8f8e8c;
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
}

._4ZaMTa_modal ._4ZaMTa_upload-image-field ._4ZaMTa_file-section ._4ZaMTa_file-name-container > span {
  width: 320px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  bottom: 4px;
  overflow: hidden;
}

._4ZaMTa_modal ._4ZaMTa_existing-image {
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #0003;
  margin: 30px 20px 20px;
}

._4ZaMTa_invisible-image-upload-input {
  display: none;
}

.ezsSlW_token-group-content {
  width: 100%;
  border-bottom: 1px solid #ccc;
  flex-direction: column;
  padding: 4px 0 4px 8px;
  display: flex;
}

.ezsSlW_token-group-content .ezsSlW_token-row {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.ezsSlW_token-group-content .ezsSlW_token-row-second {
  border-top: 1px solid #ccc;
}

.ezsSlW_token-group-content .ezsSlW_link {
  margin-left: 8px;
  padding: 8px 0 6px;
  font-size: 14px;
}

.ezsSlW_token-card {
  max-width: 260px;
  border-radius: 2px;
  margin: 8px;
}

.ezsSlW_token-card > span {
  max-width: 246px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow-x: hidden;
}

.ezsSlW_text-token-card {
  height: 24px;
  cursor: pointer;
  background-color: #f3f2f1;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  padding: 4px;
  display: flex;
}

.ezsSlW_text-token-card > span {
  padding-right: 6px;
  font-size: 14px;
}

.ezsSlW_delete-button {
  cursor: pointer;
  font-size: 14px;
}

.ezsSlW_image-token-card {
  flex-direction: column;
  margin-right: 8px;
  display: flex;
}

.ezsSlW_image-token-card .ezsSlW_image {
  height: 48px;
  width: 72px;
  cursor: pointer;
  border: 1px solid #ccc;
  margin: 0;
}

.ezsSlW_image-token-card .ezsSlW_image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ezsSlW_image-token-card .ezsSlW_bottom-bar {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.ezsSlW_image-token-card .ezsSlW_bottom-bar > span {
  width: 58px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 6px;
  font-size: 12px;
  display: block;
  overflow: hidden;
}

.ezsSlW_image-token-card .ezsSlW_delete-button {
  padding-top: 2px;
}

.ezsSlW_image-token-spinner {
  width: 72px;
  height: 70px;
  margin: 8px;
}

.C0no5a_token-group-text-modal .C0no5a_match-option-checkbox {
  margin: 15px 0 10px;
}

.C0no5a_token-group-text-modal .C0no5a_match-option-checkbox + .C0no5a_match-option-checkbox {
  margin-top: 0;
}

.rACTAa_container {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.rACTAa_container .rACTAa_content {
  align-self: center;
}

.rACTAa_container .rACTAa_content h3 {
  margin-bottom: 12px;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
}

.rACTAa_container .rACTAa_content .rACTAa_message {
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 20px;
}

.rACTAa_container .rACTAa_content .rACTAa_action .rACTAa_default-button {
  margin-left: 18px;
}

.ONFW7G_container h1 {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.ONFW7G_container h2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.ONFW7G_container h3 {
  color: #888;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
}

.ONFW7G_container .ONFW7G_content {
  width: 560px;
  margin: auto;
  padding: 32px 0;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_action {
  color: #25d0b1;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations {
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 28px;
  display: flex;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation {
  width: 100%;
  padding: 20px;
  box-shadow: 0 .6px 1.8px #0000001a, 0 3.2px 7.2px #00000021;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation .ONFW7G_bottom {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
  display: flex;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation .ONFW7G_bottom .ONFW7G_loading-button {
  width: 86px;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation h1, .ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation h3 {
  margin-bottom: 2px;
}

.ONFW7G_container .ONFW7G_content .ONFW7G_invitations hr {
  width: 100%;
  border-top: 1px solid #edebe9;
  border-bottom: 0;
  margin: 12px 0;
}

.ONFW7G_container .ONFW7G_message {
  padding-bottom: 18px;
}

.ONFW7G_container .ONFW7G_message h3 {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

@media screen and (max-width: 580px) {
  .ONFW7G_container .ONFW7G_content {
    padding: 20px;
  }

  .ONFW7G_container .ONFW7G_content .ONFW7G_invitations .ONFW7G_invitation {
    padding: 12px;
  }
}

.Arv3zG_title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.Arv3zG_footer {
  text-align: right;
  margin-top: 20px;
}

.Arv3zG_login-change-password-form {
  width: 350px;
  margin-top: -176px;
  margin-left: -175px;
  position: absolute;
  top: 50vh;
  left: 50vw;
}

@media only screen and (max-width: 350px) {
  .Arv3zG_login-change-password-form {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }
}

.yvWCNq_login-form {
  width: 350px;
  margin-top: -176px;
  margin-left: -175px;
  position: absolute;
  top: 50vh;
  left: 50vw;
}

@media only screen and (max-width: 350px) {
  .yvWCNq_login-form {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }
}

.yvWCNq_login-form .yvWCNq_logo {
  height: 36px;
  background-image: url("/logo-color.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}

.yvWCNq_login-form .yvWCNq_link {
  text-align: right;
  margin-bottom: 14px;
  font-size: 14px;
}

.yvWCNq_login-form > .ms-TextField {
  height: 82px;
}

.yvWCNq_login-form .yvWCNq_button {
  text-align: right;
}

.iti {
  display: inline-block;
  position: relative;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input.iti__tel-input, .iti input.iti__tel-input[type="text"], .iti input.iti__tel-input[type="tel"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.iti__flag-container {
  padding: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.iti__selected-flag {
  z-index: 1;
  height: 100%;
  align-items: center;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
}

.iti__arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #555;
  border-left: 3px solid #0000;
  border-right: 3px solid #0000;
  margin-left: 6px;
}

[dir="rtl"] .iti__arrow {
  margin-left: 0;
  margin-right: 6px;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__dropdown-content {
  background-color: #fff;
  border-radius: 3px;
}

.iti--inline-dropdown .iti__dropdown-content {
  z-index: 2;
  border: 1px solid #ccc;
  margin-top: 3px;
  margin-left: -1px;
  position: absolute;
  box-shadow: 1px 1px 4px #0003;
}

.iti__dropdown-content--dropup {
  margin-bottom: 3px;
  bottom: 100%;
}

.iti__search-input {
  width: 100%;
  border-width: 0;
  border-radius: 3px;
  padding: 9px 12px;
}

.iti__search-input + .iti__country-list {
  border-top: 1px solid #ccc;
}

.iti__country-list {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
}

.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}

.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}

@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}

.iti__flag-box {
  width: 20px;
  display: inline-block;
}

.iti__divider {
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.iti__country {
  outline: none;
  align-items: center;
  padding: 8px;
  display: flex;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: #0000000d;
}

.iti__flag-box, .iti__country-name {
  margin-right: 8px;
}

[dir="rtl"] .iti__flag-box, [dir="rtl"] .iti__country-name {
  margin-left: 8px;
  margin-right: 0;
}

.iti--allow-dropdown input.iti__tel-input, .iti--allow-dropdown input.iti__tel-input[type="text"], .iti--allow-dropdown input.iti__tel-input[type="tel"], .iti--show-selected-dial-code input.iti__tel-input, .iti--show-selected-dial-code input.iti__tel-input[type="text"], .iti--show-selected-dial-code input.iti__tel-input[type="tel"] {
  margin-left: 0;
  padding-left: 52px;
  padding-right: 6px;
}

[dir="rtl"] .iti--allow-dropdown input.iti__tel-input, [dir="rtl"] .iti--allow-dropdown input.iti__tel-input[type="text"], [dir="rtl"] .iti--allow-dropdown input.iti__tel-input[type="tel"], [dir="rtl"] .iti--show-selected-dial-code input.iti__tel-input, [dir="rtl"] .iti--show-selected-dial-code input.iti__tel-input[type="text"], [dir="rtl"] .iti--show-selected-dial-code input.iti__tel-input[type="tel"] {
  margin-right: 0;
  padding-left: 6px;
  padding-right: 52px;
}

.iti--allow-dropdown .iti__flag-container, .iti--show-selected-dial-code .iti__flag-container {
  left: 0;
  right: auto;
}

[dir="rtl"] .iti--allow-dropdown .iti__flag-container, [dir="rtl"] .iti--show-selected-dial-code .iti__flag-container {
  left: auto;
  right: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #0000000d;
}

.iti--allow-dropdown .iti__flag-container:has( + input[disabled]):hover, .iti--allow-dropdown .iti__flag-container:has( + input[readonly]):hover {
  cursor: default;
}

.iti--allow-dropdown .iti__flag-container:has( + input[disabled]):hover .iti__selected-flag, .iti--allow-dropdown .iti__flag-container:has( + input[readonly]):hover .iti__selected-flag {
  background-color: #0000;
}

.iti--show-selected-dial-code .iti__selected-flag {
  background-color: #0000000d;
}

.iti--show-selected-dial-code.iti--show-flags .iti__selected-dial-code {
  margin-left: 6px;
}

[dir="rtl"] .iti--show-selected-dial-code.iti--show-flags .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 6px;
}

.iti--container {
  z-index: 1060;
  padding: 1px;
  position: fixed;
  top: -1000px;
  left: -1000px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  background-color: #00000080;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  display: flex;
  position: fixed;
  inset: 0;
}

.iti--fullscreen-popup.iti--container.iti--country-search {
  justify-content: flex-start;
}

.iti--fullscreen-popup .iti__dropdown-content {
  max-height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.iti--fullscreen-popup .iti__country {
  padding: 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (min-resolution: 2dppx) {
  .iti__flag {
    background-size: 5762px 15px;
  }
}

.iti__flag.iti__ac {
  height: 10px;
  background-position: 0 0;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0;
}

.iti__flag.iti__bz {
  height: 12px;
  background-position: -812px 0;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti__flag.iti__cq {
  height: 12px;
  background-position: -1115px 0;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1137px 0;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1159px 0;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1181px 0;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1203px 0;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1225px 0;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1247px 0;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1269px 0;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1291px 0;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1313px 0;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1335px 0;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1357px 0;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1379px 0;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1401px 0;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1423px 0;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1445px 0;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1467px 0;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1489px 0;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1511px 0;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1533px 0;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1555px 0;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1577px 0;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1599px 0;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1621px 0;
}

.iti__flag.iti__ez {
  height: 14px;
  background-position: -1643px 0;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1665px 0;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1687px 0;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1709px 0;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1731px 0;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1753px 0;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1775px 0;
}

.iti__flag.iti__fx {
  height: 14px;
  background-position: -1797px 0;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1819px 0;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1841px 0;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1863px 0;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1885px 0;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1907px 0;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1929px 0;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1951px 0;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1973px 0;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1995px 0;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -2017px 0;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -2039px 0;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -2061px 0;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2083px 0;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2105px 0;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2127px 0;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2149px 0;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2171px 0;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2193px 0;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2215px 0;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2237px 0;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2259px 0;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2281px 0;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2303px 0;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2325px 0;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2347px 0;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2369px 0;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2391px 0;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2413px 0;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2435px 0;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2457px 0;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2479px 0;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2501px 0;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2523px 0;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2545px 0;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2567px 0;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2589px 0;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2611px 0;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2633px 0;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2655px 0;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2677px 0;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2699px 0;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2721px 0;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2743px 0;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2765px 0;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2787px 0;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2809px 0;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2831px 0;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2853px 0;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2875px 0;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2897px 0;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2919px 0;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2941px 0;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2963px 0;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2985px 0;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -3007px 0;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -3029px 0;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -3051px 0;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3073px 0;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3095px 0;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3117px 0;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3139px 0;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3161px 0;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3183px 0;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3205px 0;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3226px 0;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3248px 0;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3270px 0;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3292px 0;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3314px 0;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3336px 0;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3358px 0;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3380px 0;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3402px 0;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3424px 0;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3446px 0;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3468px 0;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3490px 0;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3512px 0;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3534px 0;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3556px 0;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3578px 0;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3600px 0;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3622px 0;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3644px 0;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3666px 0;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3688px 0;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3710px 0;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3732px 0;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3752px 0;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3774px 0;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3796px 0;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3818px 0;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3840px 0;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3862px 0;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3877px 0;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3899px 0;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3921px 0;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3943px 0;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3965px 0;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3987px 0;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -4009px 0;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -4031px 0;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -4053px 0;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4075px 0;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4097px 0;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4119px 0;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4141px 0;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4163px 0;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4185px 0;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4207px 0;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4229px 0;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4251px 0;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4273px 0;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4295px 0;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4317px 0;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4339px 0;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4361px 0;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4383px 0;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4405px 0;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4427px 0;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4449px 0;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4471px 0;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4493px 0;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4515px 0;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4537px 0;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4559px 0;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4581px 0;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4603px 0;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4625px 0;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4647px 0;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4669px 0;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4691px 0;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4713px 0;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4735px 0;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4757px 0;
}

.iti__flag.iti__su {
  height: 10px;
  background-position: -4779px 0;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4801px 0;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4823px 0;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4845px 0;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4867px 0;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4889px 0;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4911px 0;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4933px 0;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4955px 0;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4977px 0;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4999px 0;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -5021px 0;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -5043px 0;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -5065px 0;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -5087px 0;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5109px 0;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5131px 0;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5153px 0;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5175px 0;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5197px 0;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5219px 0;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5241px 0;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5263px 0;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5285px 0;
}

.iti__flag.iti__uk {
  height: 10px;
  background-position: -5307px 0;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5329px 0;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5351px 0;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5373px 0;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5395px 0;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5417px 0;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5439px 0;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5456px 0;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5478px 0;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5500px 0;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5522px 0;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5544px 0;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5566px 0;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5588px 0;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5610px 0;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5632px 0;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5654px 0;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5676px 0;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5698px 0;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5720px 0;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5742px 0;
}

.iti__flag {
  height: 15px;
  background-image: url("flags.c48756f5.png");
  background-position: 20px 0;
  background-repeat: no-repeat;
  box-shadow: 0 0 1px #888;
}

@media (min-resolution: 2dppx) {
  .iti__flag {
    background-image: url("flags@2x.a4474a2c.png");
  }
}

.iti__globe {
  box-shadow: none;
  height: 19px;
  background-image: url("globe.246619a6.png");
  background-position: 100%;
  background-size: contain;
}

@media (min-resolution: 2dppx) {
  .iti__globe {
    background-image: url("globe@2x.a5b9b979.png");
  }
}

._4qMINq_model-description {
  color: #83817e;
}

._4qMINq_action-button {
  color: #25d0b1;
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
}

._4qMINq_team-area {
  max-height: 232px;
  border: 1px solid #ededed;
  margin: 16px 0;
  padding-bottom: 8px;
  overflow-y: auto;
}

._4qMINq_team-area ._4qMINq_region-title {
  padding: 8px 12px;
  position: relative;
}

._4qMINq_team-area ._4qMINq_region-title ._4qMINq_text {
  color: #25d0b1;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

._4qMINq_team-area ._4qMINq_team-entry {
  padding: 8px 12px;
}

._4qMINq_team-area ._4qMINq_team-entry ._4qMINq_team-button {
  cursor: pointer;
  font-size: 14px;
}

._4qMINq_team-area ._4qMINq_team-entry ._4qMINq_team-button:hover {
  color: #3cd7ba;
}

.ER9NZa_layout {
  height: 100vh;
  flex-direction: row;
  display: flex;
  position: relative;
}

.ER9NZa_main {
  min-width: 0;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.ER9NZa_main .ER9NZa_main-inner {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.ER9NZa_main .ER9NZa_main-inner > .ER9NZa_content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ER9NZa_main .ER9NZa_main-inner > .ER9NZa_with-leftbar {
  width: calc(100% - 16px);
}

.ER9NZa_main .ER9NZa_main-inner > .ER9NZa_with-full-leftbar {
  width: calc(100% - 260px);
}

@media screen and (max-width: 848px) {
  .ER9NZa_main .ER9NZa_main-inner > .ER9NZa_with-full-leftbar, .ER9NZa_main .ER9NZa_main-inner > .ER9NZa_with-leftbar {
    width: 100%;
  }

  .ER9NZa_main {
    padding-left: 0;
  }
}

.ER9NZa_top {
  height: 43px;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ER9NZa_bottom {
  height: 64px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ER9NZa_right {
  z-index: 3;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
}

.ER9NZa_right .ER9NZa_right-inner {
  height: 100%;
  display: inline-block;
}

.ER9NZa_has-top {
  margin-top: 43px;
}

.ER9NZa_has-bottom {
  margin-bottom: 64px;
}

.ER9NZa_content-layout {
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: row;
  display: flex;
}

.ER9NZa_content-main {
  min-width: 0;
  flex: 1;
}

.ER9NZa_content-right {
  flex: 0;
}

.ER9NZa_sticky-button-box {
  text-align: right;
  width: calc(100vw - 260px);
  height: 60px;
  z-index: 101;
  background-color: #c8c8c866;
  padding-right: 24px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
}

.ER9NZa_collapsed {
  width: calc(100vw - 16px);
}

.WK_CGG_setting-form {
  margin: 30px;
}

.WK_CGG_setting-form h1 {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px 0;
  font-size: 18px;
}

.WK_CGG_setting-form pre {
  font-size: 12pt;
  line-height: 2em;
}

.WK_CGG_setting-form ul.WK_CGG_references {
  margin: 20px;
}

.WK_CGG_setting-form ul.WK_CGG_references li {
  margin: 10px;
}

.WK_CGG_setting-form .WK_CGG_textfield {
  width: 700px;
  margin-bottom: 12px;
}

.WK_CGG_setting-form .WK_CGG_google-key-textfield-container {
  width: 700px;
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

.WK_CGG_setting-form .WK_CGG_google-key-textfield-container .WK_CGG_google-key-textfield {
  flex: 1;
}

.WK_CGG_setting-form .WK_CGG_bottom-padding {
  height: 80px;
}

.WK_CGG_setting-form .WK_CGG_file-section {
  width: 700px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.WK_CGG_setting-form .WK_CGG_file-section .WK_CGG_file-name-container {
  height: 30px;
  width: 472px;
  border-bottom: 1px solid #8f8e8c;
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
}

.WK_CGG_setting-form .WK_CGG_file-section .WK_CGG_file-name-container > span {
  width: 220px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  bottom: 4px;
  overflow: hidden;
}

.sAAsda_signup-form {
  width: 350px;
  height: 476px;
  margin-top: -238px;
  margin-left: -175px;
  position: absolute;
  top: 50vh;
  left: 50vw;
}

@media only screen and (max-width: 350px) {
  .sAAsda_signup-form {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }
}

.sAAsda_signup-form .sAAsda_logo {
  margin-bottom: 20px;
}

.sAAsda_signup-form .sAAsda_link {
  text-align: right;
  margin-bottom: 14px;
  font-size: 14px;
}

.sAAsda_signup-form > .ms-TextField {
  min-height: 82px;
}

.sAAsda_signup-form .sAAsda_button {
  text-align: right;
}

._53x-1G_content {
  height: calc(100% - 42px);
  position: relative;
}

._53x-1G_token-table td {
  padding: 0 12px;
}

._53x-1G_token-table td:nth-child(3) {
  min-width: 140px;
}

._53x-1G_token-table-row {
  height: 43px;
}

._53x-1G_token-table-row td:first-child, ._53x-1G_token-table-row td:nth-child(2) {
  width: 50%;
}

._53x-1G_token-table-row td:nth-child(3) {
  min-width: 80px;
}

._53x-1G_token-table-row td {
  white-space: nowrap;
}

._53x-1G_token-table-row ._53x-1G_button i {
  color: #3a3b39;
  font-size: 14px;
}

._53x-1G_revoke-btn {
  font-size: 14px;
  font-weight: 600;
  color: #c4151e !important;
}

._53x-1G_tab {
  vertical-align: middle;
  padding: 24px 32px;
}

._53x-1G_top {
  padding: 32px;
}

._53x-1G_team-info-row {
  justify-content: space-between;
  display: flex;
}

._53x-1G_team-control-btns {
  align-self: flex-end;
  display: flex;
}

._53x-1G_rename-btn, ._53x-1G_delete-btn, ._53x-1G_create-token-btn {
  color: #605e5c;
  border: 1px solid #e1dfdd;
}

._53x-1G_rename-btn {
  margin-right: 8px;
}

._53x-1G_delete-btn {
  color: #c4151e;
  border-color: #c4151e;
}

._53x-1G_delete-btn:hover {
  color: #c4151e;
  background-color: #c4151e10;
}

._53x-1G_invite-btn, ._53x-1G_create-token-btn {
  margin-bottom: 16px;
}

._53x-1G_pivot-container {
  height: 44px;
  border-bottom: 2px solid #edebe9;
}

._53x-1G_pivot-container ._53x-1G_pivot {
  box-sizing: content-box;
  margin-left: 24px;
}

._53x-1G_pivot-container .ms-Pivot-icon {
  vertical-align: middle;
}

._53x-1G_no-token {
  text-align: center;
  color: #605e5c;
  margin-top: 25px;
  font-size: 14px;
}

._53x-1G_no-token span {
  font-weight: 600;
}

.SWJ8nW_top-bar {
  height: 42px;
  background-color: #fff;
  border-bottom: 1px solid #edebe9;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  display: flex;
}

.SWJ8nW_top-bar .SWJ8nW_button-area {
  cursor: pointer;
  text-align: center;
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
  display: flex;
}

.SWJ8nW_top-bar .SWJ8nW_button-area > i {
  margin-right: 8px;
  font-size: 16px;
  color: #5ecebd !important;
}

.SWJ8nW_top-bar .SWJ8nW_button-area > i.SWJ8nW_red-icon {
  color: #eb5757 !important;
}

.SWJ8nW_content {
  height: calc(100% - 42px);
}

.SWJ8nW_import-icon {
  width: 16px;
  height: 16px;
  background-image: url("icon-import.492134ba.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;
}

.NhX2UG_connect-container {
  height: 160px;
  border: 1px solid #edebe9;
  border-radius: 2px;
  padding: 0 10px;
  overflow-y: auto;
}

.NhX2UG_connect-container.NhX2UG_error {
  border-color: #a4262c;
}

.NhX2UG_connect-container .NhX2UG_connect-list-header {
  border-bottom: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom: 13px;
}

.NhX2UG_connect-container .NhX2UG_row {
  margin-bottom: 8px;
}

.NhX2UG_error-message {
  color: #a4262c;
  font-size: 12px;
  font-weight: 400;
}

.uEKnAq_nav-bar {
  margin-top: 0;
}

.SlUXTW_webhook-form {
  background-color: #fff;
  padding: 32px 26px;
  overflow-y: auto;
}

.SlUXTW_webhook-form .SlUXTW_title {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
}

.SlUXTW_webhook-form .SlUXTW_fields-container {
  max-width: 532px;
  padding: 15px 6px;
}

.SlUXTW_webhook-form .SlUXTW_fields-container .SlUXTW_input-field {
  margin-top: 15px;
}

.SlUXTW_webhook-form .SlUXTW_fields-container .SlUXTW_input-field .SlUXTW_connect-container {
  height: 160px;
  border: 1px solid #edebe9;
  border-radius: 2px;
  padding: 0 10px;
  overflow-y: auto;
}

.SlUXTW_webhook-form .SlUXTW_fields-container .SlUXTW_input-field .SlUXTW_connect-container.SlUXTW_error {
  border-color: #a4262c;
}

.SlUXTW_webhook-form .SlUXTW_fields-container .SlUXTW_input-field .SlUXTW_connect-container .SlUXTW_connect-list-header {
  border-bottom: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom: 13px;
}

.SlUXTW_webhook-form .SlUXTW_fields-container .SlUXTW_input-field .SlUXTW_connect-container .SlUXTW_row {
  margin-bottom: 8px;
}

.SlUXTW_webhook-form .SlUXTW_error-message {
  color: #a4262c;
  font-size: 12px;
  font-weight: 400;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-title-bar {
  height: 42px;
  border-top: 1px solid #edebe9;
  border-bottom: 1px solid #edebe9;
  margin-top: 13px;
  line-height: 40px;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-title-bar .SlUXTW_heading1 {
  width: 248px;
  padding: 0;
  display: inline-block;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-title-bar .SlUXTW_heading2 {
  width: 240px;
  padding: 0;
  display: inline-block;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-row {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-row .SlUXTW_name-column {
  width: 240px;
  display: inline-block;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-row .SlUXTW_value-column {
  width: 240px;
  padding-left: 8px;
  display: inline-block;
}

.SlUXTW_webhook-form .SlUXTW_custom-headers-row .SlUXTW_delete-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 18px;
  padding-left: 12px;
  padding-right: 12px;
}

.SlUXTW_webhook-form .SlUXTW_add-custom-header-button {
  cursor: pointer;
  color: #25d0b1;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 12px;
  display: inline-block;
}

.SlUXTW_webhook-form .SlUXTW_add-custom-header-disabled {
  cursor: pointer;
  color: #a19f9d;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 12px;
  display: inline-block;
}

.WNiRNq_top-bar {
  height: 42px;
  background-color: #fff;
  border-bottom: 1px solid #edebe9;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 32px;
  display: flex;
}

.WNiRNq_top-bar .WNiRNq_button-area {
  cursor: pointer;
  text-align: center;
  flex-direction: row;
  align-items: center;
  padding-right: 32px;
  display: flex;
}

.WNiRNq_top-bar .WNiRNq_button-area > i {
  margin-right: 8px;
  color: #5ecebd !important;
}

.WNiRNq_content {
  height: calc(100% - 42px);
}

.WNiRNq_import-icon {
  width: 16px;
  height: 16px;
  background-image: url("icon-import.492134ba.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;
}

.oZTpqa_webhook-table-row td:nth-child(1) {
  vertical-align: top;
}

.oZTpqa_webhook-table-row td:nth-child(3) {
  width: 53px;
}

.oZTpqa_webhook-table-row .oZTpqa_basic-info {
  max-width: 272px;
}

.oZTpqa_webhook-table-row .oZTpqa_basic-info .oZTpqa_name {
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
}

.oZTpqa_webhook-table-row .oZTpqa_basic-info .oZTpqa_url {
  height: 16px;
  color: #898989;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.oZTpqa_webhook-table-row .oZTpqa_connect-with-list {
  color: #898989;
}

.oZTpqa_webhook-table-row .oZTpqa_action .oZTpqa_edit-link {
  color: #5ecebd;
  font-size: 14px;
  font-weight: 600;
}

.EvU03W_webhook-list {
  background-color: #fff;
  padding: 32px;
  overflow-y: auto;
}

.EvU03W_webhook-list .EvU03W_title {
  margin-bottom: 26px;
}

.EvU03W_webhook-list .EvU03W_empty-state-container {
  width: 100%;
  display: flex;
}

.EvU03W_webhook-list .EvU03W_empty-state-container .EvU03W_empty-state {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.EvU03W_webhook-list .EvU03W_empty-state-container .EvU03W_empty-state .EvU03W_add-button {
  max-width: 140px;
  margin-top: 10px;
  padding: 0 10px;
}

.EvU03W_webhook-list .EvU03W_empty-state-container .EvU03W_empty-state .EvU03W_empty-state-text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.EvU03W_webhook-list .EvU03W_empty-state-container .EvU03W_empty-state .EvU03W_empty-state-text label {
  margin-top: 0;
  font-weight: 500;
}



.JbH9ga_main-content-container {
  min-height: 100%;
  flex: 1;
  display: flex;
}

.JbH9ga_navbar-wrapper {
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

.JbH9ga_navbar-main {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.JbH9ga_extractor-button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  flex-direction: column;
  flex: 0;
  margin-left: 20px;
  padding: 8px 12px;
  display: flex;
}

.JbH9ga_extractor-button-label {
  color: #27ae60;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.JbH9ga_extractor-button-name {
  color: var(--type-colors-secondary, #605e5c);
  white-space: pre;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.JbH9ga_container {
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.JbH9ga_container .JbH9ga_navbar-top {
  justify-content: space-between;
  align-content: top;
  flex-direction: row;
  flex-shrink: 0;
  margin: 6px 0;
  display: flex;
}

.JbH9ga_container .JbH9ga_navbar-bottom {
  height: 44px;
  flex-direction: row;
  flex-shrink: 0;
  place-content: center space-between;
  margin-top: -2px;
  display: flex;
  position: relative;
  bottom: -2px;
}

.JbH9ga_container > hr {
  height: 2px;
  background-color: #edebe9;
  border: 0;
  flex-shrink: 0;
  margin: 0 12px;
}

.JbH9ga_container .JbH9ga_content {
  flex: 1;
  position: relative;
  overflow: auto;
}

.JbH9ga_right-panel-container {
  min-width: 550px;
  border-left: 1px solid #edebe9;
  transition: all .3s;
  position: relative;
  overflow-x: hidden;
}

.JbH9ga_right-panel-container--hidden {
  display: none;
}

.JbH9ga_right-panel {
  width: 550px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.ZZD-9W_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.ZZD-9W_container .ZZD-9W_content {
  width: 100%;
  flex: 1;
  padding: 20px auto;
  overflow-y: auto;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section {
  max-width: 480px;
  flex-direction: column;
  margin: auto auto 4px;
  display: flex;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_field {
  margin-bottom: 24px;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list {
  border: 1px solid #edebe9;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list .ms-ChoiceField-wrapper {
  width: 100%;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list .ms-ChoiceField {
  margin: 0;
  padding: 10px;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list .ms-ChoiceField:nth-child(2n+1) {
  background-color: #f5f8ff;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list .ms-ChoiceField:nth-child(2n) {
  background-color: #fff;
}

.ZZD-9W_container .ZZD-9W_content .ZZD-9W_sub-section .ZZD-9W_pre-trained-extractor-list .ms-ChoiceFieldLabel {
  padding-left: 40px;
}

.ZZD-9W_extractor-connection-description {
  color: var(--type-colors-primary, #323130);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
}

.ZZD-9W_extractor-list-title {
  color: var(--type-colors-primary, #323130);
  margin-top: 16px;
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.ZZD-9W_switch-connection-button {
  height: 20px;
  padding: unset;
  align-self: flex-end;
  margin-top: 10px;
}

.ZZD-9W_switch-connection-button .ms-Button-label {
  margin: unset;
  color: #0078d4;
  opacity: 1;
}

.ZZD-9W_switch-connection-button .ms-Button-label:hover {
  opacity: .7;
}

.ZZD-9W_switch-connection-button .ms-Button-label:active {
  opacity: .5;
}

.ZZD-9W_step-title {
  color: var(--grey-palette-grey-190, #201f1e);
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.ZZD-9W_icon.ZZD-9W_fixed-layout {
  background-color: #ff6d7d;
}

.ZZD-9W_icon.ZZD-9W_custom-model {
  background-color: #25d0b1;
}

.ZZD-9W_icon.ZZD-9W_prebuilt {
  background-color: #6888fa;
}

.ZZD-9W_extractor-row {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ZZD-9W_extractor-row .ms-ChoiceField-field {
  flex: 1;
}

.ZZD-9W_extractor-row .ms-ChoiceFieldLabel {
  word-break: break-all;
}

.ZZD-9W_extractor-label {
  width: 35%;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
  display: flex;
}

.ZZD-9W_extractor-icon {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  display: flex;
}

.ZZD-9W_extractor-icon > svg {
  width: 12px;
  height: 12px;
}

.ZZD-9W_extractor-icon.ZZD-9W_combined {
  color: #fff;
  background-color: #faae68;
}

.ZZD-9W_extractor-icon.ZZD-9W_fixed-layout {
  color: #fff;
  background-color: #ff6d7d;
}

.ZZD-9W_extractor-icon.ZZD-9W_custom-model {
  color: #fff;
  background-color: #25d0b1;
}

.ZZD-9W_extractor-type-text {
  color: var(--type-colors-secondary, #605e5c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.aa4GYG_bottomBar {
  border-top: 1px solid #edebe9;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 10px 20px 20px;
  display: flex;
}

.aa4GYG_pagination {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.aa4GYG_pageNumber {
  color: #323130;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.aa4GYG_paginationButtonGroup {
  gap: 8px;
  display: flex;
}

.aa4GYG_paginationButton {
  width: 32px;
  height: 32px;
}

.aa4GYG_actionButtonGroup {
  gap: 8px;
  display: flex;
}

.aa4GYG_processing {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
}

.aa4GYG_processingText {
  color: var(--type-colors-primary, #323130);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.FGIcAW_detailsListOverride .ms-DetailsRow-cell {
  color: #323130;
}

.FGIcAW_detailsListOverride .ms-DetailsRow-cell:last-child {
  z-index: 1;
  background-color: inherit;
  position: sticky;
  inset: 0;
}

.FGIcAW_detailsListOverride .ms-DetailsHeader-cell {
  color: #000;
  background-color: #faf9f8;
}

.FGIcAW_detailsListOverride .ms-DetailsHeader-cell:first-child, .FGIcAW_detailsListOverride .ms-DetailsHeader-cell:last-child {
  z-index: 1;
  position: sticky;
  inset: 0;
}

.FGIcAW_detailsListOverride .ms-DetailsRow-fields {
  background-color: inherit;
  align-items: center;
}

.FGIcAW_detailsListOverride .ms-DetailsRow-cellCheck {
  margin-top: unset;
  padding: unset;
  background-color: inherit;
  z-index: 1;
  align-items: center;
  display: flex;
  position: sticky;
  inset: 0;
}

.FGIcAW_detailsListOverride .detailsRowTopBorder.ms-DetailsRow {
  border-top: 1px solid #edebe9;
}

.FGIcAW_detailsListOverride .detailsRowBottomBorder.ms-DetailsRow {
  border-bottom: 1px solid #edebe9;
}

.FGIcAW_status {
  align-items: center;
  gap: 4px;
  display: flex;
}

.FGIcAW_statusExtracting {
  color: #d83b01;
}

.FGIcAW_statusExtracted {
  color: #107c10;
}

.FGIcAW_statusFailed {
  color: #a4262c;
}

.FGIcAW_actionButton:hover {
  background-color: #faf9f8;
}

.FGIcAW_fileNameDisabled, .FGIcAW_documentItemDisabled {
  color: #a19f9d;
}

.FGIcAW_fileNameLink {
  width: 100%;
  height: 20px;
  color: #0078d4;
  display: block;
}

.FGIcAW_fileNameLink:visited {
  color: #0078d4;
}

.FGIcAW_fileNameLink:hover {
  text-decoration: underline;
}

.FGIcAW_disabledSelectionCheckbox {
  width: 18px;
  height: 18px;
  background-color: #edebe9;
  border-radius: 50%;
  margin: 10px 15px;
}

.FGIcAW_documentItemActionButtonGroup {
  display: flex;
}

.FGIcAW_documentItemActionButtonDownload {
  margin-right: auto;
}

.FGIcAW_documentItemActionButtonDelete {
  margin-left: auto;
}

.FGIcAW_retryButton {
  all: unset;
  cursor: pointer;
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.FGIcAW_retryButton:hover {
  opacity: .75;
}

.FGIcAW_retryButton:active {
  opacity: .5;
}

.FGIcAW_statusCell {
  align-items: center;
  gap: 8px;
  display: flex;
}

.mLgrWW_topBar {
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  display: flex;
}

.mLgrWW_searchBox {
  flex: 0 300px;
}

.mLgrWW_rightGroup {
  align-items: center;
  gap: 6px;
  display: flex;
}

.mLgrWW_outOfQuota {
  color: #a4262c;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Iqheda_uploadWidget {
  width: 340px;
  z-index: 10;
  background: #fff;
  box-shadow: 0 -6.4px 14.4px #00000021, 0 -1.2px 3.6px #0000001a;
}

.Iqheda_header {
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  display: flex;
}

.Iqheda_headerText {
  color: #201f1e;
  padding: unset;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Iqheda_headerButtonGroup {
  align-items: center;
  gap: 2px;
  display: flex;
}

.Iqheda_listAnimation {
  max-height: 0;
  transition: max-height .5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.Iqheda_listOpen {
  max-height: 300px;
  transition: max-height 1s ease-in-out;
  overflow: auto;
}

.Iqheda_list {
  flex-direction: column;
  display: flex;
}

.Iqheda_item {
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  padding: 10px;
  display: flex;
}

.Iqheda_listHeaderItem {
  background: #deecf9;
}

.Iqheda_listHeaderItemFailed {
  background: #f9dede;
}

.Iqheda_itemLabel {
  min-width: 0;
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.Iqheda_itemText {
  color: #201f1e;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
}

.Iqheda_errorText {
  color: #a4262c;
}

.Iqheda_actionButton {
  text-align: end;
  all: unset;
  cursor: pointer;
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Iqheda_actionButton:hover {
  opacity: .75;
}

.Iqheda_actionButton:active {
  opacity: .5;
}

.Iqheda_status {
  max-width: 40%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Iqheda_statusSuccess {
  color: #107c10;
}

.Iqheda_statusFail {
  color: #a4262c;
}

.Iqheda_banner--uploading-for-other-workspace {
  color: var(--grey-palette-grey-190, #201f1e);
  background: #fff4ce;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sx1wCa_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.sx1wCa_header {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.sx1wCa_tableContainer {
  flex-direction: column;
  flex: 1;
  margin: 20px 20px 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.sx1wCa_tableContainer .ms-Viewport {
  flex: 1 1 0;
  overflow: auto;
}

.sx1wCa_tableContainer .ms-DetailsList {
  overflow: unset;
}

.sx1wCa_tableContainer .ms-DetailsList-headerWrapper {
  z-index: 2;
  position: sticky;
  top: 0;
}

.sx1wCa_tableContainer .ms-DetailsHeader {
  padding-top: 0;
}

.sx1wCa_notFoundMessage {
  color: #605e5c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sx1wCa_uploadOverlay {
  outline: 2px dashed var(--primary-color, #25d0b1);
  outline-offset: -24px;
  background: #f6fdfc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  display: flex;
  position: absolute;
  inset: 42px 0 0;
}

.sx1wCa_uploadWidget {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sx1wCa_loadingSpinner {
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.sx1wCa_dragDropMessage {
  color: #00bd9b;
  margin-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.sx1wCa_emptyTitle {
  max-width: 370px;
  text-align: center;
  color: #00bd9b;
  margin-top: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.sx1wCa_emptyMessage {
  max-width: 370px;
  white-space: pre-line;
  text-align: center;
  color: var(--type-colors-primary, #323130);
  margin-bottom: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.Q8dJfa_container {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.Q8dJfa_content {
  flex: 1;
}

.Q8dJfa_header {
  border-bottom: 1px solid #edebe9;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.Q8dJfa_navigation {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.Q8dJfa_navigatorContainer {
  gap: 8px;
  display: flex;
}

.Q8dJfa_navigator {
  color: #323130;
  padding: 10px;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}

.Q8dJfa_resultAssetContainer {
  height: 100%;
  border-right: 1px solid #edebe9;
  flex-direction: column;
  padding: 15px 20px 20px;
  display: flex;
}

.Q8dJfa_imageResultContainer {
  width: 500px;
  height: 100%;
  padding: 20px;
}

.Q8dJfa_viewOptionButtonGroup {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.Q8dJfa_viewOptionLabel {
  font-size: 12px;
  line-height: 20px;
}

.Q8dJfa_resultAssetJsonViewerContainer {
  width: 100%;
  background-color: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  flex: 1;
  margin-bottom: 10px;
  padding: 20px;
}

.Q8dJfa_resultAssetViewerContainer {
  flex: 1 1 0;
  overflow-y: auto;
}

.Q8dJfa_resultAssetJson {
  white-space: pre-wrap;
  font-family: Menlo, sans-serif;
  font-size: 14px;
}

.Q8dJfa_resultAssetJsonContainer {
  flex: 1 1 0;
  overflow-y: auto;
}

.Q8dJfa_imageAssetSectionContainer {
  width: 100%;
  height: 100%;
  background-color: #faf9f8;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  display: flex;
}

.Q8dJfa_imageContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.Q8dJfa_imageAssetContainer {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.Q8dJfa_image-viewer {
  width: 100%;
  height: 100%;
}

.Q8dJfa_imageAssetNotFoundText {
  text-align: center;
  color: #605e5c;
  width: 45%;
  min-width: 195px;
  margin: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.Q8dJfa_documentNotFoundInfoContainer {
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.Q8dJfa_contactUsLink {
  color: green;
}

.Q8dJfa_goToExtractorBlockDescBoldText {
  color: #323130;
  font-weight: 700;
}

.Q8dJfa_extractionFailedPlaceholder {
  color: #323130;
  text-align: center;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Q8dJfa_notFoundText {
  color: var(--type-colors-secondary, #605e5c);
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.Q8dJfa_notFoundText .ms-Link {
  color: var(--theme-primary, #0078d4) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important;
}

.Q8dJfa_notFoundText .ms-Link:visited {
  color: var(--theme-primary, #0078d4) !important;
}

.Q8dJfa_retryButton {
  all: unset;
  cursor: pointer;
  color: #0078d4;
  margin-left: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Q8dJfa_retryButton:hover {
  opacity: .75;
}

.Q8dJfa_retryButton:active {
  opacity: .5;
}

.Q8dJfa_loading {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Q8dJfa_loadingText {
  color: var(--Primary-Color, #25d0b1);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

._9GD1mW_pagination {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

._9GD1mW_pageNumber {
  color: #323130;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._9GD1mW_paginationButtonGroup {
  gap: 8px;
  display: flex;
}

._9GD1mW_paginationButton {
  width: 32px;
  height: 32px;
}

._9GD1mW_actionButtonGroup {
  gap: 8px;
  display: flex;
}

._7VMYLa_container {
  background-color: #fff;
  border: 1px solid #edebe9;
  border-radius: 4px;
  padding: 16px;
}

._7VMYLa_card-header {
  cursor: pointer;
  align-items: center;
  display: flex;
}

._7VMYLa_card-header-left {
  flex: 1 0;
  align-items: center;
  display: flex;
}

._7VMYLa_card-delete-button {
  width: 38px;
  height: 38px;
  font-size: 18px;
  line-height: 18px;
}

._7VMYLa_card-delete-button, ._7VMYLa_card-delete-button:hover, ._7VMYLa_card-delete-button:active {
  color: #323130;
}

.HoSkvW_info-block {
  background-color: #f6fdfc;
  border-left: 3px solid #1d9f87;
  border-radius: 2px;
  padding: 8px;
  font-size: 14px;
  display: inline-block;
}

.HoSkvW_info-block-container {
  margin-bottom: 16px;
}

.HoSkvW_info-block-label-container {
  align-items: center;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.HoSkvW_info-block-label-container i {
  margin-right: 8px;
}

.HoSkvW_info-block-description {
  color: #323130;
  font-size: 14px;
}

.UhcZMq_container .UhcZMq_site-url-input {
  margin-bottom: 12px;
}

.UhcZMq_container .UhcZMq_site-url-desc {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.UhcZMq_container .UhcZMq_sharepoint-button {
  text-align: center;
  width: 100%;
  height: 51px;
  border: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.UhcZMq_container .UhcZMq_sharepoint-button:disabled {
  color: #a19f9d;
}

.UhcZMq_container .UhcZMq_sharepoint-button:active > span {
  position: static;
}

.UhcZMq_container .UhcZMq_sharepoint-button .UhcZMq_sharepoint-button-icon {
  width: 35px;
  height: 35px;
  background-color: #fff;
  position: absolute;
  top: 8px;
  left: 8px;
}

.UhcZMq_container .UhcZMq_sharepoint-button .UhcZMq_sharepoint-button-icon > svg {
  width: 35px;
  height: 35px;
}

.UhcZMq_container .UhcZMq_login-spinner {
  margin: 16px;
}

.UhcZMq_container .UhcZMq_file-picker-section {
  margin-bottom: 12px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_file-picker-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_file-picker-desc {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker {
  width: 100%;
  color: #323130;
  background-color: #fff;
  border: 1px solid #8a8886;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-icon {
  font-size: 14px;
  line-height: 18px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-not-selected {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-not-selected .UhcZMq_select-text {
  margin-right: 10px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_file-picker-folder-icon {
  margin-right: 10px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_folder-name-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 0;
  overflow: hidden;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_file-picker-deselect-button {
  height: 20px;
  border: none;
  line-height: 20px;
}

.UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_file-picker-deselect-button, .UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_file-picker-deselect-button:hover, .UhcZMq_container .UhcZMq_file-picker-section .UhcZMq_sharepoint-file-picker .UhcZMq_file-picker-selected .UhcZMq_file-picker-deselect-button:active {
  color: #25d0b1;
}

._5-Fx2a_container {
  width: 386px;
  border: 1px solid #edebe9;
  padding: 12px;
  display: flex;
}

._5-Fx2a_container._5-Fx2a_clickable {
  cursor: pointer;
}

._5-Fx2a_container ._5-Fx2a_icon {
  width: 60px;
  height: 60px;
}

._5-Fx2a_container ._5-Fx2a_icon-container {
  align-items: center;
  margin-right: 12px;
  display: flex;
}

._5-Fx2a_container ._5-Fx2a_info {
  margin-right: 12px;
}

._5-Fx2a_container ._5-Fx2a_info ._5-Fx2a_heading {
  color: #323130;
  margin-bottom: 4px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  display: block;
}

._5-Fx2a_container ._5-Fx2a_info ._5-Fx2a_desc {
  color: #605e5c;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: block;
}

._5-Fx2a_container._5-Fx2a_disabled ._5-Fx2a_info ._5-Fx2a_heading, ._5-Fx2a_container._5-Fx2a_disabled ._5-Fx2a_info ._5-Fx2a_desc {
  color: #a19f9d;
}

._5-Fx2a_container ._5-Fx2a_right {
  text-align: right;
  width: 37px;
  font-size: 12px;
  line-height: 16px;
}

._5-Fx2a_container ._5-Fx2a_right ._5-Fx2a_add-text {
  color: #25d0b1;
  font-weight: 600;
}

._5-Fx2a_container ._5-Fx2a_right ._5-Fx2a_added-text {
  color: #a19f9d;
  font-weight: 400;
}

.UCCcta_container {
  height: 100%;
  background-color: #faf9f8;
  display: flex;
}

.UCCcta_container .UCCcta_content {
  flex: 1 0;
  padding: 20px;
}

.UCCcta_container .UCCcta_content .UCCcta_section:not(:last-child) {
  margin-bottom: 28px;
}

.UCCcta_container .UCCcta_content .UCCcta_section .UCCcta_section-header {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

.UCCcta_container .UCCcta_content .UCCcta_section .UCCcta_section-empty-state-container {
  color: #605e5c;
  text-align: center;
  background-color: #f3f2f1;
  border: 1px solid #edebe9;
  border-radius: 4px;
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.UCCcta_right-sidebar {
  max-width: 425px;
  min-width: 425px;
  background-color: #fff;
  padding: 16px 12px;
}

.UCCcta_right-sidebar .UCCcta_option-list-section {
  margin-bottom: 20px;
}

.UCCcta_right-sidebar .UCCcta_option-list-section .UCCcta_heading {
  color: #605e5c;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.4px;
  display: block;
}

.UCCcta_right-sidebar .UCCcta_option-list-section .UCCcta_option-list {
  list-style-type: none;
}

._4OnhBG_container {
  padding: 20px;
}

._4OnhBG_ms-dropdown {
  width: 210px;
}

._4OnhBG_ms-dropdown .ms-Dropdown-title {
  color: #201f1e;
  border-top: none;
  border-left: none;
  border-right: none;
}

._4OnhBG_ms-dropdown i {
  color: #201f1e;
}

._4OnhBG_control {
  gap: 16px;
  margin-top: 18px;
  display: flex;
}

._4OnhBG_control ._4OnhBG_dropdown {
  align-items: center;
  gap: 10px;
  display: flex;
}

._4OnhBG_control ._4OnhBG_dropdown label {
  margin-bottom: 4px;
}

._4OnhBG_header {
  flex-direction: column;
  margin-bottom: 24px;
  display: flex;
}

._4OnhBG_header ._4OnhBG_title {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

._4OnhBG_header ._4OnhBG_title > p {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

._4OnhBG_header ._4OnhBG_subtitle {
  color: #605e5c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._4OnhBG_header ._4OnhBG_button i {
  font-size: 10px;
  font-weight: 600;
}

._4OnhBG_grid {
  flex-flow: wrap;
  align-content: flex-start;
  gap: 15px;
  display: flex;
}

._4OnhBG_grid-item {
  cursor: pointer;
  width: 225px;
  height: 225px;
  background-color: #faf9f8;
  flex-direction: column;
  padding: 12px 12px 0;
  display: flex;
  overflow: hidden;
  box-shadow: 0 .3px .9px #0000001a, 0 1.6px 3.6px #00000021;
}

._4OnhBG_grid-item ._4OnhBG_item-header {
  color: #201f1e;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
}

._4OnhBG_grid-item ._4OnhBG_item-body {
  border: 1px solid var(--border-colors-divider, #edebe9);
  background: #fff;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: auto;
  padding: 6px 8px 8px;
  display: flex;
}

._4OnhBG_grid-item ._4OnhBG_item-body-label {
  color: #27ae60;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

._4OnhBG_grid-item ._4OnhBG_item-body-text {
  color: var(--type-colors-secondary, #605e5c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

._4OnhBG_grid-item ._4OnhBG_item-footer {
  color: #a19f9d;
  border-top: 1px solid #edebe9;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 12px 0 14px;
  font-size: 10px;
  font-weight: 400;
  display: flex;
}

._4OnhBG_grid-item ._4OnhBG_item-footer > i, ._4OnhBG_grid-item ._4OnhBG_item-footer > i > svg {
  height: 12px;
}

._4OnhBG_grid-item._4OnhBG_create {
  background-color: #faf9f8;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

._4OnhBG_grid-item._4OnhBG_create ._4OnhBG_icon {
  margin-right: 12px;
}

._4OnhBG_paginator {
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 22px;
  display: flex;
}

._4OnhBG_grid-item._4OnhBG_skeleton {
  cursor: default;
  flex-direction: column;
  padding: 12px 12px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

._4OnhBG_grid-item._4OnhBG_skeleton ._4OnhBG_item-header {
  flex: 1;
}

._4OnhBG_grid-item._4OnhBG_skeleton ._4OnhBG_item-body {
  flex: 0 50px;
}

._4OnhBG_grid-item._4OnhBG_skeleton ._4OnhBG_item-footer {
  flex: 0 40px;
}

._4OnhBG_grid-item._4OnhBG_skeleton:after {
  content: "";
  background-image: linear-gradient(90deg, #fff0 0, #fff3 20%, #ffffff80 60%, #fff0);
  animation: 1.5s infinite _4OnhBG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes _4OnhBG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4OnhBG_empty-view {
  outline: 2px dashed var(--primary-color, #25d0b1);
  outline-offset: -20px;
  background: #fafafa;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  display: flex;
}

._4OnhBG_empty-view-title {
  color: var(--type-colors-primary, #323130);
  text-transform: capitalize;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

._4OnhBG_empty-view-message {
  color: #605e5c;
  text-align: center;
  margin-top: 6px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

._4OnhBG_empty-view-create-button {
  margin-top: 20px;
}

._4OnhBG_empty-view-flow-chart {
  width: 100%;
  max-width: 844px;
  height: auto;
}

._4OnhBG_not-found {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  color: var(--type-colors-secondary, #605e5c);
  background-color: #faf9f8;
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.iQ0EOW_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.iQ0EOW_content {
  flex-direction: column;
  flex: 1;
  gap: 20px;
  padding: 20px;
  display: flex;
  overflow: auto;
}

.iQ0EOW_section {
  border: 1px solid #edebe9;
  border-radius: 5px;
  grid-template-columns: 485px;
  row-gap: 10px;
  padding: 20px;
  display: grid;
}

.iQ0EOW_section-header {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.iQ0EOW_section-header-title {
  color: #323130;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.iQ0EOW_section-header-description {
  color: #605e5c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.iQ0EOW_section-header-paragraph {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.iQ0EOW_section-header-paragraph a {
  color: #53b2a1;
}

.iQ0EOW_section-header-paragraph-button {
  color: #0078d4;
  cursor: pointer;
}

.iQ0EOW_workspace-extractor {
  background-color: #faf9f8;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}

.iQ0EOW_workspace-extractor-info {
  word-break: break-word;
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.iQ0EOW_workspace-extractor-name {
  color: #201f1e;
}

.iQ0EOW_workspace-extractor-id {
  color: #605e5c;
}

.iQ0EOW_workspace-go-to-extractor {
  color: #0078d4;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  align-items: center;
  display: flex;
}

.iQ0EOW_workspace-go-to-extractor-icon {
  color: #0078d4;
  margin-left: 6px;
}

.iQ0EOW_section-field-question-label {
  display: inline-block;
}

.iQ0EOW_section-field-question-read-more {
  margin-left: 8px;
}

.iQ0EOW_footer {
  background-color: #fff;
  padding: 0 20px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.iQ0EOW_footer-separator {
  height: 1px;
  background-color: #edebe9;
  margin-bottom: 11px;
}

.iQ0EOW_footer-buttons {
  justify-content: flex-end;
  display: flex;
}

.iQ0EOW_read-more-button__container {
  cursor: pointer;
  display: inline-block;
}

.iQ0EOW_read-more-button__label {
  align-items: center;
  gap: 6px;
  display: flex;
}

.iQ0EOW_read-more-button__text {
  color: #0078d4;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.iQ0EOW_read-more-button__icon {
  color: #0078d4;
  width: 14px;
  height: 14px;
}

.iQ0EOW_document-file-label__container {
  padding-left: 26px;
}

.iQ0EOW_document-file-label__text {
  color: #201f1e;
}

.iQ0EOW_document-file-label__description {
  color: #a19f9d;
}

.pEA1VG_container {
  display: block;
}

.pEA1VG_container .pEA1VG_table {
  background-color: #d3d3d3;
  border: 1px solid #d3d3d3;
  gap: 1px;
  display: grid;
}

.pEA1VG_container .pEA1VG_table .pEA1VG_cell {
  color: #000;
  background: #fff;
  padding: 6px 8px;
  font-size: 10px;
  line-height: 20px;
}

.pEA1VG_container .pEA1VG_table .pEA1VG_header.pEA1VG_cell {
  background: #edebe9;
  font-weight: 600;
}

.pEA1VG_container .pEA1VG_table .pEA1VG_header.pEA1VG_cell.pEA1VG_highlighted {
  background: #d9f4d7;
}

.pEA1VG_container .pEA1VG_table .pEA1VG_item.pEA1VG_cell {
  background: #fff;
  font-weight: 400;
}

.ncZZea_container {
  height: 100%;
  background-color: #faf9f8;
}

.ncZZea_container .ncZZea_content .ncZZea_header {
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_header .ncZZea_title {
  color: #323130;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.ncZZea_container .ncZZea_content .ncZZea_header .ncZZea_close-button {
  width: 36px;
  height: 36px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_header .ncZZea_close-button .ncZZea_icon {
  color: #201f1e;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.ncZZea_container .ncZZea_content .ncZZea_sections {
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 20px;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: 0 1.6px 3.6px #00000021, 0 .3px .9px #0000001a;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_info {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_info .ncZZea_title {
  color: #323130;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_info .ncZZea_title .ncZZea_option {
  color: #25d0b1;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_info .ncZZea_description {
  color: #a19f9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_samples {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_samples .ncZZea_sample {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_samples .ncZZea_sample .ncZZea_label {
  color: #a19f9d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.ncZZea_container .ncZZea_content .ncZZea_sections .ncZZea_section .ncZZea_samples .ncZZea_sample .ncZZea_image > img {
  max-width: 100%;
}

.ML0GTq_container {
  height: 100%;
  background-color: #faf9f8;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_header {
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_header .ML0GTq_title {
  color: #323130;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_header .ML0GTq_close-button {
  width: 36px;
  height: 36px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_header .ML0GTq_close-button .ML0GTq_icon {
  color: #201f1e;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections {
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 20px;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section {
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: 0 1.6px 3.6px #00000021, 0 .3px .9px #0000001a;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_info {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_info .ML0GTq_title {
  color: #323130;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_info .ML0GTq_description {
  color: #a19f9d;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_samples {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_samples .ML0GTq_sample {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_samples .ML0GTq_sample .ML0GTq_label {
  color: #a19f9d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.ML0GTq_container .ML0GTq_content .ML0GTq_sections .ML0GTq_section .ML0GTq_samples .ML0GTq_sample .ML0GTq_image > img {
  max-width: 100%;
}

html, body {
  height: 100vh;
  background-color: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

a {
  text-decoration: none;
}

a, a:visited, a:active {
  color: inherit;
}

a:hover {
  color: #908c29;
}

div.react-toast-notifications__container {
  overflow: hidden;
}

.wide-toast {
  width: 400px;
}

.google-api-toast {
  width: 600px;
  white-space: pre-line;
  margin-left: -140px;
}

.payment-required-toast {
  width: 600px;
  margin-top: 64px;
}

.ms-Dialog-title {
  font-size: 18px;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #4b8ffd80;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-\[24px\] {
  bottom: 24px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[12px\] {
  left: 12px;
}

.right-0 {
  right: 0;
}

.right-6 {
  right: 1.5rem;
}

.right-\[24px\] {
  right: 24px;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 1.5rem;
}

.top-\[6px\] {
  top: 6px;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-start-1 {
  grid-column-start: 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-3 {
  grid-row-start: 3;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-\[48px\] {
  margin: 48px;
}

.m-auto {
  margin: auto;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[22px\] {
  margin-left: 22px;
  margin-right: 22px;
}

.mx-\[32px\] {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-\[48px\] {
  margin-left: 48px;
  margin-right: 48px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-\[20px\] {
  margin-top: 20px;
  margin-bottom: 20px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[11px\] {
  margin-bottom: 11px;
}

.mb-\[16px\] {
  margin-bottom: 16px;
}

.mb-\[28px\] {
  margin-bottom: 28px;
}

.mb-\[2px\] {
  margin-bottom: 2px;
}

.mb-\[48px\] {
  margin-bottom: 48px;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-\[43px\] {
  margin-left: 43px;
}

.ml-\[4px\] {
  margin-left: 4px;
}

.ml-\[8px\] {
  margin-left: 8px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[12px\] {
  margin-top: 12px;
}

.mt-\[16px\] {
  margin-top: 16px;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[34px\] {
  margin-top: 34px;
}

.mt-\[48px\] {
  margin-top: 48px;
}

.mt-\[4px\] {
  margin-top: 4px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100cqh\] {
  height: 100cqh;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[14\.125rem\] {
  height: 14.125rem;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[156px\] {
  height: 156px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[192px\] {
  height: 192px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[208px\] {
  height: 208px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[260px\] {
  height: 260px;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[39px\] {
  height: 39px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[64px\] {
  height: 64px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[calc\(100cqh-44px\)\] {
  height: calc(100cqh - 44px);
}

.h-\[calc\(100vh-158px\)\] {
  height: calc(100vh - 158px);
}

.h-full {
  height: 100%;
}

.h-lvh {
  height: 100lvh;
}

.max-h-\[100cqh\] {
  max-height: 100cqh;
}

.max-h-\[260px\] {
  max-height: 260px;
}

.max-h-\[28px\] {
  max-height: 28px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[calc\(100\%-48px\)\] {
  max-height: calc(100% - 48px);
}

.max-h-full {
  max-height: 100%;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-11 {
  min-height: 2.75rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-7 {
  min-height: 1.75rem;
}

.min-h-\[320px\] {
  min-height: 320px;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.min-h-\[52px\] {
  min-height: 52px;
}

.min-h-\[58px\] {
  min-height: 58px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[112\] {
  width: 112px;
}

.w-\[156px\] {
  width: 156px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[208px\] {
  width: 208px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[310px\] {
  width: 310px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[340px\] {
  width: 340px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[560px\] {
  width: 560px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[76px\] {
  width: 76px;
}

.w-\[calc\(100vw-96px\)\] {
  width: calc(100vw - 96px);
}

.w-full {
  width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-\[1020px\] {
  min-width: 1020px;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[237px\] {
  min-width: 237px;
}

.min-w-\[60px\] {
  min-width: 60px;
}

.min-w-\[728px\] {
  min-width: 728px;
}

.min-w-\[921px\] {
  min-width: 921px;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[1020px\] {
  max-width: 1020px;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-\[310px\] {
  max-width: 310px;
}

.max-w-\[340px\] {
  max-width: 340px;
}

.max-w-\[460px\] {
  max-width: 460px;
}

.max-w-\[560px\] {
  max-width: 560px;
}

.max-w-\[720px\] {
  max-width: 720px;
}

.max-w-\[728px\] {
  max-width: 728px;
}

.max-w-\[80px\] {
  max-width: 80px;
}

.max-w-\[921px\] {
  max-width: 921px;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.origin-center {
  transform-origin: center;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-x-0 {
  --tw-skew-x: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.skew-y-0 {
  --tw-skew-y: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-grab {
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-\[1fr_1fr_1fr_minmax\(0\,82px\)\] {
  grid-template-columns: 1fr 1fr 1fr minmax(0, 82px);
}

.grid-cols-\[1fr_auto_1fr\] {
  grid-template-columns: 1fr auto 1fr;
}

.grid-cols-subgrid {
  grid-template-columns: subgrid;
}

.grid-rows-\[auto_minmax\(0\,_1fr\)_auto\] {
  grid-template-rows: auto minmax(0, 1fr) auto;
}

.grid-rows-\[repeat\(auto-fill\,minmax\(44px\,44px\)\)\] {
  grid-template-rows: repeat(auto-fill, minmax(44px, 44px));
}

.grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[12px\] {
  gap: 12px;
}

.gap-\[16px\] {
  gap: 16px;
}

.gap-\[24px\] {
  gap: 24px;
}

.gap-\[2px\] {
  gap: 2px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-\[6px\] {
  gap: 6px;
}

.gap-\[8px\] {
  gap: 8px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[2px\] {
  border-radius: 2px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#2D4068\] {
  --tw-border-opacity: 1;
  border-color: rgb(45 64 104 / var(--tw-border-opacity));
}

.border-\[\#E7E7E9\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 231 233 / var(--tw-border-opacity));
}

.border-\[\#EDEBE9\] {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.border-\[\#FFD633\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 214 51 / var(--tw-border-opacity));
}

.border-\[\#e1dfdd\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 223 221 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(161 159 157 / var(--tw-border-opacity));
}

.border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(37 208 177 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-t-\[\#EDEBE9\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.bg-\[\#7f7fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 127 255 / var(--tw-bg-opacity));
}

.bg-\[\#7fff7f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 255 127 / var(--tw-bg-opacity));
}

.bg-\[\#C8C6C4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 198 196 / var(--tw-bg-opacity));
}

.bg-\[\#EDEBE9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.bg-\[\#FFD633\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 214 51 / var(--tw-bg-opacity));
}

.bg-\[\#aaa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 170 / var(--tw-bg-opacity));
}

.bg-\[\#aaf\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 255 / var(--tw-bg-opacity));
}

.bg-\[\#e13d3d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 61 61 / var(--tw-bg-opacity));
}

.bg-\[\#e1dfdd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 223 221 / var(--tw-bg-opacity));
}

.bg-\[\#edebe9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.bg-\[\#f2f2f2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-\[\#f3f2f1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.bg-\[\#f8f8f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-\[\#faf9f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 249 248 / var(--tw-bg-opacity));
}

.bg-\[\#ff7f7f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 127 / var(--tw-bg-opacity));
}

.bg-\[\#ffa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 170 / var(--tw-bg-opacity));
}

.bg-\[\#ffd633\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 214 51 / var(--tw-bg-opacity));
}

.bg-\[\#fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 249 248 / var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 243 234 / var(--tw-bg-opacity));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 251 249 / var(--tw-bg-opacity));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 208 177 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 50 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[24px\] {
  padding: 24px;
}

.p-\[40px\] {
  padding: 40px;
}

.p-\[8px\] {
  padding: 8px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[28px\] {
  padding-left: 28px;
  padding-right: 28px;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[10px\] {
  padding-bottom: 10px;
}

.pb-\[16px\] {
  padding-bottom: 16px;
}

.pb-\[24px\] {
  padding-bottom: 24px;
}

.pb-\[30px\] {
  padding-bottom: 30px;
}

.pb-\[48px\] {
  padding-bottom: 48px;
}

.pb-\[4px\] {
  padding-bottom: 4px;
}

.pb-\[6px\] {
  padding-bottom: 6px;
}

.pb-\[8px\] {
  padding-bottom: 8px;
}

.pb-\[92px\] {
  padding-bottom: 92px;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-\[2px\] {
  padding-left: 2px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-\[6px\] {
  padding-right: 6px;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[10px\] {
  padding-top: 10px;
}

.pt-\[16px\] {
  padding-top: 16px;
}

.pt-\[24px\] {
  padding-top: 24px;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.pt-\[32px\] {
  padding-top: 32px;
}

.pt-\[48px\] {
  padding-top: 48px;
}

.pt-\[4px\] {
  padding-top: 4px;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.pt-\[6px\] {
  padding-top: 6px;
}

.pt-\[8px\] {
  padding-top: 8px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.375rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: .625rem;
  line-height: .75rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.lowercase {
  text-transform: lowercase;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[39px\] {
  line-height: 39px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.\!text-\[\#a19f9d\] {
  --tw-text-opacity: 1 !important;
  color: rgb(161 159 157 / var(--tw-text-opacity)) !important;
}

.text-\[\#0078d4\] {
  --tw-text-opacity: 1;
  color: rgb(0 120 212 / var(--tw-text-opacity));
}

.text-\[\#0F7713\] {
  --tw-text-opacity: 1;
  color: rgb(15 119 19 / var(--tw-text-opacity));
}

.text-\[\#201F1E\], .text-\[\#201f1e\] {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.text-\[\#242424\] {
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.text-\[\#323130\] {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.text-\[\#3b3a39\] {
  --tw-text-opacity: 1;
  color: rgb(59 58 57 / var(--tw-text-opacity));
}

.text-\[\#605E5C\] {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.text-\[\#8A8886\] {
  --tw-text-opacity: 1;
  color: rgb(138 136 134 / var(--tw-text-opacity));
}

.text-\[\#9B9997\] {
  --tw-text-opacity: 1;
  color: rgb(155 153 151 / var(--tw-text-opacity));
}

.text-\[\#A19F9D\] {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.text-\[\#A4262C\] {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.text-\[\#BFBFC3\] {
  --tw-text-opacity: 1;
  color: rgb(191 191 195 / var(--tw-text-opacity));
}

.text-\[\#D05C25\] {
  --tw-text-opacity: 1;
  color: rgb(208 92 37 / var(--tw-text-opacity));
}

.text-\[\#a19f9d\] {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(75 143 253 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(16 124 16 / var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(37 208 177 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(34 188 160 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(196 20 31 / var(--tw-text-opacity));
}

.text-tomato-600 {
  --tw-text-opacity: 1;
  color: rgb(216 59 1 / var(--tw-text-opacity));
}

.text-type-700 {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.text-type-800 {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.text-type-disabled {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.text-type-secondary {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(178 148 12 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-elevation16 {
  --tw-shadow: 0px 6.4px 14.4px 0px #00000021, 0px 1.2px 3.6px 0px #0000001a;
  --tw-shadow-colored: 0px 6.4px 14.4px 0px var(--tw-shadow-color), 0px 1.2px 3.6px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-elevation4 {
  --tw-shadow: 0px 1.6px 3.6px 0px #00000021, 0px .3px .9px 0px #0000001a;
  --tw-shadow-colored: 0px 1.6px 3.6px 0px var(--tw-shadow-color), 0px .3px .9px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.-outline-offset-1 {
  outline-offset: -1px;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:w-\[96px\]:after {
  content: var(--tw-content);
  width: 96px;
}

.after\:bg-gradient-to-r:after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.after\:from-transparent:after {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:from-0\%:after {
  content: var(--tw-content);
  --tw-gradient-from-position: 0%;
}

.after\:to-white:after {
  content: var(--tw-content);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.after\:to-100\%:after {
  content: var(--tw-content);
  --tw-gradient-to-position: 100%;
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:border-b:last-child {
  border-bottom-width: 1px;
}

.last\:pr-\[48px\]:last-child {
  padding-right: 48px;
}

.visited\:text-\[\#0078d4\]:visited {
  color: #0078d4;
}

.visited\:text-primary-500:visited {
  color: #25d0b1;
}

.visited\:no-underline:visited {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.empty\:hidden:empty {
  display: none;
}

.hover\:scale-\[2\]:hover {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-0:hover {
  border-width: 0;
}

.hover\:border-none:hover {
  border-style: none;
}

.hover\:border-\[\#CCAB29\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(204 171 41 / var(--tw-border-opacity));
}

.hover\:border-\[\#e1dfdd\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(225 223 221 / var(--tw-border-opacity));
}

.hover\:border-primary-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 159 135 / var(--tw-border-opacity));
}

.hover\:bg-\[\#1BA58C\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 165 140 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#CCAB29\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(204 171 41 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#bd1c1c\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(189 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#e1dfdd\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 223 221 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#f9f7f4\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 249 248 / var(--tw-bg-opacity));
}

.hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(184 234 224 / var(--tw-bg-opacity));
}

.hover\:text-\[\#201F1E\]:hover {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.hover\:text-primary-400:hover {
  --tw-text-opacity: 1;
  color: rgb(147 232 215 / var(--tw-text-opacity));
}

.hover\:text-primary-800:hover {
  --tw-text-opacity: 1;
  color: rgb(4 108 94 / var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(196 20 31 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:outline-2:hover {
  outline-width: 2px;
}

.hover\:outline-primary-500:hover {
  outline-color: #25d0b1;
}

.hover\:\*\:bg-gray-200 > :hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:after\:border-0:focus:after {
  content: var(--tw-content);
  border-width: 0;
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-1:focus-visible {
  outline-width: 1px;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-\[1px\]:focus-visible {
  outline-width: 1px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-offset-\[-4px\]:focus-visible {
  outline-offset: -4px;
}

.focus-visible\:outline-gray-800:focus-visible {
  outline-color: #323130;
}

.focus-visible\:outline-primary-500:focus-visible {
  outline-color: #25d0b1;
}

.focus-visible\:outline-type-800:focus-visible {
  outline-color: #323130;
}

.active\:border-0:active {
  border-width: 0;
}

.active\:border-none:active {
  border-style: none;
}

.active\:bg-\[\#991b1b\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.active\:bg-\[\#CCAB29\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(204 171 41 / var(--tw-bg-opacity));
}

.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.active\:bg-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.active\:bg-primary-400:active {
  --tw-bg-opacity: 1;
  background-color: rgb(147 232 215 / var(--tw-bg-opacity));
}

.active\:text-\[\#201F1E\]:active {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.active\:text-\[\#B8EAE0\]:active {
  --tw-text-opacity: 1;
  color: rgb(184 234 224 / var(--tw-text-opacity));
}

.active\:text-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.active\:text-gray-800:active {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.active\:text-primary-600:active {
  --tw-text-opacity: 1;
  color: rgb(34 188 160 / var(--tw-text-opacity));
}

.active\:text-red-400:active {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.active\:outline-none:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.enabled\:cursor-pointer:enabled {
  cursor: pointer;
}

.enabled\:text-\[\#a4262c\]:enabled {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.hover\:enabled\:border-0:enabled:hover {
  border-width: 0;
}

.hover\:enabled\:bg-gray-100:enabled:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.hover\:enabled\:text-\[\#a4262c\]:enabled:hover {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.hover\:enabled\:opacity-70:enabled:hover {
  opacity: .7;
}

.active\:enabled\:bg-gray-200:enabled:active {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.enabled\:active\:opacity-50:active:enabled {
  opacity: .5;
}

.disabled\:border-gray-200:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.disabled\:border-gray-600:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(161 159 157 / var(--tw-border-opacity));
}

.disabled\:bg-\[\#9ca3af\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.disabled\:bg-\[\#F3F2F1\]:disabled, .disabled\:bg-gray-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.disabled\:text-\[\#7f8c8d\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(127 140 141 / var(--tw-text-opacity));
}

.disabled\:text-\[\#A19F9D\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.disabled\:text-\[\#e5e7eb\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.disabled\:text-gray-600:disabled {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.disabled\:text-gray-900:disabled {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.disabled\:hover\:text-gray-600:hover:disabled, .disabled\:active\:text-gray-600:active:disabled {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.group:hover .group-hover\:block {
  display: block;
}

.has-\[\.image-button\:focus-visible\]\:outline-2:has(.image-button:focus-visible) {
  outline-width: 2px;
}

.has-\[\.image-button\:focus-visible\]\:outline-primary-500:has(.image-button:focus-visible) {
  outline-color: #25d0b1;
}

.\[\&_\.icon\]\:text-primary-500 .icon {
  --tw-text-opacity: 1;
  color: rgb(37 208 177 / var(--tw-text-opacity));
}

.\[\&_\.icon\]\:hover\:text-primary-700:hover .icon, .\[\&_\.icon\]\:active\:text-primary-700:active .icon {
  --tw-text-opacity: 1;
  color: rgb(30 159 135 / var(--tw-text-opacity));
}

.\[\&_\.icon\]\:disabled\:text-gray-600:disabled .icon {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.\[\&_\.meta\]\:opacity-0 .meta {
  opacity: 0;
}

.\[\&_\.meta\]\:hover\:opacity-90:hover .meta, .\[\&_\.meta\]\:has-\[\:focus-visible\]\:opacity-90:has(:focus-visible) .meta {
  opacity: .9;
}

.text-headline-large {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.text-headline-medium {
  color: var(--grey-700, #131315);
  font-family: Inter, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}

.text-headline-small {
  color: var(--M3-black, #000);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.text-title-medium {
  color: var(--M3-black, #000);
  letter-spacing: .15px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-label-large {
  color: var(--M3-black, #000);
  letter-spacing: .1px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-label-medium {
  color: var(--M3-black, #000);
  letter-spacing: .5px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-body-large {
  color: var(--M3-black, #000);
  letter-spacing: .5px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.text-body-medium {
  color: var(--M3-black, #000);
  letter-spacing: .25px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-body-small {
  color: var(--M3-black, #000);
  letter-spacing: .4px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.card {
  border: 1px solid var(--bolder-border-primary, #dce3e1);
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px #00000017;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.formx-v2 .pointer-events-none {
  pointer-events: none;
}

.formx-v2 .visible {
  visibility: visible;
}

.formx-v2 .static {
  position: static;
}

.formx-v2 .fixed {
  position: fixed;
}

.formx-v2 .absolute {
  position: absolute;
}

.formx-v2 .relative {
  position: relative;
}

.formx-v2 .sticky {
  position: sticky;
}

.formx-v2 .inset-0 {
  inset: 0;
}

.formx-v2 .bottom-0 {
  bottom: 0;
}

.formx-v2 .bottom-5 {
  bottom: 1.25rem;
}

.formx-v2 .bottom-6 {
  bottom: 1.5rem;
}

.formx-v2 .bottom-\[24px\] {
  bottom: 24px;
}

.formx-v2 .left-0 {
  left: 0;
}

.formx-v2 .left-1\/2 {
  left: 50%;
}

.formx-v2 .left-\[12px\] {
  left: 12px;
}

.formx-v2 .right-0 {
  right: 0;
}

.formx-v2 .right-6 {
  right: 1.5rem;
}

.formx-v2 .right-\[24px\] {
  right: 24px;
}

.formx-v2 .top-0 {
  top: 0;
}

.formx-v2 .top-6 {
  top: 1.5rem;
}

.formx-v2 .top-\[6px\] {
  top: 6px;
}

.formx-v2 .z-10 {
  z-index: 10;
}

.formx-v2 .z-50 {
  z-index: 50;
}

.formx-v2 .col-span-3 {
  grid-column: span 3 / span 3;
}

.formx-v2 .col-span-4 {
  grid-column: span 4 / span 4;
}

.formx-v2 .col-span-6 {
  grid-column: span 6 / span 6;
}

.formx-v2 .col-start-1 {
  grid-column-start: 1;
}

.formx-v2 .row-span-2 {
  grid-row: span 2 / span 2;
}

.formx-v2 .row-span-3 {
  grid-row: span 3 / span 3;
}

.formx-v2 .row-start-1 {
  grid-row-start: 1;
}

.formx-v2 .row-start-3 {
  grid-row-start: 3;
}

.formx-v2 .m-0 {
  margin: 0;
}

.formx-v2 .m-0\.5 {
  margin: .125rem;
}

.formx-v2 .m-\[48px\] {
  margin: 48px;
}

.formx-v2 .m-auto {
  margin: auto;
}

.formx-v2 .-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.formx-v2 .mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.formx-v2 .mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.formx-v2 .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.formx-v2 .mx-\[22px\] {
  margin-left: 22px;
  margin-right: 22px;
}

.formx-v2 .mx-\[32px\] {
  margin-left: 32px;
  margin-right: 32px;
}

.formx-v2 .mx-\[48px\] {
  margin-left: 48px;
  margin-right: 48px;
}

.formx-v2 .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.formx-v2 .my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.formx-v2 .my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.formx-v2 .my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.formx-v2 .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.formx-v2 .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.formx-v2 .my-\[20px\] {
  margin-top: 20px;
  margin-bottom: 20px;
}

.formx-v2 .-ml-0 {
  margin-left: 0;
}

.formx-v2 .-ml-0\.5 {
  margin-left: -.125rem;
}

.formx-v2 .-ml-1 {
  margin-left: -.25rem;
}

.formx-v2 .-mr-2 {
  margin-right: -.5rem;
}

.formx-v2 .mb-1 {
  margin-bottom: .25rem;
}

.formx-v2 .mb-1\.5 {
  margin-bottom: .375rem;
}

.formx-v2 .mb-2 {
  margin-bottom: .5rem;
}

.formx-v2 .mb-2\.5 {
  margin-bottom: .625rem;
}

.formx-v2 .mb-3 {
  margin-bottom: .75rem;
}

.formx-v2 .mb-4 {
  margin-bottom: 1rem;
}

.formx-v2 .mb-6 {
  margin-bottom: 1.5rem;
}

.formx-v2 .mb-\[11px\] {
  margin-bottom: 11px;
}

.formx-v2 .mb-\[16px\] {
  margin-bottom: 16px;
}

.formx-v2 .mb-\[28px\] {
  margin-bottom: 28px;
}

.formx-v2 .mb-\[2px\] {
  margin-bottom: 2px;
}

.formx-v2 .mb-\[48px\] {
  margin-bottom: 48px;
}

.formx-v2 .ml-1 {
  margin-left: .25rem;
}

.formx-v2 .ml-1\.5 {
  margin-left: .375rem;
}

.formx-v2 .ml-2 {
  margin-left: .5rem;
}

.formx-v2 .ml-7 {
  margin-left: 1.75rem;
}

.formx-v2 .ml-\[43px\] {
  margin-left: 43px;
}

.formx-v2 .ml-\[4px\] {
  margin-left: 4px;
}

.formx-v2 .ml-\[8px\] {
  margin-left: 8px;
}

.formx-v2 .ml-auto {
  margin-left: auto;
}

.formx-v2 .mr-1 {
  margin-right: .25rem;
}

.formx-v2 .mr-1\.5 {
  margin-right: .375rem;
}

.formx-v2 .mr-2 {
  margin-right: .5rem;
}

.formx-v2 .mr-2\.5 {
  margin-right: .625rem;
}

.formx-v2 .mt-0 {
  margin-top: 0;
}

.formx-v2 .mt-0\.5 {
  margin-top: .125rem;
}

.formx-v2 .mt-1 {
  margin-top: .25rem;
}

.formx-v2 .mt-2 {
  margin-top: .5rem;
}

.formx-v2 .mt-3 {
  margin-top: .75rem;
}

.formx-v2 .mt-4 {
  margin-top: 1rem;
}

.formx-v2 .mt-5 {
  margin-top: 1.25rem;
}

.formx-v2 .mt-9 {
  margin-top: 2.25rem;
}

.formx-v2 .mt-\[12px\] {
  margin-top: 12px;
}

.formx-v2 .mt-\[16px\] {
  margin-top: 16px;
}

.formx-v2 .mt-\[1px\] {
  margin-top: 1px;
}

.formx-v2 .mt-\[20px\] {
  margin-top: 20px;
}

.formx-v2 .mt-\[34px\] {
  margin-top: 34px;
}

.formx-v2 .mt-\[48px\] {
  margin-top: 48px;
}

.formx-v2 .mt-\[4px\] {
  margin-top: 4px;
}

.formx-v2 .mt-\[5px\] {
  margin-top: 5px;
}

.formx-v2 .mt-auto {
  margin-top: auto;
}

.formx-v2 .block {
  display: block;
}

.formx-v2 .inline-block {
  display: inline-block;
}

.formx-v2 .inline {
  display: inline;
}

.formx-v2 .flex {
  display: flex;
}

.formx-v2 .inline-flex {
  display: inline-flex;
}

.formx-v2 .table {
  display: table;
}

.formx-v2 .table-cell {
  display: table-cell;
}

.formx-v2 .table-column {
  display: table-column;
}

.formx-v2 .table-row {
  display: table-row;
}

.formx-v2 .grid {
  display: grid;
}

.formx-v2 .contents {
  display: contents;
}

.formx-v2 .list-item {
  display: list-item;
}

.formx-v2 .hidden {
  display: none;
}

.formx-v2 .h-10 {
  height: 2.5rem;
}

.formx-v2 .h-3 {
  height: .75rem;
}

.formx-v2 .h-3\.5 {
  height: .875rem;
}

.formx-v2 .h-4 {
  height: 1rem;
}

.formx-v2 .h-5 {
  height: 1.25rem;
}

.formx-v2 .h-6 {
  height: 1.5rem;
}

.formx-v2 .h-8 {
  height: 2rem;
}

.formx-v2 .h-9 {
  height: 2.25rem;
}

.formx-v2 .h-\[100cqh\] {
  height: 100cqh;
}

.formx-v2 .h-\[100px\] {
  height: 100px;
}

.formx-v2 .h-\[14\.125rem\] {
  height: 14.125rem;
}

.formx-v2 .h-\[140px\] {
  height: 140px;
}

.formx-v2 .h-\[156px\] {
  height: 156px;
}

.formx-v2 .h-\[160px\] {
  height: 160px;
}

.formx-v2 .h-\[16px\] {
  height: 16px;
}

.formx-v2 .h-\[180px\] {
  height: 180px;
}

.formx-v2 .h-\[192px\] {
  height: 192px;
}

.formx-v2 .h-\[1px\] {
  height: 1px;
}

.formx-v2 .h-\[208px\] {
  height: 208px;
}

.formx-v2 .h-\[20px\] {
  height: 20px;
}

.formx-v2 .h-\[220px\] {
  height: 220px;
}

.formx-v2 .h-\[24px\] {
  height: 24px;
}

.formx-v2 .h-\[260px\] {
  height: 260px;
}

.formx-v2 .h-\[2px\] {
  height: 2px;
}

.formx-v2 .h-\[300px\] {
  height: 300px;
}

.formx-v2 .h-\[32px\] {
  height: 32px;
}

.formx-v2 .h-\[36px\] {
  height: 36px;
}

.formx-v2 .h-\[39px\] {
  height: 39px;
}

.formx-v2 .h-\[40px\] {
  height: 40px;
}

.formx-v2 .h-\[48px\] {
  height: 48px;
}

.formx-v2 .h-\[58px\] {
  height: 58px;
}

.formx-v2 .h-\[60px\] {
  height: 60px;
}

.formx-v2 .h-\[64px\] {
  height: 64px;
}

.formx-v2 .h-\[6px\] {
  height: 6px;
}

.formx-v2 .h-\[72px\] {
  height: 72px;
}

.formx-v2 .h-\[calc\(100cqh-44px\)\] {
  height: calc(100cqh - 44px);
}

.formx-v2 .h-\[calc\(100vh-158px\)\] {
  height: calc(100vh - 158px);
}

.formx-v2 .h-full {
  height: 100%;
}

.formx-v2 .h-lvh {
  height: 100lvh;
}

.formx-v2 .max-h-\[100cqh\] {
  max-height: 100cqh;
}

.formx-v2 .max-h-\[260px\] {
  max-height: 260px;
}

.formx-v2 .max-h-\[28px\] {
  max-height: 28px;
}

.formx-v2 .max-h-\[300px\] {
  max-height: 300px;
}

.formx-v2 .max-h-\[calc\(100\%-48px\)\] {
  max-height: calc(100% - 48px);
}

.formx-v2 .max-h-full {
  max-height: 100%;
}

.formx-v2 .min-h-10 {
  min-height: 2.5rem;
}

.formx-v2 .min-h-11 {
  min-height: 2.75rem;
}

.formx-v2 .min-h-12 {
  min-height: 3rem;
}

.formx-v2 .min-h-7 {
  min-height: 1.75rem;
}

.formx-v2 .min-h-\[320px\] {
  min-height: 320px;
}

.formx-v2 .min-h-\[32px\] {
  min-height: 32px;
}

.formx-v2 .min-h-\[52px\] {
  min-height: 52px;
}

.formx-v2 .min-h-\[58px\] {
  min-height: 58px;
}

.formx-v2 .min-h-\[64px\] {
  min-height: 64px;
}

.formx-v2 .w-3 {
  width: .75rem;
}

.formx-v2 .w-3\.5 {
  width: .875rem;
}

.formx-v2 .w-5 {
  width: 1.25rem;
}

.formx-v2 .w-6 {
  width: 1.5rem;
}

.formx-v2 .w-8 {
  width: 2rem;
}

.formx-v2 .w-9 {
  width: 2.25rem;
}

.formx-v2 .w-\[112\] {
  width: 112px;
}

.formx-v2 .w-\[156px\] {
  width: 156px;
}

.formx-v2 .w-\[16px\] {
  width: 16px;
}

.formx-v2 .w-\[208px\] {
  width: 208px;
}

.formx-v2 .w-\[260px\] {
  width: 260px;
}

.formx-v2 .w-\[2px\] {
  width: 2px;
}

.formx-v2 .w-\[310px\] {
  width: 310px;
}

.formx-v2 .w-\[320px\] {
  width: 320px;
}

.formx-v2 .w-\[32px\] {
  width: 32px;
}

.formx-v2 .w-\[340px\] {
  width: 340px;
}

.formx-v2 .w-\[360px\] {
  width: 360px;
}

.formx-v2 .w-\[40px\] {
  width: 40px;
}

.formx-v2 .w-\[560px\] {
  width: 560px;
}

.formx-v2 .w-\[60px\] {
  width: 60px;
}

.formx-v2 .w-\[76px\] {
  width: 76px;
}

.formx-v2 .w-\[calc\(100vw-96px\)\] {
  width: calc(100vw - 96px);
}

.formx-v2 .w-full {
  width: 100%;
}

.formx-v2 .min-w-0 {
  min-width: 0;
}

.formx-v2 .min-w-12 {
  min-width: 3rem;
}

.formx-v2 .min-w-8 {
  min-width: 2rem;
}

.formx-v2 .min-w-\[1020px\] {
  min-width: 1020px;
}

.formx-v2 .min-w-\[160px\] {
  min-width: 160px;
}

.formx-v2 .min-w-\[237px\] {
  min-width: 237px;
}

.formx-v2 .min-w-\[60px\] {
  min-width: 60px;
}

.formx-v2 .min-w-\[728px\] {
  min-width: 728px;
}

.formx-v2 .min-w-\[921px\] {
  min-width: 921px;
}

.formx-v2 .min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.formx-v2 .min-w-full {
  min-width: 100%;
}

.formx-v2 .max-w-\[1020px\] {
  max-width: 1020px;
}

.formx-v2 .max-w-\[150px\] {
  max-width: 150px;
}

.formx-v2 .max-w-\[310px\] {
  max-width: 310px;
}

.formx-v2 .max-w-\[340px\] {
  max-width: 340px;
}

.formx-v2 .max-w-\[460px\] {
  max-width: 460px;
}

.formx-v2 .max-w-\[560px\] {
  max-width: 560px;
}

.formx-v2 .max-w-\[720px\] {
  max-width: 720px;
}

.formx-v2 .max-w-\[728px\] {
  max-width: 728px;
}

.formx-v2 .max-w-\[80px\] {
  max-width: 80px;
}

.formx-v2 .max-w-\[921px\] {
  max-width: 921px;
}

.formx-v2 .max-w-full {
  max-width: 100%;
}

.formx-v2 .flex-1 {
  flex: 1;
}

.formx-v2 .flex-none {
  flex: none;
}

.formx-v2 .flex-shrink-0 {
  flex-shrink: 0;
}

.formx-v2 .flex-grow, .formx-v2 .grow {
  flex-grow: 1;
}

.formx-v2 .border-collapse {
  border-collapse: collapse;
}

.formx-v2 .origin-center {
  transform-origin: center;
}

.formx-v2 .-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .skew-x-0 {
  --tw-skew-x: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .skew-y-0 {
  --tw-skew-y: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .cursor-grab {
  cursor: grab;
}

.formx-v2 .cursor-pointer {
  cursor: pointer;
}

.formx-v2 .select-none {
  -webkit-user-select: none;
  user-select: none;
}

.formx-v2 .select-text {
  -webkit-user-select: text;
  user-select: text;
}

.formx-v2 .list-outside {
  list-style-position: outside;
}

.formx-v2 .list-none {
  list-style-type: none;
}

.formx-v2 .appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.formx-v2 .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.formx-v2 .grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.formx-v2 .grid-cols-\[1fr_1fr_1fr_minmax\(0\,82px\)\] {
  grid-template-columns: 1fr 1fr 1fr minmax(0, 82px);
}

.formx-v2 .grid-cols-\[1fr_auto_1fr\] {
  grid-template-columns: 1fr auto 1fr;
}

.formx-v2 .grid-cols-subgrid {
  grid-template-columns: subgrid;
}

.formx-v2 .grid-rows-\[auto_minmax\(0\,_1fr\)_auto\] {
  grid-template-rows: auto minmax(0, 1fr) auto;
}

.formx-v2 .grid-rows-\[repeat\(auto-fill\,minmax\(44px\,44px\)\)\] {
  grid-template-rows: repeat(auto-fill, minmax(44px, 44px));
}

.formx-v2 .grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.formx-v2 .flex-row {
  flex-direction: row;
}

.formx-v2 .flex-row-reverse {
  flex-direction: row-reverse;
}

.formx-v2 .flex-col {
  flex-direction: column;
}

.formx-v2 .flex-wrap {
  flex-wrap: wrap;
}

.formx-v2 .content-start {
  align-content: flex-start;
}

.formx-v2 .items-start {
  align-items: flex-start;
}

.formx-v2 .items-end {
  align-items: flex-end;
}

.formx-v2 .items-center {
  align-items: center;
}

.formx-v2 .items-stretch {
  align-items: stretch;
}

.formx-v2 .justify-end {
  justify-content: flex-end;
}

.formx-v2 .justify-center {
  justify-content: center;
}

.formx-v2 .justify-between {
  justify-content: space-between;
}

.formx-v2 .gap-1 {
  gap: .25rem;
}

.formx-v2 .gap-1\.5 {
  gap: .375rem;
}

.formx-v2 .gap-2 {
  gap: .5rem;
}

.formx-v2 .gap-3 {
  gap: .75rem;
}

.formx-v2 .gap-4 {
  gap: 1rem;
}

.formx-v2 .gap-5 {
  gap: 1.25rem;
}

.formx-v2 .gap-6 {
  gap: 1.5rem;
}

.formx-v2 .gap-\[10px\] {
  gap: 10px;
}

.formx-v2 .gap-\[12px\] {
  gap: 12px;
}

.formx-v2 .gap-\[16px\] {
  gap: 16px;
}

.formx-v2 .gap-\[24px\] {
  gap: 24px;
}

.formx-v2 .gap-\[2px\] {
  gap: 2px;
}

.formx-v2 .gap-\[4px\] {
  gap: 4px;
}

.formx-v2 .gap-\[5px\] {
  gap: 5px;
}

.formx-v2 .gap-\[6px\] {
  gap: 6px;
}

.formx-v2 .gap-\[8px\] {
  gap: 8px;
}

.formx-v2 .gap-x-1 {
  column-gap: .25rem;
}

.formx-v2 .gap-x-1\.5 {
  column-gap: .375rem;
}

.formx-v2 .gap-x-2 {
  column-gap: .5rem;
}

.formx-v2 .gap-x-5 {
  column-gap: 1.25rem;
}

.formx-v2 .gap-y-0 {
  row-gap: 0;
}

.formx-v2 .gap-y-0\.5 {
  row-gap: .125rem;
}

.formx-v2 .gap-y-2 {
  row-gap: .5rem;
}

.formx-v2 .gap-y-3 {
  row-gap: .75rem;
}

.formx-v2 :is(.space-x-4 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.formx-v2 :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.formx-v2 :is(.space-y-12 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.formx-v2 :is(.space-y-4 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.formx-v2 .self-end {
  align-self: flex-end;
}

.formx-v2 .overflow-hidden {
  overflow: hidden;
}

.formx-v2 .overflow-x-auto {
  overflow-x: auto;
}

.formx-v2 .overflow-y-auto {
  overflow-y: auto;
}

.formx-v2 .overflow-x-hidden {
  overflow-x: hidden;
}

.formx-v2 .overflow-y-hidden {
  overflow-y: hidden;
}

.formx-v2 .overflow-x-scroll {
  overflow-x: scroll;
}

.formx-v2 .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.formx-v2 .whitespace-nowrap {
  white-space: nowrap;
}

.formx-v2 .whitespace-pre-wrap {
  white-space: pre-wrap;
}

.formx-v2 .rounded {
  border-radius: .25rem;
}

.formx-v2 .rounded-\[12px\] {
  border-radius: 12px;
}

.formx-v2 .rounded-\[2px\] {
  border-radius: 2px;
}

.formx-v2 .rounded-\[4px\] {
  border-radius: 4px;
}

.formx-v2 .rounded-\[8px\] {
  border-radius: 8px;
}

.formx-v2 .rounded-sm {
  border-radius: .125rem;
}

.formx-v2 .rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.formx-v2 .border {
  border-width: 1px;
}

.formx-v2 .border-0 {
  border-width: 0;
}

.formx-v2 .border-2 {
  border-width: 2px;
}

.formx-v2 .border-\[1px\] {
  border-width: 1px;
}

.formx-v2 .border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.formx-v2 .border-b {
  border-bottom-width: 1px;
}

.formx-v2 .border-b-0 {
  border-bottom-width: 0;
}

.formx-v2 .border-b-2 {
  border-bottom-width: 2px;
}

.formx-v2 .border-b-\[1px\] {
  border-bottom-width: 1px;
}

.formx-v2 .border-l {
  border-left-width: 1px;
}

.formx-v2 .border-r {
  border-right-width: 1px;
}

.formx-v2 .border-t {
  border-top-width: 1px;
}

.formx-v2 .border-t-0 {
  border-top-width: 0;
}

.formx-v2 .border-solid {
  border-style: solid;
}

.formx-v2 .border-dashed {
  border-style: dashed;
}

.formx-v2 .border-none {
  border-style: none;
}

.formx-v2 .border-\[\#2D4068\] {
  --tw-border-opacity: 1;
  border-color: rgb(45 64 104 / var(--tw-border-opacity));
}

.formx-v2 .border-\[\#E7E7E9\] {
  --tw-border-opacity: 1;
  border-color: rgb(231 231 233 / var(--tw-border-opacity));
}

.formx-v2 .border-\[\#EDEBE9\] {
  --tw-border-opacity: 1;
  border-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.formx-v2 .border-\[\#FFD633\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 214 51 / var(--tw-border-opacity));
}

.formx-v2 .border-\[\#e1dfdd\] {
  --tw-border-opacity: 1;
  border-color: rgb(225 223 221 / var(--tw-border-opacity));
}

.formx-v2 .border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.formx-v2 .border-border-brand {
  --tw-border-opacity: 1;
  border-color: rgb(37 208 177 / var(--tw-border-opacity));
}

.formx-v2 .border-border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(220 227 225 / var(--tw-border-opacity));
}

.formx-v2 .border-border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(181 187 185 / var(--tw-border-opacity));
}

.formx-v2 .border-button-secondary, .formx-v2 .border-grey-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 227 225 / var(--tw-border-opacity));
}

.formx-v2 .border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(9 86 72 / var(--tw-border-opacity));
}

.formx-v2 .border-transparent {
  border-color: #0000;
}

.formx-v2 .border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.formx-v2 .border-t-\[\#EDEBE9\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(237 235 233 / var(--tw-border-opacity));
}

.formx-v2 .bg-\[\#7f7fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 127 255 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#7fff7f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 255 127 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#C8C6C4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 198 196 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#EDEBE9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#FFD633\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 214 51 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#aaa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 170 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#aaf\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 255 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#e13d3d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 61 61 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#e1dfdd\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 223 221 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#edebe9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 233 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#f2f2f2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#f3f2f1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#f8f8f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#faf9f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 249 248 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#ff7f7f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 127 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#ffa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 170 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#ffd633\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 214 51 / var(--tw-bg-opacity));
}

.formx-v2 .bg-\[\#fff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.formx-v2 .bg-chip-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(219 255 247 / var(--tw-bg-opacity));
}

.formx-v2 .bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 208 177 / var(--tw-bg-opacity));
}

.formx-v2 .bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 86 72 / var(--tw-bg-opacity));
}

.formx-v2 .bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 163 211 / var(--tw-bg-opacity));
}

.formx-v2 .bg-surface-background {
  --tw-bg-opacity: 1;
  background-color: rgb(229 245 241 / var(--tw-bg-opacity));
}

.formx-v2 .bg-surface-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(240 255 251 / var(--tw-bg-opacity));
}

.formx-v2 .bg-transparent {
  background-color: #0000;
}

.formx-v2 .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.formx-v2 .object-contain {
  object-fit: contain;
}

.formx-v2 .object-cover {
  object-fit: cover;
}

.formx-v2 .p-0 {
  padding: 0;
}

.formx-v2 .p-10 {
  padding: 2.5rem;
}

.formx-v2 .p-2 {
  padding: .5rem;
}

.formx-v2 .p-3 {
  padding: .75rem;
}

.formx-v2 .p-4 {
  padding: 1rem;
}

.formx-v2 .p-5 {
  padding: 1.25rem;
}

.formx-v2 .p-6 {
  padding: 1.5rem;
}

.formx-v2 .p-\[10px\] {
  padding: 10px;
}

.formx-v2 .p-\[20px\] {
  padding: 20px;
}

.formx-v2 .p-\[24px\] {
  padding: 24px;
}

.formx-v2 .p-\[40px\] {
  padding: 40px;
}

.formx-v2 .p-\[8px\] {
  padding: 8px;
}

.formx-v2 .px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.formx-v2 .px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.formx-v2 .px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.formx-v2 .px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.formx-v2 .px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.formx-v2 .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.formx-v2 .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.formx-v2 .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.formx-v2 .px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.formx-v2 .px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.formx-v2 .px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.formx-v2 .px-\[28px\] {
  padding-left: 28px;
  padding-right: 28px;
}

.formx-v2 .px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.formx-v2 .py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.formx-v2 .py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.formx-v2 .py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.formx-v2 .py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.formx-v2 .py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.formx-v2 .py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.formx-v2 .py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.formx-v2 .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.formx-v2 .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.formx-v2 .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.formx-v2 .py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.formx-v2 .py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.formx-v2 .py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.formx-v2 .py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.formx-v2 .pb-1 {
  padding-bottom: .25rem;
}

.formx-v2 .pb-10 {
  padding-bottom: 2.5rem;
}

.formx-v2 .pb-2 {
  padding-bottom: .5rem;
}

.formx-v2 .pb-3 {
  padding-bottom: .75rem;
}

.formx-v2 .pb-3\.5 {
  padding-bottom: .875rem;
}

.formx-v2 .pb-4 {
  padding-bottom: 1rem;
}

.formx-v2 .pb-5 {
  padding-bottom: 1.25rem;
}

.formx-v2 .pb-6 {
  padding-bottom: 1.5rem;
}

.formx-v2 .pb-\[10px\] {
  padding-bottom: 10px;
}

.formx-v2 .pb-\[16px\] {
  padding-bottom: 16px;
}

.formx-v2 .pb-\[24px\] {
  padding-bottom: 24px;
}

.formx-v2 .pb-\[30px\] {
  padding-bottom: 30px;
}

.formx-v2 .pb-\[48px\] {
  padding-bottom: 48px;
}

.formx-v2 .pb-\[4px\] {
  padding-bottom: 4px;
}

.formx-v2 .pb-\[6px\] {
  padding-bottom: 6px;
}

.formx-v2 .pb-\[8px\] {
  padding-bottom: 8px;
}

.formx-v2 .pb-\[92px\] {
  padding-bottom: 92px;
}

.formx-v2 .pl-1 {
  padding-left: .25rem;
}

.formx-v2 .pl-1\.5 {
  padding-left: .375rem;
}

.formx-v2 .pl-5 {
  padding-left: 1.25rem;
}

.formx-v2 .pl-6 {
  padding-left: 1.5rem;
}

.formx-v2 .pl-7 {
  padding-left: 1.75rem;
}

.formx-v2 .pl-\[2px\] {
  padding-left: 2px;
}

.formx-v2 .pl-\[8px\] {
  padding-left: 8px;
}

.formx-v2 .pr-2 {
  padding-right: .5rem;
}

.formx-v2 .pr-5 {
  padding-right: 1.25rem;
}

.formx-v2 .pr-\[6px\] {
  padding-right: 6px;
}

.formx-v2 .pt-1 {
  padding-top: .25rem;
}

.formx-v2 .pt-1\.5 {
  padding-top: .375rem;
}

.formx-v2 .pt-2 {
  padding-top: .5rem;
}

.formx-v2 .pt-3 {
  padding-top: .75rem;
}

.formx-v2 .pt-4 {
  padding-top: 1rem;
}

.formx-v2 .pt-5 {
  padding-top: 1.25rem;
}

.formx-v2 .pt-6 {
  padding-top: 1.5rem;
}

.formx-v2 .pt-\[10px\] {
  padding-top: 10px;
}

.formx-v2 .pt-\[16px\] {
  padding-top: 16px;
}

.formx-v2 .pt-\[24px\] {
  padding-top: 24px;
}

.formx-v2 .pt-\[2px\] {
  padding-top: 2px;
}

.formx-v2 .pt-\[32px\] {
  padding-top: 32px;
}

.formx-v2 .pt-\[48px\] {
  padding-top: 48px;
}

.formx-v2 .pt-\[4px\] {
  padding-top: 4px;
}

.formx-v2 .pt-\[5px\] {
  padding-top: 5px;
}

.formx-v2 .pt-\[6px\] {
  padding-top: 6px;
}

.formx-v2 .pt-\[8px\] {
  padding-top: 8px;
}

.formx-v2 .text-left {
  text-align: left;
}

.formx-v2 .text-center {
  text-align: center;
}

.formx-v2 .text-right {
  text-align: right;
}

.formx-v2 .align-middle {
  vertical-align: middle;
}

.formx-v2 .align-bottom {
  vertical-align: bottom;
}

.formx-v2 .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.formx-v2 .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.formx-v2 .text-\[12px\] {
  font-size: 12px;
}

.formx-v2 .text-\[14px\] {
  font-size: 14px;
}

.formx-v2 .text-\[16px\] {
  font-size: 16px;
}

.formx-v2 .text-\[20px\] {
  font-size: 20px;
}

.formx-v2 .text-\[30px\] {
  font-size: 30px;
}

.formx-v2 .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.formx-v2 .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.formx-v2 .text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.formx-v2 .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.formx-v2 .text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.formx-v2 .font-bold {
  font-weight: 700;
}

.formx-v2 .font-normal {
  font-weight: 400;
}

.formx-v2 .font-semibold {
  font-weight: 600;
}

.formx-v2 .lowercase {
  text-transform: lowercase;
}

.formx-v2 .leading-4 {
  line-height: 1rem;
}

.formx-v2 .leading-5 {
  line-height: 1.25rem;
}

.formx-v2 .leading-\[20px\] {
  line-height: 20px;
}

.formx-v2 .leading-\[39px\] {
  line-height: 39px;
}

.formx-v2 .leading-none {
  line-height: 1;
}

.formx-v2 .leading-normal {
  line-height: 1.5;
}

.formx-v2 .\!text-\[\#a19f9d\] {
  --tw-text-opacity: 1 !important;
  color: rgb(161 159 157 / var(--tw-text-opacity)) !important;
}

.formx-v2 .text-\[\#0078d4\] {
  --tw-text-opacity: 1;
  color: rgb(0 120 212 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#0F7713\] {
  --tw-text-opacity: 1;
  color: rgb(15 119 19 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#201F1E\], .formx-v2 .text-\[\#201f1e\] {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#242424\] {
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#323130\] {
  --tw-text-opacity: 1;
  color: rgb(50 49 48 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#3b3a39\] {
  --tw-text-opacity: 1;
  color: rgb(59 58 57 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#605E5C\] {
  --tw-text-opacity: 1;
  color: rgb(96 94 92 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#8A8886\] {
  --tw-text-opacity: 1;
  color: rgb(138 136 134 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#9B9997\] {
  --tw-text-opacity: 1;
  color: rgb(155 153 151 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#A19F9D\] {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#A4262C\] {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#BFBFC3\] {
  --tw-text-opacity: 1;
  color: rgb(191 191 195 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#D05C25\] {
  --tw-text-opacity: 1;
  color: rgb(208 92 37 / var(--tw-text-opacity));
}

.formx-v2 .text-\[\#a19f9d\] {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.formx-v2 .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.formx-v2 .text-button-label-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.formx-v2 .text-button-label-secondary {
  --tw-text-opacity: 1;
  color: rgb(43 45 44 / var(--tw-text-opacity));
}

.formx-v2 .text-chip-label-primary {
  --tw-text-opacity: 1;
  color: rgb(27 165 140 / var(--tw-text-opacity));
}

.formx-v2 .text-grey-300 {
  --tw-text-opacity: 1;
  color: rgb(144 149 147 / var(--tw-text-opacity));
}

.formx-v2 .text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(9 86 72 / var(--tw-text-opacity));
}

.formx-v2 .text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(3 51 42 / var(--tw-text-opacity));
}

.formx-v2 .text-text-placeholder {
  --tw-text-opacity: 1;
  color: rgb(181 187 185 / var(--tw-text-opacity));
}

.formx-v2 .text-text-primary {
  --tw-text-opacity: 1;
  color: rgb(43 45 44 / var(--tw-text-opacity));
}

.formx-v2 .text-text-secondary {
  --tw-text-opacity: 1;
  color: rgb(74 77 76 / var(--tw-text-opacity));
}

.formx-v2 .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.formx-v2 .underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.formx-v2 .no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.formx-v2 .caret-text-brand {
  caret-color: #25d0b1;
}

.formx-v2 .opacity-0 {
  opacity: 0;
}

.formx-v2 .opacity-100 {
  opacity: 1;
}

.formx-v2 .shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.formx-v2 .shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.formx-v2 .outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.formx-v2 .outline {
  outline-style: solid;
}

.formx-v2 .outline-0 {
  outline-width: 0;
}

.formx-v2 .-outline-offset-1 {
  outline-offset: -1px;
}

.formx-v2 .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.formx-v2 .\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.formx-v2 .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.formx-v2 .transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.formx-v2 .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.formx-v2 .duration-500 {
  transition-duration: .5s;
}

.formx-v2 .after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.formx-v2 .after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.formx-v2 .after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.formx-v2 .after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.formx-v2 .after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.formx-v2 .after\:w-\[96px\]:after {
  content: var(--tw-content);
  width: 96px;
}

.formx-v2 .after\:bg-gradient-to-r:after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.formx-v2 .after\:from-transparent:after {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.formx-v2 .after\:from-0\%:after {
  content: var(--tw-content);
  --tw-gradient-from-position: 0%;
}

.formx-v2 .after\:to-white:after {
  content: var(--tw-content);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.formx-v2 .after\:to-100\%:after {
  content: var(--tw-content);
  --tw-gradient-to-position: 100%;
}

.formx-v2 .after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.formx-v2 .last\:border-b:last-child {
  border-bottom-width: 1px;
}

.formx-v2 .last\:pr-\[48px\]:last-child {
  padding-right: 48px;
}

.formx-v2 .visited\:text-\[\#0078d4\]:visited {
  color: #0078d4;
}

.formx-v2 .visited\:text-primary-500:visited {
  color: #095648;
}

.formx-v2 .visited\:no-underline:visited {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.formx-v2 .empty\:hidden:empty {
  display: none;
}

.formx-v2 .hover\:scale-\[2\]:hover {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.formx-v2 .hover\:border-0:hover {
  border-width: 0;
}

.formx-v2 .hover\:border-none:hover {
  border-style: none;
}

.formx-v2 .hover\:border-\[\#CCAB29\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(204 171 41 / var(--tw-border-opacity));
}

.formx-v2 .hover\:border-\[\#e1dfdd\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(225 223 221 / var(--tw-border-opacity));
}

.formx-v2 .hover\:border-border-brand:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 208 177 / var(--tw-border-opacity));
}

.formx-v2 .hover\:border-border-secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(181 187 185 / var(--tw-border-opacity));
}

.formx-v2 .hover\:border-primary-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(1 20 16 / var(--tw-border-opacity));
}

.formx-v2 .hover\:bg-\[\#1BA58C\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 165 140 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-\[\#CCAB29\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(204 171 41 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-\[\#bd1c1c\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(189 28 28 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-\[\#e1dfdd\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 223 221 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-\[\#f9f7f4\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 247 244 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-button-tertiary-hovered:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 242 241 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-chip-focused-hovered:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(206 240 232 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-grey-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 227 225 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 165 140 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:bg-secondary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 92 189 / var(--tw-bg-opacity));
}

.formx-v2 .hover\:text-\[\#201F1E\]:hover {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.formx-v2 .hover\:text-button-label-secondary:hover {
  --tw-text-opacity: 1;
  color: rgb(43 45 44 / var(--tw-text-opacity));
}

.formx-v2 .hover\:text-chip-label-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(27 165 140 / var(--tw-text-opacity));
}

.formx-v2 .hover\:text-primary-400:hover {
  --tw-text-opacity: 1;
  color: rgb(18 125 105 / var(--tw-text-opacity));
}

.formx-v2 .hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.formx-v2 .hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.formx-v2 .hover\:outline-2:hover {
  outline-width: 2px;
}

.formx-v2 .hover\:outline-primary-500:hover {
  outline-color: #095648;
}

.formx-v2 .focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.formx-v2 .focus\:after\:border-0:focus:after {
  content: var(--tw-content);
  border-width: 0;
}

.formx-v2 .focus-visible\:border-border-primary:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgb(220 227 225 / var(--tw-border-opacity));
}

.formx-v2 .focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.formx-v2 .focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.formx-v2 .focus-visible\:outline-1:focus-visible {
  outline-width: 1px;
}

.formx-v2 .focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.formx-v2 .focus-visible\:outline-\[1px\]:focus-visible {
  outline-width: 1px;
}

.formx-v2 .focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.formx-v2 .focus-visible\:outline-offset-\[-4px\]:focus-visible {
  outline-offset: -4px;
}

.formx-v2 .focus-visible\:outline-primary-500:focus-visible {
  outline-color: #095648;
}

.formx-v2 .active\:border-0:active {
  border-width: 0;
}

.formx-v2 .active\:border-none:active {
  border-style: none;
}

.formx-v2 .active\:bg-\[\#991b1b\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.formx-v2 .active\:bg-\[\#CCAB29\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(204 171 41 / var(--tw-bg-opacity));
}

.formx-v2 .active\:bg-button-secondary-pressed:active {
  --tw-bg-opacity: 1;
  background-color: rgb(181 187 185 / var(--tw-bg-opacity));
}

.formx-v2 .active\:bg-primary-400:active {
  --tw-bg-opacity: 1;
  background-color: rgb(18 125 105 / var(--tw-bg-opacity));
}

.formx-v2 .active\:bg-secondary-400:active {
  --tw-bg-opacity: 1;
  background-color: rgb(208 37 153 / var(--tw-bg-opacity));
}

.formx-v2 .active\:text-\[\#201F1E\]:active {
  --tw-text-opacity: 1;
  color: rgb(32 31 30 / var(--tw-text-opacity));
}

.formx-v2 .active\:text-\[\#B8EAE0\]:active {
  --tw-text-opacity: 1;
  color: rgb(184 234 224 / var(--tw-text-opacity));
}

.formx-v2 .active\:text-primary-600:active {
  --tw-text-opacity: 1;
  color: rgb(3 51 42 / var(--tw-text-opacity));
}

.formx-v2 .active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.formx-v2 .active\:outline-none:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.formx-v2 .enabled\:cursor-pointer:enabled {
  cursor: pointer;
}

.formx-v2 .enabled\:text-\[\#a4262c\]:enabled {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.formx-v2 .hover\:enabled\:border-0:enabled:hover {
  border-width: 0;
}

.formx-v2 .hover\:enabled\:text-\[\#a4262c\]:enabled:hover {
  --tw-text-opacity: 1;
  color: rgb(164 38 44 / var(--tw-text-opacity));
}

.formx-v2 .hover\:enabled\:opacity-70:enabled:hover {
  opacity: .7;
}

.formx-v2 .enabled\:active\:opacity-50:active:enabled {
  opacity: .5;
}

.formx-v2 .disabled\:bg-\[\#9ca3af\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.formx-v2 .disabled\:bg-\[\#F3F2F1\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(243 242 241 / var(--tw-bg-opacity));
}

.formx-v2 .disabled\:bg-button-disable:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(220 227 225 / var(--tw-bg-opacity));
}

.formx-v2 .disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.formx-v2 .disabled\:text-\[\#7f8c8d\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(127 140 141 / var(--tw-text-opacity));
}

.formx-v2 .disabled\:text-\[\#A19F9D\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(161 159 157 / var(--tw-text-opacity));
}

.formx-v2 .disabled\:text-\[\#e5e7eb\]:disabled {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.formx-v2 .disabled\:text-button-label-disable:disabled {
  --tw-text-opacity: 1;
  color: rgb(144 149 147 / var(--tw-text-opacity));
}

.formx-v2 :is(.group:hover .group-hover\:block) {
  display: block;
}

.formx-v2 .has-\[\.image-button\:focus-visible\]\:outline-2:has(.image-button:focus-visible) {
  outline-width: 2px;
}

.formx-v2 .has-\[\.image-button\:focus-visible\]\:outline-primary-500:has(.image-button:focus-visible) {
  outline-color: #095648;
}

.formx-v2 :is(.\[\&_\.icon\]\:text-primary-500 .icon) {
  --tw-text-opacity: 1;
  color: rgb(9 86 72 / var(--tw-text-opacity));
}

.formx-v2 :is(.\[\&_\.icon\]\:hover\:text-primary-700:hover .icon), .formx-v2 :is(.\[\&_\.icon\]\:active\:text-primary-700:active .icon) {
  --tw-text-opacity: 1;
  color: rgb(1 20 16 / var(--tw-text-opacity));
}

.formx-v2 :is(.\[\&_\.meta\]\:opacity-0 .meta) {
  opacity: 0;
}

.formx-v2 :is(.\[\&_\.meta\]\:hover\:opacity-90:hover .meta), .formx-v2 :is(.\[\&_\.meta\]\:has-\[\:focus-visible\]\:opacity-90:has(:focus-visible) .meta) {
  opacity: .9;
}

.formx-v2 :is(.\[\&_input\]\:border-border-primary input), .formx-v2 :is(.\[\&_input\]\:focus-within\:border-border-primary:focus-within input), .formx-v2 :is(.\[\&_input\]\:focus\:border-border-primary:focus input) {
  --tw-border-opacity: 1;
  border-color: rgb(220 227 225 / var(--tw-border-opacity));
}

/*# sourceMappingURL=index.28fcf9cb.css.map */
